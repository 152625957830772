import React, { useState } from "react";
import useStyles from "./makeStyles";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Box, Paper } from "@material-ui/core";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { signup } from "service/authentication-service";
import { snackbarAlert } from "utils/utils";

const Authentication = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [processing, setProcessing] = useState(false);
    const [username, setUsername] = useState("test1@mail.com");
    const [password, setPassword] = useState("123456");
    const { enqueueSnackbar } = useSnackbar();

    const _alert = (data, type) => {
        snackbarAlert(enqueueSnackbar, data, type);
    };

    const onSignin = async () => {
        setProcessing(true);
        const response = await signup(username, password);
        if (response?.status === 200) {
            const user = response?.data;
            document.cookie =
                "user = " +
                JSON.stringify({ user: user?.username, token: user?.token });
            setProcessing(false);
        } else {
            _alert([t("auth.failAuth")], "error");
            setProcessing(false);
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Paper
                elevation={10}
                style={{
                    padding: 20,
                    width: 400,
                    margin: "40px auto",
                    minHeight: 550,
                }}
            >
                <Grid
                    container
                    spacing={5}
                    justifyContent="center"
                    alignItems="stretch"
                >
                    <Grid item align="center" xs={12}>
                        <Typography className={classes.title} variant="h5">
                            Login
                        </Typography>
                    </Grid>
                    <Grid item xs={12} container justifyContent="center">
                        <TextField
                            label={t("auth.username")}
                            placeholder={t("auth.usernamePlaceholder")}
                            fullWidth
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <br />
                        <TextField
                            label={t("auth.password")}
                            placeholder={t("auth.passwordPlaceholder")}
                            fullWidth
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            onClick={onSignin}
                            loading={processing?.toString()}
                        >
                            {t("auth.signin")}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
};

export default Authentication;
