import { getPoleEmploi, postPoleEmploi } from "utils/api";
import { url } from "./url";
import Cookies from "js-cookie";

export const type = {
    JOBS: "JOBS",
    CV: "CV",
    CRITERIA: "CRITERIA",
    SKILLS: "SKILLS",
    STEP: "STEP",
    SELECTED_JOB: "SELECTED_JOB",
    SUGGESTION: "SUGGESTION",
    CHOICE: "CHOICE",
    EXTSESSION: "EXTSESSION",
};

const urlSession = {
    [type.JOBS]: url.session.jobs,
    [type.CV]: url.session.cv,
    [type.CRITERIA]: url.session.criteria,
    [type.SKILLS]: url.session.skills,
    [type.STEP]: url.session.steps,
    [type.SELECTED_JOB]: url.session.selectedJob,
    [type.SUGGESTION]: url.session.suggest,
    [type.CHOICE]: url.session.choice,
    [type.EXTSESSION]: url.session.extsession,
};

export const getSessionServer = async () => {
    try {
        const result = await getPoleEmploi(
            url.session.session,
            Cookies.get("connect.sid")
        );
        return result;
    } catch (e) {
        console.log(e);
    }
};
export const getExtSessionServer = async (userId, appRef) => {
    try {
        const result = await getPoleEmploi(
            url.session.extsession,
            Cookies.get("connect.sid"),
            { userId, appRef }
        );
        return result;
    } catch (e) {
        console.log(e);
    }
};

export const setSessionServer = async (type, data, headers = {}) => {
    // console.log(Cookies.get("connect.sid"))
    try {
        const result = await postPoleEmploi(
            urlSession[type],
            data,
            Cookies.get("connect.sid"),
            headers
        );

        if (result?.status === 200) {
            return result?.data;
        }
    } catch (e) {
        console.log(e);
    }
};
