import React from "react";
import { DialogContent, Typography, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MuiDialog from "./MuiDialog";

const DialogLoader = ({ open }) => {
    const { t } = useTranslation();
    return (
        <MuiDialog open={open} maxWidth="sm">
            <DialogContent>
                <div className="d-flex justify-content-center py-4 flex-column align-items-center mt-4">
                    <CircularProgress color="primary" size={34} />
                    <Typography
                        variant="subtitle2"
                        className="mt-4"
                        align="center"
                    >
                        {t("cv.filteringJobAdsSubtitle")}
                    </Typography>
                    <Typography variant="subtitle2" className="mt-2">
                        {t("cv.filteringJobAds")}
                    </Typography>
                    <Typography variant="subtitle2" className="mt-1">
                        {t("cv.mightTakeTime")}
                    </Typography>
                </div>
            </DialogContent>
        </MuiDialog>
    );
};

export default DialogLoader;
