import React from "react";
import { useTranslation } from "react-i18next";
import { Steps } from "antd";

const { Step } = Steps;
const ValidateSteps = ({ step }) => {
    const { t } = useTranslation();

    return (
        <Steps current={step} className="mt-4" size="small">
            {["validateJobs", "validateSkills"].map((item, idx) => (
                <Step
                    key={idx}
                    title={<span className="font13">{t(`cv.${item}`)}</span>}
                />
            ))}
        </Steps>
    );
};

export default ValidateSteps;
