import React from "react";
import { useTranslation } from "react-i18next";
import { DialogContent, DialogContentText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { DialogActions, DialogTitle, MuiDialog } from "../common-components";

const ConfirmSave = ({
    open,
    submit,
    toggleModal,
    title,
    description,
    processing,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <MuiDialog open={open} maxWidth="sm">
            <DialogTitle
                title={title || t("confirmSaveTitle")}
                onClose={toggleModal}
            />

            <DialogContent className={classes.content} color="inherit">
                <DialogContentText className={classes.text}>
                    {description || t("confirmSaveDesc")}
                </DialogContentText>

                <DialogActions
                    cancelLabel="cancel"
                    cancel={toggleModal}
                    submitLabel={t("confirm")}
                    submit={submit}
                    processing={processing}
                />
            </DialogContent>
        </MuiDialog>
    );
};

const useStyles = makeStyles(() => ({
    content: {
        margin: "-1em 0 1em 0",
    },
    text: {
        color: "#616161",
        fontSize: 14,
    },
}));

export default ConfirmSave;
