import { skills, files, pole_emploi, ml_api } from "utils/baseUrl";

export const create = async (type, data, sub, uuid, params) => {
    try {
        const url = `/${type}${sub ? `/${uuid}/${sub}/` : ""}`;
        const response = await files.post(url, data, {
            params,
        });

        return response;
    } catch (e) {
        return e.response;
    }
};

export const getAll = async (type, params) => {
    try {
        const response = await skills.get(`/api/${type}`, { params });

        console.log(`get ${type}`, response);
        return response;
    } catch (e) {
        console.log(e.response);
        return e.response;
    }
};

export const getMl = async (url, params = {}) => {
    try {
        const response = await ml_api.get(url, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            params,
        });
        return response;
    } catch (e) {
        return e.response;
    }
};

export const postMl = async (
    url,
    data,
    headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
    }
) => {
    try {
        const response = await ml_api({
            method: "POST",
            url: url,
            headers,
            data: data,
        });
        return response;
    } catch (e) {
        return e.response;
    }
};

export const putMl = async (
    url,
    data = {},
    headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
    }
) => {
    try {
        const response = await ml_api({
            method: "PUT",
            headers,
            url,
            data: data,
        });
        return response;
    } catch (e) {
        return e.response;
    }
};

export const deleteMl = async (url, data = {}) => {
    try {
        const response = await ml_api({
            method: "DELETE",
            url: url,
            data: data,
        });
        return response;
    } catch (e) {
        return e.response;
    }
};

export const getPoleEmploi = async (url, cookie = "", params = {}) => {
    try {
        const response = await pole_emploi.get(url, {
            headers: cookie
                ? {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorization: `Bearer ${cookie}`,
                  }
                : {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                  },
            params,
        });
        return response;
    } catch (e) {
        console.log(e.response);
        return e.response;
    }
};

export const postPoleEmploi = async (
    url,
    data,
    cookie = "",
    headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
    }
) => {
    try {
        const response = await pole_emploi({
            method: "POST",
            url: url,
            headers: cookie
                ? { ...headers, Authorization: `Bearer ${cookie}` }
                : headers,
            data: data,
        });
        return response;
    } catch (e) {
        return e.response;
    }
};

export const deletePoleEmploi = async (url, data = {}, token) => {
    try {
        const response = await pole_emploi({
            method: "DELETE",
            url,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
            data,
        });
        return response;
    } catch (e) {
        return e.response;
    }
};
