import React from "react";
import useStyles from "../makeStyles";
import { useTranslation } from "react-i18next";
import { DialogContent, Typography } from "@material-ui/core";
import { Button } from "antd";
import { FaCheck } from "react-icons/fa";
import { DialogTitle, MuiDialog } from "../../common-components";

const JobDescription = ({ open, toggleModal, descs, job }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { appellation } = job || {};
    const { acces, cond, definition } = descs || {};

    return (
        <MuiDialog open={open} maxWidth="lg">
            <DialogTitle
                title={appellation || t("position.jobDescription")}
                onClose={() => toggleModal(null)}
            />

            <DialogContent className={classes.dialogContent}>
                {["description", "jobAccess", "condition"].map((desc, idx) => {
                    const options = {
                        0: definition,
                        1: acces,
                        2: cond,
                    }[idx];
                    return (
                        <span key={idx}>
                            <Typography
                                className={`font-weight-bold mb-2 ${
                                    idx !== 0 ? "mt-4" : ""
                                }`}
                                variant="subtitle2"
                                key={`typo${idx}`}
                            >
                                {t(`position.${desc}`)}:
                            </Typography>
                            <div className={classes.descs}>
                                {options?.length
                                    ? options.map((item, key) => {
                                          return (
                                              <p
                                                  className="mb-2"
                                                  key={`a${key}`}
                                              >
                                                  <FaCheck
                                                      color="green"
                                                      className="mr-2"
                                                  />
                                                  {item.libelle_texte}
                                              </p>
                                          );
                                      })
                                    : t("noDataFound")}
                            </div>
                        </span>
                    );
                })}
                <div className="d-flex justify-content-center my-4">
                    <Button
                        type="primary"
                        onClick={toggleModal}
                        className={classes.descBtn}
                    >
                        {t("ok")}
                    </Button>
                </div>
            </DialogContent>
        </MuiDialog>
    );
};

export default JobDescription;
