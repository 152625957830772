import React from "react";
import { useTranslation } from "react-i18next";
import { DialogContent, DialogContentText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { DialogActions, DialogTitle, MuiDialog } from "../../common-components";
import { titleCase } from "utils/utils";

const ConfirmRemove = ({ open, submit, toggleModal, job, processing }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <MuiDialog open={open} maxWidth="sm">
            <DialogTitle
                title={t("cv.confirmJobRemove")}
                onClose={toggleModal}
            />

            <DialogContent className={classes.content} color="inherit">
                <DialogContentText className={classes.text}>
                    {t("cv.confirmJobRemoveDesc")}{" "}
                    <b>{job ? titleCase(job) : ""}</b> ?
                </DialogContentText>

                <DialogActions
                    cancelLabel="cancel"
                    cancel={toggleModal}
                    submitLabel={t("confirm")}
                    submit={submit}
                    processing={processing}
                />
            </DialogContent>
        </MuiDialog>
    );
};

const useStyles = makeStyles(() => ({
    content: { margin: "-1em 0 1em 0" },
    text: { color: "#616161", fontSize: 14 },
}));

export default ConfirmRemove;
