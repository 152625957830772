import React from "react";
import { DialogContent, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { Button, Empty } from "antd";
import { MuiDialog } from "components/common-components";

const NoJobFound = ({ open, toggleModal }) => {
    const { t } = useTranslation();
    return (
        <MuiDialog open={open} maxWidth="sm">
            <div className="d-flex justify-content-end p-4">
                <MdClose size={20} className="pointer" onClick={toggleModal} />
            </div>
            <DialogContent>
                <div className="d-flex justify-content-center pb-4 px-2 flex-column align-items-center mt-4">
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "-2em",
                        }}
                    >
                        <Empty
                            description={false}
                            imageStyle={{ height: "100px" }}
                        />
                    </div>
                    <Typography
                        variant="subtitle2"
                        className="font-weight-normal mt-3"
                        style={{ fontSize: 14.5 }}
                        align="center"
                    >
                        {t("position.noJobFoundTitle")}
                    </Typography>{" "}
                    <Typography
                        variant="subtitle2"
                        className="font-weight-normal mt-3"
                        style={{ fontSize: 14.5 }}
                        align="center"
                    >
                        {t("position.noJobFoundDesc")}
                    </Typography>{" "}
                    <Button
                        onClick={toggleModal}
                        style={{
                            background: "#00536D",
                            color: "#fff",
                            borderRadius: 4,
                            marginTop: "2em",
                        }}
                    >
                        Ok
                    </Button>
                </div>
            </DialogContent>
        </MuiDialog>
    );
};

export default NoJobFound;
