import React from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { Popover } from "antd";
import { HiQuestionMarkCircle } from "react-icons/hi";

const JobItemDescription = ({ job }) => {
    const { t } = useTranslation();

    const {
        description,
        competences,
        origineOffre,
        qualitesProfessionnelles: profs,
    } = job || {};

    const { partenaires, origine, urlOrigine } = origineOffre || {};
    const x = partenaires?.length ? partenaires[0] : null;
    const link = origine === "1" ? urlOrigine : x?.url || "";

    return (
        <>
            <p className="font-weight-bold">
                {t("jobSites.origineOffre")} :{" "}
                <a
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    className="font-weight-normal"
                    style={{
                        color: "#127AF4",
                    }}
                >
                    {link || ""}
                </a>
            </p>
            <p className="font-weight-bold">
                {t("jobSites.jobBoardDescription")}
            </p>
            <p>{description || ""}</p>

            <Grid container spacing={2}>
                {profs?.length && (
                    <Grid item xs={12} sm={12}>
                        <p className="font-weight-bold">
                            Qualites Professionnelles
                        </p>

                        <div>
                            {profs.map((prof, idx) => {
                                return (
                                    <div key={idx}>
                                        {`- ${prof.libelle}`}
                                        <Popover
                                            content={
                                                <div
                                                    style={{
                                                        width: "300px",
                                                    }}
                                                >
                                                    <p className="text-justify">
                                                        {prof.description}{" "}
                                                    </p>
                                                </div>
                                            }
                                            placement="right"
                                        >
                                            <HiQuestionMarkCircle
                                                color="#1890FF"
                                                fontSize={16}
                                                className="ml-2"
                                            />
                                        </Popover>
                                    </div>
                                );
                            })}
                        </div>
                    </Grid>
                )}
                {competences?.length && (
                    <Grid item xs={12} sm={12} className="mt-2">
                        <p className="font-weight-bold">Competences</p>{" "}
                        <div>
                            {" "}
                            {competences.map((comp, idx) => {
                                return (
                                    <div key={idx}>{`- ${comp.libelle}`}</div>
                                );
                            })}
                        </div>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default JobItemDescription;
