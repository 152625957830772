import React, { useState, useEffect } from "react";
import SkillTable from "./SkillTable";
// import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { Tabs } from "antd";
import { getAll } from "service/cv-service";
// import { snackbarAlert } from "utils/utils";

const { TabPane } = Tabs;

const SkillList = (props) => {
    const { t } = useTranslation();
    // const { enqueueSnackbar } = useSnackbar();
    const [state, setState] = useState({
        loading: false,
        key: 1,
    });

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            loadData: true,
        }));
        getAll("skill", cb1).then((data) =>
            setState((prevState) => ({ ...prevState, data }))
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let type =
            state?.key === "1" ? "skill" : state?.key === "2" ? "job" : "skill";
        setState((prevState) => ({
            ...prevState,
            loadData: true,
        }));
        getAll(type, cb1).then((data) =>
            setState((prevState) => ({
                ...prevState,
                data,
            }))
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.key]);

    // const _alert = (data, type) => {
    //     snackbarAlert(enqueueSnackbar, data, type);
    // };

    const cb1 = () => {
        setState((prevState) => ({
            ...prevState,
            loadData: false,
        }));
    };

    const onChange = (key) => {
        setState((prevState) => ({ ...prevState, key }));
    };

    const setData = (data) => {
        setState((prevState) => ({ ...prevState, data }));
    };

    return (
        <Grid>
            <Tabs defaultActiveKey="1" onChange={onChange}>
                <TabPane tab={t("auth.skills")} key="1">
                    <Grid>
                        <SkillTable
                            type="skill"
                            data={state?.data}
                            setData={setData}
                            loadData={state?.loadData}
                        />
                    </Grid>
                </TabPane>
                <TabPane tab={t("auth.jobs")} key="2">
                    <Grid>
                        <SkillTable
                            type="job"
                            data={state?.data}
                            setData={setData}
                            loadData={state?.loadData}
                        />
                    </Grid>
                </TabPane>
            </Tabs>
        </Grid>
    );
};

export default SkillList;
