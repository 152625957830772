import React from "react";
import clsx from "clsx";
import useStyles from "../Front-office/makeStyles";
import { useTranslation } from "react-i18next";
import { Button, Space } from "antd";

const StepperActions = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { steps, state, reset, validateJobs, searchJob, nextUrl } = props;
    const { uploadCV, handleSteps, getJobBoards, selectJob } = props;
    const { backToSkills, backToSuggestedJobs, backToSuggestedSkills } = props;

    const { step, fileUpload, processing, validateStep } = state || {};
    const { loadingSuggestion, onValidate, validateSkillAdd } = state || {};
    const { selectedRowKeys, hasChosen } = state || {};

    return (
        <>
            {loadingSuggestion || onValidate || !hasChosen ? (
                ""
            ) : (
                <div className={classes.actionBtn}>
                    <div>
                        {(step !== 0 || (step === 0 && fileUpload)) && (
                            <Button
                                onClick={() =>
                                    step === 0 &&
                                    validateSkillAdd &&
                                    validateStep === 1
                                        ? backToSkills()
                                        : step === 0 && hasChosen
                                        ? backToSuggestedJobs()
                                        : step === 1
                                        ? backToSuggestedSkills()
                                        : step > 1
                                        ? handleSteps("prev")
                                        : reset()
                                }
                                className={clsx(classes.cancel)}
                            >
                                {t("previous")}
                            </Button>
                        )}
                    </div>

                    {(step !== 0 || (step === 0 && fileUpload)) && (
                        <Space>
                            {step === steps.length - 1 && nextUrl ? (
                                ""
                            ) : (
                                <Button
                                    onClick={reset}
                                    disabled={step === 0 && !fileUpload}
                                    className={clsx(classes.cancel)}
                                >
                                    {t("reset")}
                                </Button>
                            )}
                            {step === steps.length - 1 && !nextUrl ? (
                                <></>
                            ) : (
                                <>
                                    {step === steps?.length - 1 && nextUrl ? (
                                        <Button
                                            loading={processing}
                                            className={clsx(
                                                classes.active,
                                                classes.btn,
                                                classes.submit
                                            )}
                                            disabled={processing}
                                            // href={nextUrl}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                const searchParams =
                                                    new URLSearchParams();

                                                if (selectedRowKeys?.length) {
                                                    selectedRowKeys.forEach(
                                                        (job, index) => {
                                                            searchParams.append(
                                                                `JobPositionValue${
                                                                    index + 1
                                                                }`,
                                                                job?.appellation ||
                                                                    ""
                                                            );
                                                        }
                                                    );
                                                }
                                                const modifiedNextUrl = `${nextUrl}?${searchParams}`;
                                                console.log(modifiedNextUrl);

                                                window.top.location.href =
                                                    modifiedNextUrl;

                                                window.scroll({
                                                    top: 0,
                                                    left: 0,
                                                    behavior: "smooth", // Smooth scrolling
                                                });
                                            }}
                                        >
                                            {t("next")}
                                        </Button>
                                    ) : (
                                        <Button
                                            loading={processing}
                                            className={clsx(
                                                classes.active,
                                                classes.btn,
                                                classes.submit
                                            )}
                                            disabled={processing}
                                            onClick={
                                                step === steps.length - 1
                                                    ? null
                                                    : step === 0
                                                    ? validateStep === 0
                                                        ? validateJobs
                                                        : fileUpload &&
                                                          validateStep === 1
                                                        ? () => searchJob()
                                                        : uploadCV
                                                    : step === 1
                                                    ? () => selectJob()
                                                    : step === 2
                                                    ? () => getJobBoards()
                                                    : () => handleSteps("next")
                                            }
                                        >
                                            {t("next")}
                                        </Button>
                                    )}
                                </>
                            )}
                        </Space>
                    )}
                </div>
            )}
        </>
    );
};

export default StepperActions;
