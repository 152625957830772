import React, { useState } from "react";
import useStyles from "../makeStyles";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { Button, Space, Badge, Input } from "antd";
import { titleCase } from "utils/utils";
import { TiDeleteOutline } from "react-icons/ti";
import ConfirmRemove from "./ConfirmRemove";

const AddSkill = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [removing, setRemoving] = useState(false);
    const { skills, skill, handleChange, addSkill, removeJobSkill } = props;

    const hoverSkill = (skill) => setSelectedSkill(skill);

    const toggleRemoveDialog = () => {
        setConfirmOpen(!confirmOpen);
        if (confirmOpen) setSelectedSkill(null);
    };

    const delay = (milliseconds) => {
        return new Promise((resolve) => {
            setTimeout(resolve, milliseconds);
        });
    };

    const confirmRemove = async () => {
        setRemoving(true);
        removeJobSkill("chosenSkills", selectedSkill);
        await delay(500);
        toggleRemoveDialog();
        setRemoving(false);
    };

    return (
        <>
            <Space className="my-4" direction="vertical">
                <Typography variant="subtitle2">
                    <b>{t("cv.addSkillTitle")} </b>
                </Typography>{" "}
                <Typography variant="subtitle2">
                    {t("cv.addSkillSubtitle")}
                </Typography>{" "}
                <Space size={0} className="mt-4">
                    <Typography variant="subtitle2">
                        <b>{t("cv.selectedSkillsTitle")} </b>
                    </Typography>{" "}
                    <Badge
                        count={`${skills?.length > 0 ? skills?.length : 0}`}
                        overflowCount={10000}
                        style={{
                            marginLeft: "0.5em",
                            marginTop: "-4px",
                            backgroundColor: "#0B9F6F", //0B9F6F 35A553
                        }}
                    />
                </Space>
                <Space direction="vertical">
                    {skills?.length
                        ? skills.map((_skill, idx) => (
                              <Button
                                  key={idx}
                                  type="text"
                                  className="pr-4 pl-0 w-100"
                                  size="small"
                                  onMouseEnter={() => hoverSkill(_skill)}
                                  onClick={() => {
                                      hoverSkill(_skill);
                                      setConfirmOpen(true);
                                  }}
                              >
                                  <Typography variant="subtitle2" align="left">
                                      {idx + 1}
                                      .) {titleCase(_skill?.name)}
                                      {selectedSkill?.name === _skill?.name && (
                                          <Space>
                                              <TiDeleteOutline
                                                  className={
                                                      classes.removeSkillIcon
                                                  }
                                                  onClick={() => {
                                                      hoverSkill(_skill);
                                                  }}
                                              />
                                              <Typography
                                                  variant="caption"
                                                  className={
                                                      classes.removeSkillText
                                                  }
                                              >
                                                  {t("remove")}
                                              </Typography>
                                          </Space>
                                      )}
                                  </Typography>
                              </Button>
                          ))
                        : t("cv.noChosenSkill")}
                </Space>
                <Space className="mt-4">
                    <Input
                        placeholder={`${t(
                            `cv.searchSkillInputPlaceholder`
                        )}...`}
                        autoFocus
                        className={classes.addSkillInput}
                        onChange={(e) => handleChange("skill", e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                addSkill("Skill", skill || "");
                            }
                        }}
                        value={skill || ""}
                    />

                    <Button
                        className={classes.addSkillBtn}
                        onClick={() => addSkill("Skill", skill || "")}
                    >
                        {t("cv.addSkill")}
                    </Button>
                </Space>
            </Space>
            <ConfirmRemove
                open={confirmOpen}
                toggleModal={toggleRemoveDialog}
                job={selectedSkill?.name}
                submit={confirmRemove}
                processing={removing}
            />
        </>
    );
};

export default AddSkill;
