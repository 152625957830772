import React from "react";
import useStyles from "../makeStyles";
import JobPanel from "./JobPanel";
import { useTranslation } from "react-i18next";
import { Button, Space, Table, Popconfirm } from "antd";
import { Typography, Tooltip } from "@material-ui/core"; //Slider
import { BiHide } from "react-icons/bi";

const JobList = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { jobs, selectedRowKeys, onJobSelectChange } = props || {};
    const { loader, viewJobDesc, onRowExpand, expandedRow } = props;
    const { softSkills, jobSkills, hideJob, url } = props;

    return (
        <Table
            rowKey={"code"}
            style={{ marginBottom: "2em", marginTop: "2.5em" }}
            columns={[
                {
                    title: t("position.cols.appellation"),
                    dataIndex: "appellation",
                    key: "appellation",
                    width: "70%",
                    render: (item, rec) => (
                        <>
                            <Typography
                                className={classes.primary}
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                }}
                            >
                                {item || ""}{" "}
                            </Typography>
                            <Typography
                                className={classes.secondary}
                                style={{ fontSize: 14 }}
                            >
                                {rec?.libelle || ""}
                            </Typography>{" "}
                        </>
                    ),
                },
                {
                    title: t("position.cols.actions"),
                    dataIndex: "",
                    key: "x",
                    render: (_, job) => (
                        <Space>
                            <Button
                                size="small"
                                style={{
                                    borderRadius: "4px",
                                    fontSize: "13px",
                                    // background: "#F5F5FA",
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    viewJobDesc(job);
                                }}
                            >
                                {t("position.jobDescription")}
                            </Button>

                            <Popconfirm
                                placement="left"
                                title={t("position.confirmHideJob")}
                                okText={t("confirm")}
                                cancelText={t("cancel")}
                                onConfirm={() => hideJob(job)}
                            >
                                <Tooltip title={t("position.hideJob")}>
                                    <Button
                                        size="small"
                                        style={{
                                            borderRadius: "4px",
                                            fontSize: "13px",
                                            // background: "#F5F5FA",
                                        }}
                                    >
                                        <BiHide />
                                    </Button>
                                </Tooltip>
                            </Popconfirm>
                        </Space>
                    ),
                },
            ]}
            dataSource={jobs}
            pagination={false}
            expandable={{
                fixed: "right",
                expandedRowKeys: expandedRow?.code ? [expandedRow?.code] : [],
                onExpand: (expanded, rec) => onRowExpand(expanded, rec),
                expandIconColumnIndex: 0,
                expandedRowRender: () => (
                    <>
                        <div style={{ marginTop: "-1em" }}>
                            <JobPanel
                                loader={loader}
                                jobSkills={jobSkills}
                                softSkills={softSkills}
                            />
                        </div>
                    </>
                ),
            }}
            rowSelection={{
                selectedRowKeys: selectedRowKeys?.length
                    ? selectedRowKeys?.map((row) => row.code)
                    : [],
                onChange: onJobSelectChange,
                hideSelectAll: url ? true : false,
                getCheckboxProps: url
                    ? (rec) => ({
                          disabled:
                              selectedRowKeys?.length >= 3
                                  ? !selectedRowKeys.some(
                                        (item) => item?.code === rec?.code
                                    )
                                  : false,
                      })
                    : null,
            }}
        />
    );
};

export default JobList;
