import React from "react";
import scanning from "../../images/scanning.gif";
import { DialogContent, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Progress } from "antd";
import MuiDialog from "./MuiDialog";

const ProgressLoader = ({ open, progress, text }) => {
    const { t } = useTranslation();
    return (
        <MuiDialog maxWidth="sm" open={open}>
            <DialogContent>
                <div className="d-flex justify-content-center py-4 px-4 flex-column align-items-center mt-4">
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "-3em",
                        }}
                    >
                        <img
                            src={scanning}
                            width={300}
                            height={"100%"}
                            alt="scanning file"
                        />
                    </div>
                    <Typography
                        variant="subtitle2"
                        className="font-weight-normal"
                        style={{ fontSize: 16 }}
                        align="center"
                    >
                        {text ? t(`cv.${text}`) : ""}
                    </Typography>{" "}
                    <Progress
                        className="mt-4 mb-3"
                        strokeWidth={18}
                        percent={progress}
                        strokeColor={{
                            "0%": "#108ee9",
                            "25%": "#108ee9",
                            "50%": "#108ee9",
                            "100%": "#87d068",
                        }}
                        showInfo={true}
                        strokeLinecap="round"
                    />
                </div>
            </DialogContent>
        </MuiDialog>
    );
};

export default ProgressLoader;
