import React, { useState, useEffect } from "react";
import NewJobReferential from "./NewJobReferential";
import { useLocation } from "react-router-dom";
import ConfirmRemove from "./ConfirmRemove";
import useStyles from "../makeStyles";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { Button, Space, Divider, Input } from "antd";
import { delay, snackbarAlert, titleCase } from "utils/utils";
import { create } from "service/cv-service";
import { useSnackbar } from "notistack";
import { DebounceSelect } from "components/common-components";

async function fetchJobs(search_expression, lang) {
    console.log();
    const search = { search_expression };
    const res = await create(JSON.stringify(search), `filteredJobs${lang}`);
    console.log(res);
    const jobs = res?.data?.results?.[0]?.[0]?.[0]?.listjobsfiltered;

    if (jobs?.length) {
        jobs.forEach((item) => {
            item.value = item?.uuid || "";
            item.label = titleCase(item?.joblabel) || "";
            item.labelfr = titleCase(item?.joblabelfr) || "";
            item.name = item?.joblabel || "";
            item.namefr = item?.joblabelfr || "";
        });
    }
    return jobs;
}

const AddJob = (props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [inputValue, setInputValue] = useState(null);
    const [jobAssociated, setJobAssociated] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [openRef, setOpenRef] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [removing, setRemoving] = useState(false);
    const { jobs, job, addJob, handleChange, removeJobSkill } = props;
    const { jobAddLoader, skillLearning, onViewDesc, setJob, loadDesc } = props;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const lang =
        searchParams.get("lang") ||
        window?.localStorage?.i18nextLng ||
        i18n.language ||
        "fr";

    useEffect(() => {
        setJob("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleReferential = () => setOpenRef(!openRef);

    const showAlert = (msg, type) =>
        snackbarAlert(enqueueSnackbar, [msg], type);

    const addToReferential = async () => {
        if (!inputValue) {
            showAlert(t("cv.invalidJobTitle"), "error");
        } else if (!jobAssociated?.id) {
            showAlert(t("cv.emptyJob"), "error");
        } else {
            setProcessing(true);
            const newJob = {
                job_label: inputValue,
                job_id: jobAssociated?.uuid,
            };
            const res = await create(JSON.stringify(newJob), `addJob${lang}`);
            console.log("ADD JOB FR RESPONSE", res);
            if (res?.data?.results?.[0]?.[0]?.[0]?.status === "200") {
                let _newJob = { ...jobAssociated };
                _newJob.name = inputValue;
                _newJob.label = inputValue;
                addJob("Job", _newJob);
                setInputValue("");
                setJobAssociated(null);
                setOpenRef(false);
                showAlert(t("cv.addNewJobSuccess"), "success");
            } else {
                showAlert(
                    res?.data?.results?.[0]?.[0]?.[0]?.status_description,
                    "error"
                );
            }
            setProcessing(false);
        }
    };

    const toggleRemoveDialog = (job) => {
        setConfirmOpen(!confirmOpen);
        setSelectedJob(job);
    };

    const confirmRemove = async () => {
        setRemoving(true);
        removeJobSkill("chosenJobs", selectedJob);
        await delay(500);
        toggleRemoveDialog(null);
        setRemoving(false);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={12} className="mb-4 mt-2">
                    {skillLearning("job", true, toggleRemoveDialog)}
                </Grid>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={0} className="mt-0">
                        <Grid item xs={12} md={12}>
                            <Space className="mb-4 mt-2" direction="vertical">
                                <div>
                                    <Typography
                                        variant="subtitle2"
                                        className="font-weight-bold"
                                    >
                                        {t("cv.addMoreJobs")}
                                    </Typography>

                                    <p className="mt-2">
                                        {t("cv.addMoreJobsDescription")}
                                    </p>
                                    <p style={{ marginTop: "-10px" }}>
                                        <i
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    "cv.addMoreJobsDescription2"
                                                ),
                                            }}
                                        />
                                    </p>
                                </div>
                            </Space>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Space>
                                {openRef ? (
                                    <Input
                                        value={inputValue || ""}
                                        style={{ width: "500px" }}
                                    />
                                ) : (
                                    <DebounceSelect
                                        showSearch
                                        allowClear
                                        value={job?.id ? job : undefined}
                                        jobs={jobs}
                                        type="newJob"
                                        search={inputValue}
                                        placeholder={t(
                                            "cv.searchJobInputPlaceholder"
                                        )}
                                        fetchOptions={fetchJobs}
                                        onChange={(_, option) => {
                                            handleChange("job", option);
                                        }}
                                        setInputValue={setInputValue}
                                        className={classes.jobSelect}
                                        fetching={fetching}
                                        setFetching={setFetching}
                                        dropdownRender={(menu) => (
                                            <div>
                                                {menu}
                                                {inputValue && !fetching && (
                                                    <>
                                                        <Divider
                                                            style={{
                                                                marginTop: "0",
                                                            }}
                                                        />
                                                        <div
                                                            className="p-2"
                                                            style={{
                                                                marginTop:
                                                                    "-1em",
                                                            }}
                                                        >
                                                            <Button
                                                                size="small"
                                                                className="w-100"
                                                                type="primary"
                                                                onClick={
                                                                    toggleReferential
                                                                }
                                                            >
                                                                +{" "}
                                                                {t(
                                                                    "cv.jobReferentialAdd"
                                                                )}
                                                            </Button>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    />
                                )}
                                {job?.value && (
                                    <Button
                                        type="text"
                                        className="p-0 font-weight-bold"
                                        style={{ color: "#075ED4" }}
                                        onClick={() => onViewDesc(job)}
                                    >
                                        {t("cv.viewSelectedJobDesc")}
                                    </Button>
                                )}
                                <Space>
                                    <Button
                                        disabled={loadDesc}
                                        onClick={() => addJob("Job", job || "")}
                                        className={classes.addJobBtn}
                                        loading={
                                            jobAddLoader || loadDesc || false
                                        }
                                    >
                                        {t("cv.addJob")}
                                    </Button>
                                </Space>{" "}
                            </Space>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <NewJobReferential
                newJob={inputValue}
                onInputChange={setInputValue}
                open={openRef}
                toggleModal={toggleReferential}
                fetchJobs={fetchJobs}
                jobAssociated={jobAssociated}
                setJobAssociated={setJobAssociated}
                submit={addToReferential}
                processing={processing}
            />
            <ConfirmRemove
                open={confirmOpen}
                toggleModal={() => toggleRemoveDialog(null)}
                job={selectedJob?.name}
                submit={confirmRemove}
                processing={removing}
            />
        </>
    );
};

export default AddJob;
