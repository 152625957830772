import React from "react";
import contractsType from "../../../utils/contractsType";
import useStyles from "../makeStyles";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Popover, Space, Tag, Radio } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { RiArrowDownSLine } from "react-icons/ri";
import { BiCalendar } from "react-icons/bi";

const JobFilters = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { filterList, handleInput, filterItems, items } = props;
    const { checkedList, dateFilter, checkedListCopy, openContract } =
        filterItems || {};

    const typeContrat = checkedList?.length
        ? checkedList.join(", ")
        : t("filterDate.all");

    return (
        <Space size={0} className={classes.filters}>
            <Popover
                title={t("jobSites.contratFilter")}
                content={
                    <Space direction={"vertical"} className="w-100">
                        <Checkbox.Group
                            className="my-2"
                            onChange={(e) => handleInput("checkedListCopy", e)}
                            style={{ height: "250px", overflowY: "scroll" }}
                            value={checkedListCopy}
                        >
                            <Space direction="vertical" className="w-100">
                                {contractsType?.length
                                    ? contractsType.map((item, idx) => {
                                          const { label, value } = item;

                                          return (
                                              <Checkbox value={value} key={idx}>
                                                  {label}
                                              </Checkbox>
                                          );
                                      })
                                    : ""}
                            </Space>
                        </Checkbox.Group>
                        <Space className="d-flex justify-content-end">
                            <Button
                                type="text"
                                size="small"
                                onClick={() => filterList("reset")}
                            >
                                {t("reset")}
                            </Button>
                            <Button
                                type="primary"
                                size="small"
                                className="w-100"
                                onClick={() => filterList()}
                            >
                                {t("saveChanges")}
                            </Button>
                        </Space>
                    </Space>
                }
                placement="bottomRight"
                trigger="click"
                // visible={openContract}
                onVisibleChange={
                    items?.length > 1 && items
                        ? () => {
                              handleInput("checkedList", checkedList);
                              handleInput("checkedListCopy", checkedList);
                              handleInput("openContract", !openContract);
                          }
                        : null
                }
            >
                <Tag
                    className="p-1 px-2"
                    color="#f5f5fa"
                    style={{ color: "#363636" }}
                    closeIcon={<AiOutlineClose color="#fff" />}
                >
                    Type Contrat: {typeContrat || ""} <RiArrowDownSLine />
                </Tag>
            </Popover>
            <Popover
                title={t("jobSites.dateFilter")}
                content={
                    <Space direction={"vertical"}>
                        <Radio.Group
                            onChange={(e) =>
                                handleInput("dateFilter", e.target.value)
                            }
                            value={dateFilter}
                        >
                            <Space direction="vertical">
                                <Radio value={"all"}>
                                    {t("filterDate.all")}
                                </Radio>
                                <Radio value={"today"}>
                                    {t("filterDate.today")}
                                </Radio>
                                <Radio value={"week"}>
                                    {t("filterDate.thisWeek")}
                                </Radio>
                                <Radio value={"month"}>
                                    {t("filterDate.thisMonth")}
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </Space>
                }
                placement="bottomRight"
                trigger="click"
                // visible={items?.length > 1 && items}
            >
                <Tag
                    className="p-1 px-2"
                    color="#f5f5fa"
                    style={{ color: "#363636" }}
                >
                    <BiCalendar
                        className="mr-0"
                        fontSize={16}
                        style={{ marginTop: "-2px" }}
                    />{" "}
                    {dateFilter === "all"
                        ? t("filterDate.all")
                        : dateFilter === "today"
                        ? t("filterDate.today")
                        : dateFilter === "week"
                        ? t("filterDate.thisWeek")
                        : dateFilter === "month"
                        ? t("filterDate.thisMonth")
                        : ""}
                    <RiArrowDownSLine />
                </Tag>
            </Popover>
        </Space>
    );
};
export default JobFilters;
