import React from "react";
import useStyles from "../makeStyles";
import { DialogContent, Typography } from "@material-ui/core";
import { DebounceSelect, MuiDialog } from "../../common-components";
import { DialogActions, DialogTitle } from "../../common-components";
import { useTranslation } from "react-i18next";
import { Input, Space, Form } from "antd";

const NewJobReferential = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { open, toggleModal, newJob, onInputChange, submit } = props;
    const { fetchJobs, setJobAssociated, processing } = props;

    return (
        <MuiDialog open={open} maxWidth="sm">
            <DialogTitle title={t("cv.newJobTitle")} onClose={toggleModal} />

            <DialogContent className={classes.dialogContent}>
                <div className="mb-2 mt-0">
                    <Typography variant="subtitle2">
                        {t("cv.newJobDescription")}
                    </Typography>
                    <Space style={{ marginTop: "2.5em" }}>
                        <Form
                            name="basic"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 17 }}
                        >
                            <Form.Item
                                label={
                                    <span className="font-weight-bold">
                                        {t("cv.newJob")}
                                    </span>
                                }
                            >
                                <Input
                                    className={classes.search}
                                    onChange={(e) =>
                                        onInputChange(e.target.value)
                                    }
                                    value={newJob || ""}
                                    style={{ marginLeft: "5px" }}
                                />
                            </Form.Item>{" "}
                            <Typography
                                variant="subtitle2"
                                style={{ marginTop: "-15px" }}
                            >
                                {t("cv.searchJobAssociate")}{" "}
                                <b> {newJob || ""}</b>.
                            </Typography>{" "}
                            <Form.Item
                                className="mt-3"
                                label={
                                    <span className="font-weight-bold">
                                        {t("cv.jobAssociate")}
                                    </span>
                                }
                            >
                                <DebounceSelect
                                    showSearch
                                    dropdownStyle={{ zIndex: 2000 }}
                                    allowClear
                                    placeholder={t(
                                        "cv.jobAssociateSearchPlaceholder"
                                    )}
                                    fetchOptions={fetchJobs}
                                    onChange={(_, option) => {
                                        setJobAssociated(option);
                                    }}
                                    style={{
                                        width: "350px",
                                        marginLeft: "5px",
                                    }}
                                />
                            </Form.Item>
                        </Form>
                    </Space>
                    <hr />
                    <div style={{ marginTop: "-2em" }}>
                        <DialogActions
                            cancelLabel="cancel"
                            cancel={toggleModal}
                            submitLabel={t("cv.addReferential")}
                            submit={submit}
                            processing={processing}
                        />
                    </div>
                </div>
            </DialogContent>
        </MuiDialog>
    );
};

export default NewJobReferential;
