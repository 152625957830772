import React from "react";
import useStyles from "../makeStyles";
import { DialogContent } from "@material-ui/core";
import { DialogTitle, MuiDialog } from "../../common-components";
import { useTranslation } from "react-i18next";
import { Button, List, Space, Badge, Typography } from "antd";
import { filterByContract, filterByDate, searchFilter } from "utils/utils";

const JobOfferStat = ({
    open,
    toggleModal,
    jobStats,
    contractFilters,
    totalJobs,
    filterItems,
    isCriteria,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <MuiDialog open={open} maxWidth="sm">
            <DialogTitle
                title={
                    isCriteria ? (
                        <span>
                            {t("jobSites.jobPositionsRequested")}
                            <Badge
                                count={`${jobStats?.length || ""}`}
                                overflowCount={10000000}
                                className="ml-1"
                                style={{
                                    backgroundColor: "#198754",
                                    marginTop: "-3px",
                                }}
                            />{" "}
                        </span>
                    ) : (
                        `${t("jobSites.jobOfferStatTitle")}`
                    )
                }
                onClose={() => toggleModal(null)}
            />

            <DialogContent className={classes.dialogContent}>
                <div className="mb-4 mt-0">
                    {!isCriteria && (
                        <Space size={0}>
                            <Typography className="font-weight-bold">
                                {t("jobSites.jobOfferTotalLabel")} :{" "}
                            </Typography>
                            <Badge
                                count={`${totalJobs}`}
                                size="small"
                                className="ml-1"
                                overflowCount={10000000}
                                style={{
                                    marginTop: "-2px",
                                    backgroundColor: "#198754",
                                }}
                            />
                        </Space>
                    )}

                    <List
                        size="small"
                        className={!isCriteria ? "mt-3" : ""}
                        dataSource={jobStats}
                        renderItem={(item, idx) => {
                            const { dateFilter, search } = filterItems || {};
                            let filteredJobs = filterByContract(
                                item?.result,
                                contractFilters
                            );
                            filteredJobs = searchFilter(filteredJobs, search);
                            filteredJobs = filterByDate(
                                filteredJobs,
                                dateFilter
                            );

                            return (
                                <List.Item
                                    key={idx}
                                    extra={
                                        !isCriteria && (
                                            <Badge
                                                count={`${
                                                    filteredJobs?.length || 0
                                                }`}
                                                className="ml-2"
                                                overflowCount={10000000}
                                                style={{
                                                    backgroundColor: "#E8F5E9",
                                                    color: "green",
                                                    fontWeight: "bold",
                                                    fontSize: 13,
                                                }}
                                            />
                                        )
                                    }
                                    className="px-0"
                                    style={
                                        idx % 2 === 0
                                            ? { background: "#fafafa" }
                                            : {}
                                    }
                                >
                                    <Space size={0}>
                                        <span style={{ fontSize: 13.5 }}>
                                            {item?.job?.appellation ||
                                                item?.appellation ||
                                                ""}
                                        </span>
                                    </Space>
                                </List.Item>
                            );
                        }}
                    />
                </div>
                <div className="d-flex justify-content-center pb-3 mt-4">
                    <Button
                        style={{
                            background: "#00536D",
                            color: "#fff",
                            borderRadius: 4,
                            marginTop: "2em",
                        }}
                        onClick={() => toggleModal(null)}
                    >
                        Ok
                    </Button>
                </div>
            </DialogContent>
        </MuiDialog>
    );
};

export default JobOfferStat;
