import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getSession, setSession } from "utils/utils";
import { useLocation } from "react-router-dom";
import { Tabs } from "antd";
import Introduction from "../Introduction";
import SavedJobs from "components/SavedJobs";
import SearchJob from "../SearchJob";
import Layout from "../Layout";

const { TabPane } = Tabs;

const MyJobs = () => {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [activeTab, setActiveTab] = useState(getSession("menuTab") || "1");
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get("userId");
    const tab3 = searchParams.get("tab3");
    const lang =
        searchParams.get("lang") || window?.localStorage?.i18nextLng || "fr";

    const onTabChange = (key) => {
        setActiveTab(key);
        setSession("menuTab", key);
    };

    useEffect(() => {
        i18n.changeLanguage(lang);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout>
            <Tabs activeKey={activeTab} onChange={onTabChange}>
                <TabPane tab={t("tabs.intro")} key="1">
                    <Introduction />
                </TabPane>
                <TabPane tab={t("tabs.jobOffers")} key="2" disabled={!userId}>
                    <SavedJobs embed={true} />
                </TabPane>
                <TabPane
                    tab={t("tabs.newSearch")}
                    key="3"
                    disabled={!userId || `${tab3}` !== "1"}
                >
                    <SearchJob />
                </TabPane>
            </Tabs>
        </Layout>
    );
};

export default MyJobs;
