import { deleteMl, getMl, postMl, putMl } from "utils/api";
import { url } from "./url";

export const fetchSkillAndJob = async (text, language, cb = () => {}) => {
    try {
        // const response = await api.postPoleEmploi(
        //     url.cv.suggest,
        //     { text, language }
        // )
        const response = await getMl(url.cv.scan, {
            text,
            lang: language === "fr" ? "fr" : "en",
        });

        console.log("SCAN RESULT", response);
        if (response?.status === 200) {
            cb();
            return response?.data;
        }
    } catch (err) {
        console.log(err);
    }
};

const getTypeUrl = (type) => {
    return type === "skill"
        ? url.cv.skills
        : type?.includes("searchJobfr")
        ? url.cv.searchJobFr
        : type?.includes("searchJoben")
        ? url.cv.searchJobEn
        : type?.includes("searchJobStatfr")
        ? url.cv.searchJobStatFr
        : type?.includes("searchJobStaten")
        ? url.cv.searchJobStatEn
        : type === "insertCV"
        ? url.cv.insertCV
        : type === "statusSearch"
        ? url.cv.statusSearch
        : type && type?.includes("filteredJobsfr")
        ? url.cv.filteredJobsFr
        : type && type?.includes("filteredJobsen")
        ? url.cv.filteredJobsEn
        : type?.includes("addJobfr")
        ? url.cv.addJobFr
        : type?.includes("addJoben")
        ? url.cv.addJobEn
        : type?.includes("getJobItemfr")
        ? url.cv.getJobItemFr
        : type?.includes("getJobItemen")
        ? url.cv.getJobItemEn
        : type?.includes("searchSkillsfr")
        ? url.cv.searchSkillsFr
        : type?.includes("searchSkillsen")
        ? url.cv.searchSkillsEn
        : type === "getJobOffer"
        ? url.job.getJobOffer
        : type === "addJobOffer"
        ? url.job.addJobOffer
        : type === "deleteJobOffer"
        ? url.job.deleteJobOffer
        : type === "addJobPosition"
        ? url.job.addJobPosition
        : url.cv.jobs;
    // : type === "searchSkills2"
    // ? url.cv.searchSkills2
    // : type === "searchSkills"
    // ? url.cv.searchSkills
};

const getTypeUrlTrain = (type) => {
    return type === "skill" ? url.cv.trainSkill : url.cv.trainJob;
};

export const create = async (data, type, cb = (result) => {}) => {
    try {
        const response = await postMl(
            type === "searchSkillfr" || type === "searchSkillen"
                ? `${getTypeUrl(type)}?id=${data}`
                : getTypeUrl(type),
            data
        );
        return response;
        // cb(response);
    } catch (err) {
        console.log(err);
    }
};

export const createBatch = async (
    data,
    type,
    language,
    cb = (result) => {}
) => {
    let newData = data.map((item) => {
        let langAtt =
            language === "fr" ? "preferredlabel_fr" : "preferredlabel_en";
        let newItem = {
            train: item.train,
            [langAtt]: item.name,
        };
        return {
            preferredlabel_fr: "",
            preferredlabel_en: "",
            test: "",
            train: "",
            ...newItem,
        };
    });
    try {
        const response = await postMl(getTypeUrl(type) + "list", newData);
        console.log(`Validate ${type} response`, response);
        cb(response);
    } catch (err) {
        console.log(err);
    }
};

export const getAll = async (type, cb = () => {}) => {
    try {
        const response = await getMl(getTypeUrl(type));
        if (response?.status === 200) {
            cb();
            return response?.data;
        }
    } catch (err) {
        console.log(err);
    }
};

export const update = async (id, data, type, cb = (result) => {}) => {
    try {
        const response = await putMl(getTypeUrl(type) + `${id}`, data);
        cb(response);
    } catch (err) {
        console.log(err);
    }
};

export const deleteSkill = async (id, type, cb = (result) => {}) => {
    try {
        const response = await deleteMl(getTypeUrl(type) + `${id}`);
        cb(response);
    } catch (err) {
        console.log(err);
    }
};

export const train = async (language, data, type, cb = (result) => {}) => {
    const key = language === "fr" ? "preferredlabel_fr" : "preferredlabel_en";
    try {
        const response = await postMl(
            getTypeUrlTrain(type) +
                `?lang=${language?.includes("en") ? "en" : "fr"}`,
            {
                preferredlabel: data.map((v) => v[key]),
            }
        );
        cb(response);
    } catch (err) {
        console.log(err);
    }
};
