import React, { useState, useEffect } from "react";
import clsx from "clsx";
import useStyles from "../makeStyles";
import JobDescription from "./JobDescription";
import JobStats from "./JobStats";
import jobDescs from "utils/rome-jobdesc.json";
import JobList from "./JobList";
import { useTranslation } from "react-i18next";
import { Badge, Space } from "antd";
import { Typography, Grid } from "@material-ui/core";
import { getJobSkills, getSoftSkill } from "service/job-service";
import { delay, getSession, isFr, scrollToTop, setSession } from "utils/utils";
import { RiAddBoxLine } from "react-icons/ri";
import { BiHide } from "react-icons/bi";

const JobPosition = (props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [jobSkills, setJobSkills] = useState([]);
    const [softSkills, setSoftSkills] = useState([]);
    const [expandedRow, setExpandedRow] = useState(null);
    const [loader, setLoader] = useState(false);
    const [jobDesc, setJobDesc] = useState(null);
    const [job, setJob] = useState(null);
    const [descs, setDescs] = useState(null);
    const [jobStat, setJobStat] = useState(null);
    const [jobFilters, setJobFilters] = useState(null);
    const [hiddenJobs, setHiddenJobs] = useState(getSession("hiddenJobs"));
    const { data, url, selectedRowKeys, onJobSelectChange } = props || {};
    const [filteredJobs, setFilteredJobs] = useState([]);

    let lang = i18n.language || window?.localStorage?.i18nextLng;
    lang = isFr ? "fr" : "en";

    useEffect(() => {
        const jobs = data;

        const _jobs = jobs?.length
            ? hiddenJobs?.length
                ? jobs.filter((itemB) =>
                      hiddenJobs.some(
                          (itemA) =>
                              itemA.code !== itemB.code &&
                              itemA?.appellation?.toLowerCase() !==
                                  itemB?.appellation?.toLowerCase()
                      )
                  )
                : jobs
            : [];

        setFilteredJobs(_jobs);
        scrollToTop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onRowExpand = async (expanded, item) => {
        if (expanded) {
            setExpandedRow(item);
            setLoader(true);
            setJobSkills([]);
            await delay(600);
            const code = item?.code;
            const jobSkills = await getJobSkills(code);
            const softSkills = await getSoftSkill(code, setLoader(false));
            setJobSkills(jobSkills?.length ? jobSkills.slice(0, 10) : []);
            setSoftSkills(softSkills?.skills);
            setLoader(false);
        } else {
            setExpandedRow(null);
            setJobSkills([]);
            setSoftSkills([]);
        }
    };

    const viewJobDesc = (job) => {
        const { code } = job || {};
        const x = jobDescs.filter((item) => item?.code_rome === code);
        let descs = {};
        if (x?.length) {
            descs.definition = x.filter((item) => item.code_type_texte === 1);
            descs.acces = x.filter((item) => item.code_type_texte === 2);
            descs.cond = x.filter((item) => item.code_type_texte === 3);
        }
        setJobDesc(!jobDesc);
        setJob(job);
        setDescs(descs);
    };

    const viewJobStat = (job) => {
        let jobFilters;
        if (job) {
            const { resultats, filtresPossibles: _filtresPossibles } =
                job || {};
            const { filtresPossibles } = resultats || {};
            jobFilters =
                filtresPossibles?.length || _filtresPossibles?.length
                    ? filtresPossibles || _filtresPossibles
                    : [];
        }
        setJobStat(!jobStat);
        setJob(job);
        setJobFilters(jobFilters);
    };

    const hideJob = (job) => {
        const _hiddenJobs = hiddenJobs?.length ? [...hiddenJobs, job] : [job];
        setHiddenJobs(_hiddenJobs);
        setSession("hiddenJobs", _hiddenJobs);

        const jobss = filteredJobs?.length
            ? filteredJobs.filter(
                  (item) =>
                      item?.code !== job?.code &&
                      item?.appellation?.toLowerCase() !==
                          job?.appellation?.toLowerCase()
              )
            : [];

        setFilteredJobs(jobss);
        setSession("jobs", jobss);

        if (selectedRowKeys?.length) {
            const isSelected = selectedRowKeys.some(
                (item) => item.code === job?.code
            );
            if (isSelected) {
                const newSelected = selectedRowKeys.filter(
                    (item) => item?.code !== job?.code
                );
                onJobSelectChange(null, newSelected);
            }
        }
    };

    let jobs = filteredJobs;

    // jobs = jobs?.length
    //     ? hiddenJobs?.length
    //         ? jobs.filter((itemB) =>
    //               hiddenJobs.some(
    //                   (itemA) =>
    //                       itemA.code !== itemB.code &&
    //                       itemA?.appellation?.toLowerCase() !==
    //                           itemB?.appellation?.toLowerCase()
    //               )
    //           )
    //         : jobs
    //     : [];

    return (
        <div className={jobs?.length ? classes.position : null}>
            {jobs?.length ? (
                <Grid container justifyContent="space-between" className="mb-1">
                    <Grid item xs={12} sm={11}>
                        <Space>
                            <Typography
                                className={clsx(
                                    classes.header,
                                    "font-weight-bold"
                                )}
                                align={jobs?.length ? "inherit" : "center"}
                            >
                                {t("position.title")}
                            </Typography>
                            {jobs?.length ? (
                                <Badge
                                    count={jobs?.length || 0}
                                    size="small"
                                    className="ml-1"
                                    style={{ backgroundColor: "#198754" }}
                                />
                            ) : (
                                ""
                            )}
                        </Space>
                        <Typography
                            align="justify"
                            className={clsx(
                                classes.secondary,
                                classes.font14,
                                "mt-2"
                            )}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t("position.subtitle1"),
                                }}
                            ></span>
                        </Typography>
                        <Typography
                            align="justify"
                            className={clsx(
                                classes.secondary,
                                classes.font14,
                                "mt-3"
                            )}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t("position.subtitle2"),
                                }}
                            ></span>
                        </Typography>{" "}
                        <Typography
                            align="justify"
                            className={clsx(
                                classes.secondary,
                                classes.font14,
                                "mt-3"
                            )}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t("position.subtitle3"),
                                }}
                            ></span>
                        </Typography>
                        <Typography
                            align="justify"
                            className={clsx(
                                classes.secondary,
                                classes.font14,
                                "mt-2"
                            )}
                        >
                            {lang === "fr"
                                ? `Pour chaque métier, vous pouvez accéder à des informations complémentaires en cliquant sur le bouton`
                                : `For each job, you can access additional information by clicking the`}{" "}
                            <RiAddBoxLine
                                style={{ fontSize: "22px", marginTop: "-5px" }}
                            />{" "}
                            {lang === "fr"
                                ? `Si vous le souhaitez, vous pouvez masquer un métier en cliquant sur l'icône`
                                : `button. If you wish, you can hide a job by clicking on the`}{" "}
                            <BiHide
                                style={{ fontSize: "21px", marginTop: "-3px" }}
                            />{" "}
                            {lang === "fr"
                                ? `pour qu'il n'apparaisse plus dans votre liste.`
                                : ` icon so that it no longer appears in your list.`}
                        </Typography>
                    </Grid>
                    {/* <Grid
                        item
                        xs={12}
                        sm={4}
                        className="d-flex justify-content-end"
                    >
                        {saveBtn}
                    </Grid> */}
                </Grid>
            ) : (
                ""
            )}

            <JobList
                jobs={jobs}
                selectedRowKeys={selectedRowKeys}
                onJobSelectChange={onJobSelectChange}
                loader={loader}
                viewJobDesc={viewJobDesc}
                onRowExpand={onRowExpand}
                expandedRow={expandedRow}
                softSkills={softSkills}
                jobSkills={jobSkills}
                hideJob={hideJob}
                url={url}
            />
            <JobDescription
                open={jobDesc}
                toggleModal={viewJobDesc}
                job={job}
                descs={descs}
                key="description"
            />
            <JobStats
                open={jobStat}
                toggleModal={viewJobStat}
                filters={jobFilters}
                job={job}
                key="stats"
            />
        </div>
    );
};

export default JobPosition;
