import React from "react";
import useStyles from "../makeStyles";
import JobStatsSummary from "./FilterSummary";
import { DialogContent } from "@material-ui/core";
import { DialogTitle, MuiDialog } from "../../common-components";

const JobStatistics = (props) => {
    const classes = useStyles();
    const { open, toggleModal, filters, job } = props;
    const { libelle } = job || {};

    return (
        <MuiDialog open={open} maxWidth="lg">
            <DialogTitle
                title={`${libelle || ""} - Job Statistics`}
                onClose={() => toggleModal(null)}
            />

            <DialogContent className={classes.dialogContent}>
                <div className="py-3 mb-4">
                    <JobStatsSummary filters={filters} />
                </div>
            </DialogContent>
        </MuiDialog>
    );
};

export default JobStatistics;
