import React, { useEffect } from "react";
import SkillList from "./SkillList";
import Authentication from "./Authentication";
import Cookies from "universal-cookie";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, CssBaseline } from "@material-ui/core";
import { LanguageOptions } from "components/common-components";
import { Button, Space } from "antd";
import { checkToken, signout } from "service/authentication-service";
import { snackbarAlert } from "utils/utils";

const cookie = new Cookies();

const BackOffice = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const user = cookie.get("user");
    const { token } = user || {};

    useEffect(() => {
        if (token) {
            checkToken(token).then((value) => {
                if (value.status === 401) {
                    cookie.remove("user");
                    snackbarAlert(
                        enqueueSnackbar,
                        [t("auth.expired")],
                        "error"
                    );
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logout = async () => {
        if (user) {
            const response = await signout(token);
            if (response?.status === 200) {
                cookie.remove("user");
            }
        }
        history.push("/");
    };

    return (
        <Container component="main" className="mb-4">
            <CssBaseline />
            <div className="pt-4">
                <div className="d-flex justify-content-end">
                    <Space>
                        <Button onClick={logout}>
                            {user ? t("auth.logout") : t("auth.goToHomepage")}
                        </Button>
                        <LanguageOptions component="appbar" />
                    </Space>
                </div>
                <div className="h-100 mt-4">
                    {user ? <SkillList /> : <Authentication />}
                </div>
            </div>
        </Container>
    );
};

export default BackOffice;
