import React from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Typography } from "@material-ui/core";

const SkillsLoader = () => {
    const { t } = useTranslation();

    return (
        <div className="d-flex justify-content-center py-4 flex-column align-items-center mt-4">
            <CircularProgress color="primary" size={34} />
            <Typography variant="subtitle2" className="mt-4">
                {t("cv.analyzingSkillsJobs")}
            </Typography>
            <Typography variant="subtitle2" className="mt-1">
                {t("cv.mightTakeTime")}
            </Typography>
        </div>
    );
};

export default SkillsLoader;
