import React, { useEffect, useState } from "react";
import JobFilters from "./JobFilters";
import JobBoardsList from "./JobBoardsList";
import useStyles from "../makeStyles";
import clsx from "clsx";
import JobOfferStat from "./JobOfferStat";
import JobBoardHelp from "./JobBoardHelp";
import { useTranslation } from "react-i18next";
import { Badge, Input, Divider, Empty, Space, Button } from "antd";
import { Pagination, Tooltip } from "antd";
import { Grid, Typography } from "@material-ui/core";
import { getAll } from "utils/api";
import { Loader } from "../../common-components";
import {
    getSession,
    setSession,
    searchFilter,
    isFr,
} from "../../../utils/utils";
import { scrollToTop } from "../../../utils/utils";
import { filterByDate, filterByContract } from "../../../utils/utils";
import { FaInfoCircle } from "react-icons/fa";

const JobBoards = (props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [processing, setProcessing] = useState(false);
    const [openStat, setOpenStat] = useState(false);
    const [loader, setLoader] = useState(true);
    const [result, setResult] = useState([]);
    const [contractFilters, setContractFilters] = useState(null);
    const [jobStats, setJobStats] = useState(null);
    const [help, setHelp] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterItems, setFilterItems] = useState({
        openContract: false,
        search: "",
        dateFilter: "all",
        checkedList: null,
        checkedListCopy: null,
    });
    const selectedJob = getSession("selectedJob");
    const selectedRowKeys = props?.selectedRowKeys?.length
        ? props?.selectedRowKeys
        : getSession("selectedRowKeys")?.length
        ? getSession("selectedRowKeys")
        : null;

    let lang = i18n.language || window?.localStorage?.i18nextLng;
    lang = isFr ? "fr" : "en";

    useEffect(() => {
        getJobBoardsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getJobBoardsList = () => {
        const criteria = getSession("criteria");
        const { typeContracts: contractFilters, date } = criteria;

        setProcessing(true);
        Promise.all(
            selectedRowKeys.map(async (job) => {
                const result = await getJobBoardItem(job);
                return {
                    job,
                    result,
                    resultLength: Array.isArray(result) ? result.length : 0,
                };
            })
        )
            .then((results) => {
                const mergedResults = results.reduce((merged, result) => {
                    if (
                        Array.isArray(result?.result) &&
                        result?.result?.length > 0
                    ) {
                        merged.push(...result?.result);
                    }
                    return merged;
                }, []);

                setJobStats(results);
                setResult(mergedResults);
                setLoader(false);
                setProcessing(false);
                setContractFilters(contractFilters);
                setFilterItems((prevState) => ({
                    ...prevState,
                    checkedList: contractFilters,
                    checkedListCopy: contractFilters,
                    dateFilter: date ? date : "all",
                }));
                scrollToTop();
            })
            .catch((error) => {
                setLoader(false);
                setProcessing(false);
                console.error("Error fetching data:", error);
            });
    };

    const getJobBoardItem = async (job) => {
        const { code } = job || {};
        const criteria = getSession("criteria");
        const { dept, region, addressType } = criteria;

        let params = { codeROME: code };
        if (addressType === "region" && region) params.region = region;
        if (addressType === "dept" && dept) params.departement = dept;
        const { status, data } = await getAll("pe/jobs", params);

        if (status === 200 || status === 206) {
            const { resultats } = data || {};
            let result = resultats?.length ? resultats : [];

            result = result?.length
                ? result.sort((a, b) => {
                      const dateA = new Date(a.dateActualisation);
                      const dateB = new Date(b.dateActualisation);

                      if (dateA > dateB) return -1;
                      if (dateA < dateB) return 1;
                      return 0;
                  })
                : [];

            return result;
        }
        return;
    };

    const handleInput = (name, value) => {
        setFilterItems((prevState) => ({ ...prevState, [name]: value }));
    };

    const toggleHelp = () => setHelp(!help);

    const filterList = async (action) => {
        const { checkedListCopy } = filterItems || {};
        let _criteria = getSession("criteria");
        const _contractFilters =
            action === "reset"
                ? []
                : action === "cancel"
                ? contractFilters
                : checkedListCopy?.length
                ? checkedListCopy
                : [];

        _criteria.typeContracts = _contractFilters;
        setContractFilters(_contractFilters);
        setSession("criteria", _criteria);
        handleInput("checkedList", _contractFilters);
        handleInput("openContract", false);
    };

    const getFilters = (type) => {
        let filters = result?.length
            ? result
                  .filter(
                      (v, i, a) => a.findIndex((t) => t[type] === v[type]) === i
                  )
                  .map((item) => {
                      return {
                          text: item[type],
                          value: item[type],
                      };
                  })
            : [];

        if (type === "typeContrat") {
            const contracts = getSession("criteria")?.typeContracts;
            if (contracts?.length) {
                const x = contracts.map((item) => {
                    return { text: item, value: item };
                });
                filters = x;
            }
        }
        return filters;
    };

    const onPageChange = (page) => setCurrentPage(page);

    const onViewStat = () => setOpenStat(!openStat);

    const { appellation, libelle } = selectedJob || {};
    const { dateFilter } = filterItems || {};
    let filteredJobs = result?.length ? result : [];

    filteredJobs = filterByContract(filteredJobs, contractFilters);

    const dupJobs = filteredJobs?.length ? [...filteredJobs] : [];

    // filter by search
    filteredJobs = searchFilter(filteredJobs, filterItems?.search);

    // date filters
    filteredJobs = filterByDate(filteredJobs, dateFilter);

    let _results = [...filteredJobs];
    _results = _results?.length
        ? _results.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
        : [];

    return (
        <div className={classes.position}>
            <>
                {!processing && (
                    <>
                        <Typography className="font-weight-bold">
                            {t("jobSites.jobBoardsTitle")}
                        </Typography>

                        <Typography
                            align="justify"
                            className={clsx(
                                classes.secondary,
                                classes.font14,
                                "mb-3"
                            )}
                        >
                            {lang === "fr"
                                ? `Vous retrouverez ci-dessous la liste des offres d'emploi publiées qui tient compte des métiers et des compétences que vous avez sélectionnés . Cliquez`
                                : "Below you will find the list of published job offers which take into account the professions and skills you have selected. Click "}{" "}
                            <Button
                                type="link"
                                className="p-0 font-weight-bold"
                                onClick={toggleHelp}
                            >
                                {lang === "fr" ? "ici" : "here"}
                            </Button>{" "}
                            {lang === "fr"
                                ? `pour découvrir toutes les fonctionnalités qui pourront vous être utile dans votre recherche d'emploi.`
                                : "to discover all the features that may be useful to you in your job search. "}{" "}
                            <Button
                                type="link"
                                className="p-0 font-weight-bold"
                                onClick={toggleHelp}
                                style={{ marginTop: "-1.2em" }}
                            >
                                {t("jobSites.help.buttonText")}
                            </Button>
                        </Typography>

                        <Grid
                            container
                            justifyContent="space-between"
                            className="mt-3"
                        >
                            <Grid item>
                                {jobStats?.length ? (
                                    <>
                                        {jobStats?.length === 1 ? (
                                            <>
                                                <Space size={0}>
                                                    <Typography
                                                        className="font-weight-bold"
                                                        style={{ fontSize: 14 }}
                                                    >
                                                        {appellation || ""}
                                                    </Typography>
                                                    {filteredJobs?.length ? (
                                                        <Badge
                                                            count={`${
                                                                filteredJobs.length
                                                            } ${t(
                                                                "jobSites.totalJobOpening"
                                                            )}`}
                                                            overflowCount={
                                                                10000000
                                                            }
                                                            size="small"
                                                            className="ml-1"
                                                            style={{
                                                                backgroundColor:
                                                                    "#198754",
                                                            }}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </Space>
                                                <Typography
                                                    className={clsx(
                                                        classes.secondary,
                                                        classes.font14
                                                    )}
                                                    style={{ fontSize: 14 }}
                                                >
                                                    {libelle || ""}
                                                </Typography>
                                            </>
                                        ) : (
                                            <>
                                                <div>
                                                    <Space size={0}>
                                                        <Typography
                                                            className={clsx(
                                                                classes.secondary,
                                                                classes.font14,
                                                                "font-weight-bold"
                                                            )}
                                                            style={{
                                                                fontSize: 14,
                                                            }}
                                                        >
                                                            {t(
                                                                "jobSites.listTotalJobOffer"
                                                            )}
                                                            :
                                                        </Typography>
                                                        <Badge
                                                            count={`${
                                                                filteredJobs.length
                                                            } ${t(
                                                                "jobSites.totalJobOfferBadge"
                                                            )}`}
                                                            size="small"
                                                            overflowCount={
                                                                10000000
                                                            }
                                                            className="ml-2"
                                                            style={{
                                                                backgroundColor:
                                                                    "#198754",
                                                                marginTop:
                                                                    "-2px",
                                                            }}
                                                        />
                                                    </Space>
                                                </div>
                                                <Space size={0}>
                                                    <Typography
                                                        style={{
                                                            fontSize: 14,
                                                            color: "#000",
                                                        }}
                                                    >
                                                        {jobStats?.length || 0}{" "}
                                                        {t(
                                                            "jobSites.jobPositionsRequested"
                                                        )}
                                                    </Typography>
                                                    <Tooltip
                                                        title={t(
                                                            "jobSites.seeJobOffersDetails"
                                                        )}
                                                        placement="right"
                                                    >
                                                        <FaInfoCircle
                                                            onClick={onViewStat}
                                                            style={{
                                                                cursor: "pointer",
                                                                fontSize: 16,
                                                                color: "#1890FF",
                                                                marginLeft:
                                                                    "5px",
                                                                marginTop:
                                                                    "-2px",
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </Space>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    ""
                                )}
                            </Grid>
                            <Grid item>
                                <Space>
                                    {filteredJobs?.length ? (
                                        <Pagination
                                            defaultCurrent={1}
                                            total={filteredJobs?.length || 0}
                                            showSizeChanger={false}
                                            simple
                                            current={currentPage}
                                            onChange={onPageChange}
                                        />
                                    ) : (
                                        ""
                                    )}
                                    {/* {props.saveBtn} */}
                                </Space>
                            </Grid>
                        </Grid>
                        <Divider className="mt-2" />
                    </>
                )}
                <Grid container justifyContent="space-between" className="mt-2">
                    <Grid item xs={12} sm={6}>
                        <Space className={classes.searchSmUp}>
                            <Typography
                                className={clsx(
                                    classes.searchText,
                                    classes.font14
                                )}
                            >
                                {t("jobSites.searchJobLabel")}:
                            </Typography>
                            <Input
                                placeholder={t("jobSites.searchJobPlaceholder")}
                                autoFocus
                                className={classes.search}
                                onChange={({ target: { value } }) =>
                                    handleInput("search", value)
                                }
                            />
                            {filterItems?.search && (
                                <Typography variant="subtitle2">
                                    {filteredJobs.length} {t("result")}
                                    {filteredJobs.length > 1 ? "s" : ""} of{" "}
                                    {dupJobs?.length || 0}
                                </Typography>
                            )}
                        </Space>{" "}
                        <Input
                            placeholder={t("jobSites.searchJobPlaceholder")}
                            autoFocus
                            className={classes.searchxs}
                            onChange={({ target: { value } }) =>
                                handleInput("search", value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.filters}>
                        <JobFilters
                            handleInput={handleInput}
                            filterList={filterList}
                            filterItems={filterItems}
                            items={result}
                        />
                    </Grid>
                </Grid>
                {selectedRowKeys?.length ? (
                    <>
                        {processing || loader ? (
                            <Loader />
                        ) : (
                            <JobBoardsList
                                currentPage={currentPage}
                                getFilters={getFilters}
                                jobs={_results}
                                defJobsLength={result?.length}
                            />
                        )}
                    </>
                ) : (
                    <Empty description={t("noDataFound")} className="mt-4" />
                )}
            </>

            <JobOfferStat
                open={openStat}
                toggleModal={onViewStat}
                jobStats={jobStats}
                contractFilters={contractFilters}
                totalJobs={filteredJobs?.length || 0}
                filterItems={filterItems}
            />
            <JobBoardHelp open={help} toggleModal={toggleHelp} />
        </div>
    );
};

export default JobBoards;
