import React, { useState, useEffect, useRef } from "react";
import useStyles from "./makeStyles";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { TableContainer } from "@material-ui/core";
import { Button, Form, Input, Radio, Space, Table, Typography } from "antd";
import { deleteSkill, train, update } from "service/cv-service";
import { Loader } from "components/common-components";
import { snackbarAlert } from "utils/utils";

const EditableCell = ({
    editing,
    dataIndex,
    value,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode =
        inputType === "text" ? (
            <Input />
        ) : (
            <Radio.Group>
                <Radio value={true}>true</Radio>
                <Radio value={false}>false</Radio>
            </Radio.Group>
        );
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const SkillTable = (props) => {
    const classes = useStyles();
    const formRef = useRef();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [state, setState] = useState({
        page: 1,
        editingKey: "",
        clearingKey: "",
        sortedInfo: {},
        loadItem: false,
        loadClear: false,
        selectedRow: [],
        loading: false,
    });
    const { data, setData, loadData, type } = props;

    useEffect(() => {
        formRef.current.setFieldsValue({
            preferredlabel_fr: "java",
            preferredlabel_en: "java",
            train: true,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _alert = (data, type) => {
        snackbarAlert(enqueueSnackbar, data, type);
    };

    const isEditing = (item) => item.id === state.editingKey;

    const title = (label) => (
        <span className={clsx(classes.th)}>
            {t(`auth.${label}`).toUpperCase()}
        </span>
    );

    const edit = (record) => {
        formRef.current.setFieldsValue({
            preferredlabel_fr: "java",
            preferredlabel_en: "java",
            train: true,
            ...record,
        });
        setState((prevState) => ({
            ...prevState,
            editingKey: record.id,
        }));
    };

    const save = async (key) => {
        setState((prevState) => ({
            ...prevState,
            loadItem: true,
        }));
        try {
            const row = await formRef.current.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.id);

            if (index > -1) {
                const item = newData[index];
                await update(item.id, { ...item, ...row }, type, (result) => {
                    if (result.status === 200) {
                        newData.splice(index, 1, { ...item, ...row });
                        setState((prevState) => ({
                            ...prevState,
                            editingKey: "",
                            loadItem: false,
                        }));
                        setData(newData);
                    } else {
                        setState((prevState) => ({
                            ...prevState,
                            editingKey: "",
                            loadItem: false,
                        }));
                        _alert([result.data.details.msg], "error");
                    }
                });
            }
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
            setState((prevState) => ({
                ...prevState,
                loadItem: false,
            }));
        }
    };

    const cancel = () => {
        setState((prevState) => ({
            ...prevState,
            editingKey: "",
        }));
    };

    const clear = async (index) => {
        setState((prevState) => ({
            ...prevState,
            loadClear: true,
            clearingKey: index,
        }));

        let newData = [...data];
        let selectedRow = state.selectedRow;
        await deleteSkill(index, type, (result) => {
            if (result.status === 200) {
                newData = newData.filter((item) => item.id !== index);
                selectedRow = selectedRow.filter((item) => item !== index);
                setState((prevState) => ({
                    ...prevState,
                    loadClear: false,
                    clearingKey: "",
                    selectedRow: selectedRow,
                }));
                setData(newData);
            } else {
                setState((prevState) => ({
                    ...prevState,
                    loadClear: false,
                    clearingKey: "",
                }));

                _alert([result.data.details.msg], "error");
            }
        });
    };

    const onSelectionChange = (newSelectedRowKeys) => {
        setState((prevState) => ({
            ...prevState,
            newSelectedRowKeys,
        }));
    };

    const rowSelection = {
        selectedRowKey: state.selectedRow,
        onChange: onSelectionChange,
    };

    const columns = [
        {
            title: title("french"),
            render: (_, item, index) => {
                return <span key={item.id}>{item.preferredlabel_fr}</span>;
            },
            dataIndex: "preferredlabel_fr",
            key: "preferredlabel_fr",
            width: "35%",
            editable: true,
            sorter: (a, b) =>
                ("" + a.preferredlabel_fr).localeCompare(b.preferredlabel_fr),
            sortOrder:
                state.sortedInfo.columnKey === "preferredlabel_fr"
                    ? state.sortedInfo.order
                    : null,
        },
        {
            title: title("english"),
            render: (_, item, index) => {
                return <span key={item.id}>{item.preferredlabel_en}</span>;
            },
            dataIndex: "preferredlabel_en",
            key: "preferredlabel_en",
            editable: true,
            sorter: (a, b) =>
                ("" + a.preferredlabel_en).localeCompare(b.preferredlabel_en),
            sortOrder:
                state.sortedInfo.columnKey === "preferredlabel_en"
                    ? state.sortedInfo.order
                    : null,
        },
        {
            render: (_, item, index) => {
                return (
                    <Space className="float-right">
                        <span>
                            {isEditing(item) ? (
                                <Space>
                                    <Typography.Link
                                        onClick={() => save(item.id)}
                                        disabled={state.loadItem}
                                    >
                                        {t("save")}
                                    </Typography.Link>
                                    <Typography.Link
                                        onClick={cancel}
                                        disabled={state.loadItem}
                                    >
                                        {t("cancel")}
                                    </Typography.Link>
                                </Space>
                            ) : (
                                <Typography.Link
                                    onClick={() => edit(item)}
                                    disabled={state.editingKey !== ""}
                                >
                                    {t("edit")}
                                </Typography.Link>
                            )}
                        </span>

                        <Button
                            danger
                            className="float-right"
                            key={index}
                            onClick={() => clear(item.id)}
                            loading={
                                state.loadClear && item.id === state.clearingKey
                            }
                        >
                            {t("clear")}
                        </Button>
                    </Space>
                );
            },
            key: "clear",
            width: "20%",
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === "train" ? "radio" : "text",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const handleChange = (pagination, filters, sorter) => {
        setState((prevState) => ({
            ...prevState,
            sortedInfo: sorter,
            page: pagination.current,
            editingKey: "",
        }));
    };

    const cb = (result) => {
        if (result?.status === 200) {
            setState((prevState) => ({
                ...prevState,
                loading: false,
            }));
            _alert(["Training complete"], "success");
        } else {
            _alert(["Error"], "error");
            setState((prevState) => ({
                ...prevState,
                loading: false,
            }));
        }
    };

    const trainSkill = async (type, language) => {
        setState((prevState) => ({
            ...prevState,
            loading: true,
        }));

        let lang = language?.includes("fr") ? "fr" : "en";
        let dataSelected = data.filter((obj) =>
            state.selectedRow.find((id) => id === obj?.id)
        );
        await train(lang, dataSelected, type, cb);
    };

    return (
        <div>
            <TableContainer className="mt-2">
                {loadData ? (
                    <Loader></Loader>
                ) : (
                    <Form ref={formRef}>
                        <Table
                            key="list"
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            rowKey="id"
                            size="small"
                            rowSelection={rowSelection}
                            columns={mergedColumns}
                            dataSource={data}
                            // pagination={{
                            //     onChange(current) {
                            //         setState({page: current, editingKey: ""})
                            //     }
                            // }}
                            onChange={handleChange}
                        />
                    </Form>
                )}
            </TableContainer>
            <div className="d-flex">
                <Space>
                    <Button
                        onClick={() => trainSkill(type, "fr")}
                        loading={state.loading}
                        disabled={state.selectedRow?.length === 0}
                    >
                        Entrainer (Français)
                    </Button>
                    <Button
                        onClick={() => trainSkill(type, "en")}
                        loading={state.loading}
                        disabled={state.selectedRow?.length === 0}
                    >
                        Train (English)
                    </Button>
                </Space>
            </div>
        </div>
    );
};

export default SkillTable;
