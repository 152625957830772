import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import Criteria from "../Criteria";
import JobBoards from "../JobBoards";
import JobPositions from "../JobPosition";
import Skills from "../Skills";
import useStyles from "../makeStyles";
import NoJobFound from "../JobPosition/NoJobFound";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { Backdrop, Typography } from "@material-ui/core";
import { Button, Space } from "antd";
import { DialogLoader, ProgressLoader } from "../../common-components";
import { ConfirmSave, JobSteppers } from "../../common-components";
import { StepperActions } from "../../common-components";
import { delay, getSession, setSession, snackbarAlert } from "utils/utils";
import { scrollToTop } from "utils/utils";
import { getExtSessionServer, type } from "service/session-service";
import { setSessionServer, getSessionServer } from "service/session-service";
import { create, createBatch, fetchSkillAndJob } from "service/cv-service";
import { BiSave } from "react-icons/bi";
import { SemipolarLoading } from "react-loadingg";
import { create as createApi, getAll, getMl, postMl } from "utils/api";

const cookie = new Cookies();

const SearchJob = () => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const steps = ["skills", "jobPosition", "criteria", "jobBoards"];

    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get("userId");
    const appRef = searchParams.get("appRef");
    const lang =
        searchParams.get("lang") ||
        window?.localStorage?.i18nextLng ||
        i18n.language ||
        "fr";
    const url = searchParams.get("url");
    const nextUrl = searchParams.get("nextUrl");
    const [progress, setProgress] = useState(2);

    const [state, setState] = useState({
        step: getSession("step") ? getSession("step") : 0,
        owner: getSession("file") ? getSession("file")?.owner : "",
        fileUpload: getSession("file") ? getSession("file")?.fileUpload : false,
        file: {},
        criteria: {},
        validateStep: getSession("validateStep")
            ? getSession("validateStep")
            : 0,
        validateSkillAdd: getSession("validateSkillAdd")
            ? getSession("validateSkillAdd")
            : false,
        onValidate: getSession("onValidate") || false,
        tab: "1",
        isBottom: false,
    });

    useEffect(() => {
        getState();
        i18n.changeLanguage(lang);
        updateState({ lang });

        const _step = getSession("step");
        const taskId = getSession("taskid");
        if (url && !getSession("onValidate") && _step < 1 && !taskId) {
            uploadPDF();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getState = async () => {
        let step = getOriginStep();
        await getUserSession();
        const _fileUploaded =
            getSession("file")?.fileUpload && getSession("file")?.fileData;

        if (_fileUploaded) {
            step = getSession("step") ? getSession("step") : step;
            setState((prevState) => ({
                ...prevState,
                step,
                file: getSession("file")?.fileData
                    ? JSON.parse(getSession("file").fileData)
                    : {},
                fileUpload: getSession("file")
                    ? getSession("file")?.fileUpload
                    : false,
                criteria: getSession("criteria"),
                jobs: getSession("jobs"),
                selectedJob: getSession("selectedJob"),
                selectedJobs: getSession("selectedJobs"),
                suggestedJobs: getSession("suggestedJobs"),
                suggestedSkills: getSession("suggestedSkills"),
                onValidate: getSession("onValidate"),
                hasChosen: getSession("hasChosen"),
                // hasChosen: getSession("hasChosen", false),
                chosenJobs: getSession("chosenJobs", []),
                chosenSkills: getSession("chosenSkills", []),
                taskid: getSession("taskid"),
                selectedSkills: getSession("selectedSkills"),
                selectedJobCode: getSession("selectedJobCode"),
                selectedRowKeys: getSession("selectedRowKeys"),
            }));
        }
    };

    const _alert = (data, type) => snackbarAlert(enqueueSnackbar, data, type);

    const process = (processing) => updateState({ processing });

    const updateState = (state) => {
        setState((prevState) => ({ ...prevState, ...state }));
    };

    const updateSession = (data) => {
        Object.entries(data).forEach(([key, value]) => {
            setSession(key, value);
        });
    };

    const getOriginStep = () => {
        const originStep = cookie.get("originStep");
        const sessionStep = getSession("step", 0);
        return originStep
            ? originStep <= sessionStep
                ? sessionStep
                : originStep
            : sessionStep;
    };

    const handleStep = (step) => {
        return cookie.get("originStep") > step
            ? parseInt(cookie.get("originStep"))
            : step;
    };

    const onStepChange = (s) => {
        const step = handleStep(s);
        updateState({ step });
        setSession("step", step);
        const maxStep = getSession("maxStep") || 0;
        setSession("maxStep", step > maxStep ? step : maxStep);

        if (step !== 0) {
            updateState({ onValidate: false });
            setSession("onValidate", false);
        } else {
            const data = {
                validateStep: 1,
                onValidate: false,
                validateSkillAdd: true,
            };
            updateState(data);
            updateSession(data);
        }
        scrollToTop();
    };

    const handleSteps = (t) => {
        const { step: current } = state || {};
        let step = t === "next" ? current + 1 : current - 1;
        step = handleStep(step);
        updateState({ step });
        const maxStep = getSession("maxStep") || 0;
        updateSession({ step, maxStep: step > maxStep ? step : maxStep });
        scrollToTop();
    };

    const handleDocuments = (newFiles) => {
        const data = {
            chosenJobs: null,
            chosenSkills: null,
            suggestedJobs: [],
            suggestedSkills: [],
            defListCount: [],
            jobs: [],
        };
        updateState({
            ...data,
            files: state?.files
                ? [...state?.files, ...newFiles]
                : [...newFiles],
        });
        updateSession(data);
    };

    const removeFile = (file) => {
        setState((prevState) => {
            const index = prevState.files.indexOf(file);
            const newFileList = prevState.files.slice();
            newFileList.splice(index, 1);
            return {
                ...prevState,
                files: newFileList,
            };
        });
    };

    const handleSelectionSkill = (skills) => {
        let selection = [];
        if (skills?.length) {
            for (let skill of skills) {
                const selected = skill.isSure ? "0" : "1";
                selection.push(selected);
            }
        }
        return selection;
    };

    const uploadPDF = async () => {
        if (url) {
            let formData = new FormData();
            formData.append("input_file", url);
            formData.append("owner", "me");

            updateState({
                processing: true,
                hasChosen: false,
                progressText: "scanningCV",
            });
            setProgress(2);
            setSession("hasChosen", false);
            const res = await createApi(`ocr/link?link=${url}`);

            console.log("OCR LINK RES", res);
            const { status, data } = res || {};
            if (res) {
                if (status === 200 && data?.id) {
                    setProgress(8);
                    await getCVData(data?.id, formData);
                } else if (status === 422) {
                    _alert([data.detail[0].msg], "error");
                    process(false);
                } else {
                    _alert([t("alert.cannotUploadCV")], "error");
                    process(false);
                }
            } else {
                history.replace({ pathname: "/server-error" });
            }
        } else {
            updateState({ fileUpload: false, other: true });
        }
    };

    const uploadCV = async () => {
        const { files } = state || {};

        if (files?.length) {
            let formData = new FormData();
            formData.append("input_file", files?.[0]);
            formData.append("owner", "me");

            updateState({
                processing: true,
                hasChosen: false,
                progressText: "scanningCV",
            });
            setProgress(2);
            setSession("hasChosen", false);
            const res = await createApi("ocr", formData);

            const { status, data } = res || {};
            if (res) {
                if (status === 200 && data?.id) {
                    setProgress(8);
                    await getCVData(data?.id, formData);
                } else if (status === 422) {
                    _alert([data.detail[0].msg], "error");
                    process(false);
                } else {
                    _alert([t("alert.cannotUploadCV")], "error");
                    process(false);
                }
            } else {
                history.replace({ pathname: "/server-error" });
            }
        } else {
            updateState({ fileUpload: false, other: true });
        }
    };

    const getCVData = async (id, formData) => {
        const { owner } = state;
        let stat = "PENDING";
        while (stat === "PENDING") {
            const tasks = await getMl(`/task/result/${id}`);
            console.log("UPLOAD CV Response", tasks);

            const cvData = { search_expression: tasks?.data?.result || "" };

            stat = tasks?.data?.status;

            if (stat === "SUCCESS") {
                setProgress(12);
                await searchJobStat(cvData);

                let file = {
                    data: tasks.data,
                    name: files?.[0]?.name,
                    owner: owner,
                };
                updateState({
                    fileUpload: true,
                    file,
                    processing: false,
                });
                setProgress(90);

                formData.append("fileData", JSON.stringify(file));
                const fileSession = {
                    fileUpload: true,
                    fileData: JSON.stringify(file),
                    owner: owner,
                };
                setSession("file", fileSession);
            } else if (stat === "FAILURE") {
                _alert([tasks.data.error], "error");
                process(false);
            } else {
                await delay(3000);
            }
        }
    };

    const searchJobStat = async (search_expression) => {
        const insertCVRes = await create(
            JSON.stringify(search_expression),
            `insertCV`
        );
        const { data: insertCVData } = insertCVRes || {};
        console.log("INSERT CV RESPONSE", insertCVRes);
        if (insertCVRes?.status === 200 && insertCVData?.results?.length) {
            setProgress(12);

            const taskid = `${insertCVData?.results?.[0]?.[0]?.task_id}`;
            const frequency = insertCVData?.results?.[0]?.[0]?.frequency;

            let searchStat = "PENDING";
            let searchStatCode = 0;
            let tries = 0;

            while (searchStat !== "TO PROCESS" && searchStatCode !== 1) {
                const taskId = { taskid };

                const statSearchRes = await create(
                    JSON.stringify(taskId),
                    `statusSearch`
                );
                console.log("STATUS SEARCH RESPONSE", statSearchRes);
                const { data: { results: statSearchResult } = {} } =
                    statSearchRes || {};

                searchStat = statSearchResult?.[0]?.status;
                searchStatCode = statSearchResult?.[0]?.status_code;
                tries = tries + 1;
                if (searchStat === "TO PROCESS" && searchStatCode === 1) {
                    updateState({ progressText: "analyzingCV" });
                    setProgress(12);

                    await delay(1500);
                    setProgress(45);

                    // Simulate API call delay
                    await new Promise((resolve) => setTimeout(resolve, 2000));
                    const intervalId = setInterval(() => {
                        setProgress((prevProg) => {
                            if (prevProg === 80) {
                                updateState({ progressText: "almostThere" });
                            }
                            const prog = prevProg < 99 ? prevProg + 1 : 99;
                            return prog;
                        });
                    }, frequency);
                    // }, frequency);

                    const resss = await create(
                        JSON.stringify(taskId),
                        `searchJobStat${lang}`
                    );

                    // Simulate API call completion
                    await new Promise((resolve) => setTimeout(resolve, 3000));
                    clearInterval(intervalId);

                    updateState({ progressText: "almostThere" });
                    await delay(1500);

                    console.log("SEARCH JOB STAT RESPONSE", resss);
                    const { status: searchJobStat, data: searchJobData } =
                        resss || {};

                    const jobList = searchJobData?.results?.[0]?.joblist;

                    console.log(jobList);

                    if (searchJobStat === 200 && jobList?.length) {
                        let defListCount = 0;
                        if (jobList?.length) {
                            jobList.forEach((item) => {
                                item.name = item?.label || "";
                                item.isSure = true;
                            });

                            defListCount = jobList.filter((item) =>
                                `${item.code}`?.startsWith("2514")
                            );
                        }

                        const _state = {
                            suggestedJobs: jobList,
                            defListCount,
                            onValidate: true,
                            taskid,
                        };
                        updateSession(_state);
                        updateState({
                            ..._state,
                            jobList,
                            selectedJobs: handleSelectionSkill(jobList),
                        });
                        console.log(jobList);
                    } else {
                        updateState({
                            onValidate: false,
                            taskid,
                            noJobFound: true,
                            hasChosen: true,
                        });
                        setSession("onValidate", false);
                        setSession("taskid", taskid);
                        setSession("hasChosen", true);
                    }
                }
                if (tries >= 10) {
                    updateState({ onValidate: true, taskid });
                    setSession("onValidate", true);
                    setSession("taskid", taskid);
                }
            }
        }
    };

    const fetchSkills = async (text) => {
        const res = await fetchSkillAndJob(text, state?.lang, () => {});

        console.log("FETCH SKILLS AND JOBS", res?.skill);
        if (res) {
            let suggestedSkills = [];
            if (res?.skill?.length) {
                let skillSet = [...new Set(res.skill)];
                for (let skill of skillSet) {
                    suggestedSkills.push({
                        id: skill,
                        description: null,
                        altlabels: null,
                        skills_relation: null,
                        name: skill,
                        isSure: true,
                    });
                }
            }
            const selectedSkills = handleSelectionSkill(suggestedSkills);
            updateState({
                suggestedSkills,
                selectedSkills,
            });
            setSession("suggestedSkills", suggestedSkills);
        }
        updateState({ loadingSuggestion: false });
    };

    const handleSelected = (type, value, index) => {
        if (type === "selectedJobs") {
            let x;
            if (state?.selectedJobs?.length) {
                x = state?.selectedJobs;
                x[index] = value;
            } else {
                x = [];
                x[index] = value;
            }
            if (state?.suggestedJobs) {
                setState((prevState) => ({
                    ...prevState,
                    [type]: x,
                }));
            }
        } else {
            setState((prevState) => ({
                ...prevState,
                [type]: {
                    ...prevState?.[type],
                    [index]: value,
                },
            }));
        }
    };

    const getSelected = (selected, train, suggested) => {
        let selectedSkills =
            (Array.isArray(selected) && selected?.length) ||
            (typeof selected === "object" && selected !== null)
                ? Object.values(selected)
                : null;
        let trained =
            (Array.isArray(train) && train?.length) ||
            (typeof train === "object" && train !== null)
                ? Object.values(train)
                : null;
        let [chosen, denied, agreed] = [[], [], []];
        if (selectedSkills?.length) {
            for (let i = 0; i < selectedSkills.length; i++) {
                if (selectedSkills?.[i] === "1") chosen.push(suggested[i]);
                if (trained?.[i] === "-1") denied.push(suggested[i]);
                if (trained?.[i] === "1" && !suggested[i]?.isSure)
                    agreed.push(suggested[i]);
            }
        }

        return { chosen, denied, agreed };
    };

    const saveSkills = async (chosen, denied, type) => {
        let newChosen = chosen.map((item) => {
            return { train: false, ...item };
        });
        let newDenied = [];
        await createBatch(
            [...newChosen, ...newDenied],
            type,
            lang,
            (result) => {
                if (result.status !== 200)
                    _alert([result.data.details.msg], "error");
            }
        );
    };

    const handleChoose = async () => {
        const { selectedJobs, suggestedJobs } = state || {};

        let chosenJobs = getSelected(selectedJobs, selectedJobs, suggestedJobs);
        const selectedJobCode = chosenJobs?.chosen?.length
            ? chosenJobs?.chosen.map((item) =>
                  item?.jobcode ? item?.jobcode?.split(".")?.[0] : ""
              )
            : [];

        updateState({
            hasChosen: true,
            onValidate: false,
            chosenJobs: chosenJobs.chosen,
            loadChoice: true,
            selectedJobCode,
        });

        await saveSkills(chosenJobs?.agreed, chosenJobs?.denied, "job");
        setSession("hasChosen", true);
        setSession("onValidate", false);
        setSession("chosenJobs", chosenJobs.chosen);
        setSession("suggestedJobs", suggestedJobs);
        setSession("selectedJobs", selectedJobs);
        setSession("selectedJobCode", selectedJobCode);

        updateState({ loadChoice: false });
        scrollToTop();
    };

    const validateSkills = async () => {
        const { selectedSkills, suggestedSkills } = state || {};
        let { agreed, denied, chosen } =
            getSelected(selectedSkills, selectedSkills, suggestedSkills) || {};

        updateState({ loadChoice: true });
        await saveSkills(agreed, denied, "skill");
        const newState = {
            chosenSkills: chosen,
            validateSkillAdd: true,
            onValidate: false,
        };
        updateState({ ...newState, loadChoice: false });
        updateSession({ ...newState, selectedSkills });
        scrollToTop();
    };

    const validateJobs = async () => {
        const { chosenJobs } = state || {};
        const sessionJobs = getSession("chosenJobs");

        const x = chosenJobs?.length
            ? chosenJobs
            : sessionJobs?.length
            ? sessionJobs
            : [];

        if (x?.length) {
            const hasIdKey = (array) => {
                for (const obj of array) {
                    if (obj.hasOwnProperty("id")) {
                        return true;
                    }
                }
                return false;
            };
            const onValidate = state?.suggestedJobs?.length ? true : false;
            const validateSkillAdd = state?.suggestedJobs?.length
                ? false
                : true;
            const validateState = {
                validateStep: 1,
                onValidate,
                validateSkillAdd,
            };
            updateSession(validateState);
            updateState({ ...validateState, loadingSuggestion: true });

            if (hasIdKey(x)) {
                const taskid = state?.taskid || "";
                const res = await create({ taskid }, `searchSkills${lang}`);

                console.log("SEARCH_SKILLS", res);
                if (res?.status === 200) {
                    const resSkill = res?.data?.results?.[0]?.[0]?.[0]?.skills;

                    let suggestedSkills = [];
                    let skillSet = [...new Set(resSkill)];
                    for (let skill of skillSet) {
                        suggestedSkills.push({
                            id: skill?.skills_id || "",
                            name: skill?.skills_SubCategory,
                            description: skill?.skills_description || "",
                            altlabels: skill?.skills_altlabels || "",
                            isSure: true,
                            category: skill?.skills_Category || "",
                            skills: skill?.skills_label || "",
                            subCategory: skill?.skills_SubCategory || "",
                            subCategoryFr: skill?.skillsfr_SubCategory,
                        });
                    }
                    const selectedJobCode = x.map((item) =>
                        item?.jobcode ? item?.jobcode?.split(".")?.[0] : ""
                    );

                    setSession("suggestedSkills", suggestedSkills);
                    if (x?.length !== getSession("chosenJobs")?.length) {
                        setSession("selectedJobCode", selectedJobCode);
                        setSession("chosenJobs", x);
                    }
                    updateState({
                        suggestedSkills,
                        selectedSkills: handleSelectionSkill(suggestedSkills),
                        loadingSuggestion: false,
                    });
                } else {
                    updateState({
                        suggestedSkills: null,
                        loadingSuggestion: false,
                    });
                    setSession("suggestedSkills", null);
                }
            } else {
                const scannedCV = getSession("file")?.fileData
                    ? JSON.parse(getSession("file")?.fileData)
                    : "";

                await fetchSkills(scannedCV?.data?.result);
            }
        } else {
            _alert([t("alert.noJobSelected")], "error");
        }
        scrollToTop();
    };

    const backToSkills = () => {
        const selectedSkills = getSession("selectedSkills");
        const validateStep = state?.suggestedJobs?.length ? 1 : 0;
        const onValidate = state?.suggestedJobs?.length ? true : false;
        const validateState = {
            validateStep,
            validateSkillAdd: false,
            onValidate,

            // validateSkillAdd: false,
            // onValidate: true,
        };
        updateState({ ...validateState, selectedSkills });
        updateSession(validateState);
        scrollToTop();
    };

    const backToJobs = () => {
        const suggestedJobs = getSession("suggestedJobs");
        const selectedJobs = getSession("selectedJobs");
        const validateState = {
            hasChosen: true,
            validateStep: 0,
            onValidate: false,
        };
        updateState({ ...validateState, suggestedJobs, selectedJobs });
        updateSession(validateState);
        scrollToTop();
    };

    const getJobBoards = async () => {
        setSession("criteria", state?.criteria);
        handleSteps("next");
    };

    const searchJob = async () => {
        const { chosenSkills, chosenJobs, step } = state || {};

        // console.log("chosenSkills", chosenSkills);
        // console.log("chosenJobs", chosenJobs);

        if (chosenSkills?.length) {
            process(true);
            const skills = chosenSkills
                .map(({ subCategoryFr }) => subCategoryFr)
                .join(" ");
            const jobs = chosenJobs.map(({ labelfr }) => labelfr).join(" ");
            const search = { skills, jobs };

            console.log("search", search);
            console.log("PROCESSING pe/metier/explorer, PLEASE WAIT...");
            const res = await getAll("pe/metier/explorer", search);
            console.log("pe/metier/explore RESPONSE", res);
            const { status, data } = res || {};

            if (status === 200) {
                let jobs = data;
                if (step === 0) {
                    let filtered = chosenJobs.filter(
                        (itemY) =>
                            !jobs.some((itemX) => itemX.code === itemY.RomeCode)
                    );
                    filtered = filtered?.length
                        ? filtered.filter((item) => item?.RomeCode)
                        : null;
                    const romeCodes = filtered?.length
                        ? filtered.map((item) => item?.RomeCode)
                        : [];

                    console.log("romeCodes", romeCodes);
                    if (romeCodes.length) {
                        const result = await getJobStat(romeCodes);
                        if (result?.length) {
                            // const x = result;
                            // x.forEach((item) => {
                            //     const y = filtered?.find(
                            //         (job) => job.RomeCode === item.code
                            //     );
                            //     item.appellation = y?.name || "";
                            // });
                            const peJobs = result.map((item) => {
                                const y = filtered?.find(
                                    (job) => job?.RomeCode === item?.code
                                );

                                const peJob = {
                                    appellation: y?.name || "",
                                    code: item?.code,
                                    codeIsco: item?.codeIsco,
                                    domaineProfessionnel:
                                        item?.domaineProfessionnel,
                                    libelle: item?.libelle,
                                    riasecMajeur: item?.riasecMajeur,
                                    riasecMineur: item?.riasecMineur,
                                };
                                return peJob;
                            });
                            console.log("peJobs", peJobs);
                            const updatedJobs = [...peJobs, ...jobs];
                            console.log("updatedJobs", updatedJobs);
                            updateState({
                                jobs: updatedJobs,
                            });
                            setSession("jobs", updatedJobs);
                        } else {
                            updateState({ jobs });
                            setSession("jobs", jobs);
                        }
                    } else {
                        updateState({ jobs });
                        setSession("jobs", jobs);
                    }
                }
                handleSteps("next");
            } else {
                _alert(
                    [`${res?.statusText || ""}: ${data?.message || ""}`],
                    "error"
                );
            }

            process(false);
        } else {
            _alert([t("alert.noSkillSelected")], "error");
        }
        scrollToTop();
    };

    const getJobStat = async (romecodes) => {
        if (romecodes?.length) {
            const peJob = await postMl(`/pe/pejob2/`, romecodes);
            const { status, data } = peJob || {};

            if (status === 200) {
                return data;
            }
        }
        return;
    };

    const insertJobPosition = async (user_id, job_position) => {
        const res = await create({ user_id, job_position }, "addJobPosition");
        return res;
    };

    const selectJob = async () => {
        const { selectedRowKeys } = state || {};
        if (selectedRowKeys?.length) {
            const selectedJob = selectedRowKeys?.[0];
            updateState({ selectedJob });
            handleSteps("next");
            setSession("selectedJob", selectedJob);
            setSession("selectedRowKeys", selectedRowKeys);

            if (url && userId) {
                const inserJobPositions = selectedRowKeys.map((obj) =>
                    insertJobPosition(userId, obj?.appellation || "")
                );

                Promise.all(inserJobPositions)
                    .then((results) => {
                        console.log(results);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        } else {
            _alert([t("alert.noJobSelected")], "error");
        }
        scrollToTop();
    };

    const reset = () => {
        setState((prevState) => ({
            ...prevState,
            fileUpload: false,
            checkedList: [],
            files: [],
            jobs: [],
            step: 0,
            selectedJob: null,
            criteria: {},
            validateStep: 0,
            validateSkillAdd: false,
            hasChosen: false,
            chosenJobs: [],
            chosenSkills: [],
            file: null,
            suggestedJobs: [],
            suggestedSkills: [],
            defListCount: [],
            selectedSkills: [],
            selectedRowKeys: [],
        }));
        sessionStorage.clear();
        scrollToTop();
    };

    const handleCriteria = (name, value) => {
        const criteria = {
            ...state.criteria,
            [name]: value,
        };
        updateState({ criteria });
        setSession("criteria", criteria);
    };

    const addJobSkill = async (type, name, value) => {
        // console.log(type, name, value);
        if (value) {
            let chosenItems;
            if (value?.id) {
                updateState({ jobAddLoader: true });

                chosenItems = state?.[name]?.length
                    ? [...state?.[name], { ...value, added: true }]
                    : [{ ...value, added: true }];
                const x = value;
                x.isSure = true;
                x.isAdded = true;
                // console.log(x);
                const newSuggestedJobs = state?.chosenJobs?.length
                    ? [x, ...state.chosenJobs]
                    : [x];
                updateState({
                    chosenJobs: newSuggestedJobs,
                    jobAddLoader: false,
                });
            } else {
                chosenItems = state?.[name]?.length
                    ? [...state?.[name], { name: value, subCategoryFr: value }]
                    : [{ name: value, subCategoryFr: value }];
            }
            if (!value?.id) {
                setSession(name, chosenItems);
                updateState({ [name]: chosenItems });
            }
        } else {
            _alert([t(`alert.no${type}Added`)], "error");
        }
    };

    const removeJobSkill = (name, item) => {
        const chosen = [...state?.[name]];
        const x = chosen?.filter((c) => c.name !== item?.name);
        setSession(name, x);
        updateState({ [name]: x });
    };

    const backToSuggestedSkills = () => {
        const updatedState = {
            step: 0,
            validateStep: 1,
            onValidate: false,
            validateSkillAdd: true,
        };
        updateState(updatedState);
        updateSession(updatedState);
        scrollToTop();
    };

    const backToSuggestedJobs = () => {
        if (state?.suggestedJobs?.length) {
            const updatedState = { hasChosen: false, onValidate: true };
            updateState(updatedState);
            updateSession(updatedState);
        } else {
            reset();
        }
        scrollToTop();
    };

    const getUserSession = async () => {
        updateState({ savingChanges: true, loaderContent: "fetchingData" });

        const response = await getExtSessionServer(`${userId}`, appRef);
        const { status, data } = response || {};
        const validParams =
            getSession("userId") !== userId || getSession("appRef") !== appRef;
        if (status === 200 && (!getSession("isLoaded") || validParams)) {
            if (data?.id) {
                setSession("isLoaded", true);

                const { criteria, jobs, selectedjob } = data || {};
                const { suggestedskills, suggestedjobs } = data || {};
                const { chosenjobs, chosenskills, maxstep } = data || {};

                const updatedState = {
                    step: maxstep,
                    fileUpload: true,
                    validateStep: 1,
                    validateSkillAdd: true,
                    criteria,
                    jobs,
                    maxStep: maxstep,
                    hasChosen: true,
                    onValidate: false,
                    selectedJob: selectedjob,
                    suggestedJobs: suggestedjobs,
                    suggestedSkills: suggestedskills,
                    chosenJobs: chosenjobs,
                    chosenSkills: chosenskills,
                };
                updateSession({ ...updatedState, userId, appRef });
                updateState({
                    ...updatedState,
                    selectedSkills: handleSelectionSkill(suggestedskills),
                    selectedJobs: handleSelectionSkill(suggestedjobs),
                });
            } else {
                const response = await getSessionServer();

                const _fileUploaded =
                    getSession("file")?.fileUpload &&
                    getSession("file")?.fileData;

                const { status } = response || {};
                if (status !== 200 && !_fileUploaded) {
                    reset();
                }
            }
        }
        updateState({ savingChanges: false, loaderContent: "" });
    };

    const saveToExtSession = async () => {
        updateState({ savingChanges: true, confirmOpen: false });
        const extSession = {
            userId,
            appRef,
            lang,
            jobs: getSession("jobs"),
            criteria: getSession("criteria"),
            selectedJob: getSession("selectedJob"),
            chosenSkills: getSession("chosenSkills"),
            chosenJobs: getSession("chosenJobs"),
            suggestedJobs: getSession("suggestedJobs"),
            suggestedSkills: getSession("suggestedSkills"),
            maxStep: getSession("maxStep"),
        };
        await setSessionServer(type.EXTSESSION, extSession);
        await delay(1000);
        updateState({ savingChanges: false });
        _alert([t("alert.changesSaved")], "success");
    };

    const toggleConfirmModal = () => {
        updateState({ confirmOpen: !state.confirmOpen });
    };

    const toggleNoJobModal = () => {
        updateState({ noJobFound: !state.noJobFound });
    };

    const onJobSelectChange = (_, selectedRowKeys) => {
        updateState({ selectedRowKeys });
    };

    const saveBtn = (
        <Space>
            <Button
                type="default"
                onClick={toggleConfirmModal}
                disabled={!userId || !appRef}
                className={classes.saveBtn}
            >
                <BiSave className={classes.saveIcon} />
                {t("saveData")}
            </Button>
        </Space>
    );

    const { step, files, processing } = state || {};

    return (
        <>
            <div className="h-100 mt-4">
                <JobSteppers
                    steps={steps}
                    step={step}
                    onStepChange={onStepChange}
                />
                <div className={classes.mt_3em}>
                    {step === 0 ? (
                        <Skills
                            state={state}
                            reset={reset}
                            backToJobs={backToJobs}
                            onFileRemove={removeFile}
                            handleDocuments={handleDocuments}
                            setSelected={handleSelected}
                            handleChoose={handleChoose}
                            addJobSkill={addJobSkill}
                            removeJobSkill={removeJobSkill}
                            validateSkills={validateSkills}
                            submit={uploadCV}
                            url={url}
                        />
                    ) : step === 2 ? (
                        <Criteria
                            handleCriteria={handleCriteria}
                            criteria={state.criteria}
                            saveBtn={saveBtn}
                            selectedRowKeys={state?.selectedRowKeys}
                        />
                    ) : step === 1 ? (
                        <JobPositions
                            data={getSession("jobs")}
                            saveBtn={saveBtn}
                            selectedRowKeys={state?.selectedRowKeys}
                            onJobSelectChange={onJobSelectChange}
                            url={url}
                        />
                    ) : step === 3 ? (
                        <JobBoards
                            selectedJob={state.selectedJob}
                            criteria={state.criteria}
                            saveBtn={saveBtn}
                            selectedRowKeys={state?.selectedRowKeys}
                        />
                    ) : (
                        ""
                    )}
                </div>

                <StepperActions
                    state={state}
                    steps={steps}
                    reset={reset}
                    nextUrl={nextUrl}
                    validateJobs={validateJobs}
                    searchJob={searchJob}
                    selectJob={selectJob}
                    uploadCV={uploadCV}
                    handleSteps={handleSteps}
                    getJobBoards={getJobBoards}
                    backToSkills={backToSkills}
                    backToSuggestedJobs={backToSuggestedJobs}
                    backToSuggestedSkills={backToSuggestedSkills}
                />
            </div>

            <DialogLoader
                open={step === 0 && state.validateStep === 1 && processing}
            />
            <ProgressLoader
                open={processing && state.validateStep !== 1}
                progress={progress}
                text={state?.progressText}
            />
            <ConfirmSave
                open={state.confirmOpen}
                submit={saveToExtSession}
                toggleModal={toggleConfirmModal}
            />
            <Backdrop
                className={classes.backdrop}
                open={state.savingChanges || false}
            >
                <SemipolarLoading size="small" color={"#F8F8F9"} />
                {userId && (
                    <Typography className={classes.backdropText}>
                        {t(state?.loaderContent || "backdropContent")}
                    </Typography>
                )}
            </Backdrop>
            <NoJobFound
                open={state?.noJobFound}
                toggleModal={toggleNoJobModal}
            />
        </>
    );
};

export default SearchJob;
