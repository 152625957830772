import React from "react";
import { useTranslation } from "react-i18next";
import { Steps } from "antd";
import { getSession } from "utils/utils";

const { Step } = Steps;

const JobSteppers = (props) => {
    const { t } = useTranslation();
    const { steps, step, onStepChange } = props;

    return (
        <Steps
            current={step}
            className="mt-4"
            size="small"
            onChange={onStepChange}
        >
            {steps.map((item, idx) => (
                <Step
                    disabled={idx > (getSession("maxStep") || 0)}
                    key={item}
                    title={
                        <span style={{ fontSize: "14px" }}>
                            {t(`steps.${item}`)}
                        </span>
                    }
                />
            ))}
        </Steps>
    );
};

export default JobSteppers;
