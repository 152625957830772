import React from "react";
import useStyles from "./makeStyles";
import clsx from "clsx";
import JobFilters from "components/Front-office/JobBoards/JobFilters";
import { useTranslation } from "react-i18next";
import { Typography, Grid } from "@material-ui/core";
import { Space, Input, Pagination } from "antd";

const SavedJobsFilters = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { filteredJobs, jobOffers, filterList, filterItems } = props;
    const { currentPage, setCurrentPage, handleInput, dupJobs } = props;

    return (
        <Grid
            container
            justifyContent="space-between"
            style={{ marginTop: "3em" }}
        >
            <Grid item xs={12} sm={6}>
                <Space className={classes.searchSmUp}>
                    <Typography
                        className={clsx(classes.searchText, classes.font14)}
                    >
                        {t("jobSites.searchJobLabel")}:
                    </Typography>
                    <Input
                        placeholder={t("jobSites.searchJobPlaceholder")}
                        autoFocus
                        className={classes.search}
                        onChange={({ target: { value } }) =>
                            handleInput("search", value)
                        }
                    />
                    {filterItems?.search && (
                        <Typography variant="subtitle2">
                            {filteredJobs.length} {t("result")}
                            {filteredJobs.length > 1 ? "s" : ""} of{" "}
                            {dupJobs?.length || 0}
                        </Typography>
                    )}
                </Space>{" "}
                <Input
                    placeholder={t("jobSites.searchJobPlaceholder")}
                    autoFocus
                    className={classes.searchxs}
                    onChange={({ target: { value } }) =>
                        handleInput("search", value)
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.filters}>
                <Space>
                    {filteredJobs?.length ? (
                        <Pagination
                            defaultCurrent={1}
                            total={filteredJobs?.length || 0}
                            showSizeChanger={false}
                            simple
                            current={currentPage}
                            onChange={(page) => setCurrentPage(page)}
                        />
                    ) : (
                        ""
                    )}
                    <JobFilters
                        handleInput={handleInput}
                        filterList={filterList}
                        filterItems={filterItems}
                        items={jobOffers}
                    />
                </Space>
            </Grid>
        </Grid>
    );
};

export default SavedJobsFilters;
