import React from "react";
import nodataImg from "../../images/not_found.svg";
import { Container, Typography, makeStyles } from "@material-ui/core";
import { Space } from "antd";

const NotFoundPage = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="lg" className={classes.paper}>
            <img src={nodataImg} height="230px" width="auto" alt="no data" />
            <Space direction="vertical" align="center" className="mt-4">
                <Typography className={classes.notFound}>
                    PAGE NOT FOUND
                </Typography>
                <Typography className={classes.font14}>
                    The page you are looking for cannot be found.
                </Typography>
            </Space>
        </Container>
    );
};

const useStyles = makeStyles(() => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        height: "75vh",
    },
    btn: {
        marginTop: "1em",
        borderRadius: 0,
        "&:hover": {
            border: "1px solid #00536D",
            color: "#00536D",
        },
    },
    font14: { fontSize: 14 },
    notFound: { fontWeight: 500, marginTop: "1em" },
}));

export default NotFoundPage;
