import { POLE_EMPLOI_API } from "./baseUrl";
import { format } from "date-fns";
import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";

export const isFr = (lang) => lang?.includes("fr");

export const getSession = (key, d = "") => {
    return sessionStorage?.getItem(key)
        ? JSON.parse(sessionStorage?.getItem(key) || "")
        : d;
};

export const setSession = (key, value = "") => {
    sessionStorage?.setItem(key, JSON.stringify(value));
};

export const fileUrl = (fileUrl) => {
    return POLE_EMPLOI_API + "/files/" + fileUrl;
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const titleCase = (data) => {
    if (typeof data === "string") {
        return data.charAt(0).toUpperCase() + data.slice(1);
    } else {
        return data;
    }
};

const toSentenceCase = (str) => {
    const words = str.split(" ");
    for (let i = 0; i < words.length; i++) {
        words[i] =
            words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }

    return words.join(" ");
};

export const formatDate = (date, type, locale) => {
    const month = type === "short" ? "MMM" : "MMMM";

    const formattedDate = format(new Date(date), `dd ${month} yyyy`, {
        locale: locale === "fr" ? fr : en,
    });
    return toSentenceCase(formattedDate);
};

export const snackbarAlert = (action, message, variant, persist) => {
    message.map((msg) => {
        return action(msg, {
            variant,
            preventDuplicate: true,
            autoHideDuration: 6000,
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            },
            transitionDuration: {
                enter: 200,
                exit: 400,
            },
            persist,
        });
    });
};

export const sortData = (x) => {
    const datae = x;
    const rankMap = new Map();
    datae.forEach((item) => {
        const firstTwoDigits = item.code.slice(0, 2);
        rankMap.set(firstTwoDigits, (rankMap.get(firstTwoDigits) || 0) + 1);
    });
    datae.forEach((item) => {
        const firstTwoDigits = item.code.slice(0, 2);
        item.rank = rankMap.get(firstTwoDigits) || 0;
    });
    datae.sort((a, b) => b.rank - a.rank);

    return datae;
};

export const searchFilter = (list, search) => {
    const filteredList = list?.length
        ? list.filter((item) => {
              const _search = search ? search.toLowerCase() : "";
              const { intitule, lieuTravail, typeContrat } = item || {};
              const { experienceLibelle } = item || {};
              const { libelle } = lieuTravail || {};

              return (
                  intitule.toLowerCase().includes(_search) ||
                  libelle.toLowerCase().includes(_search) ||
                  typeContrat.toLowerCase().includes(_search) ||
                  experienceLibelle.toLowerCase().includes(_search)
              );
          })
        : [];
    return filteredList;
};

export const filterByContract = (list, contracts) => {
    const filteredList =
        list?.length && contracts?.length
            ? list.filter((item) =>
                  contracts.some((y) => item.typeContrat === y)
              )
            : list;
    return filteredList;
};

export const filterByDate = (list, type) => {
    const getDate = (days) => {
        const now = new Date();
        return new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - days
        );
    };
    const now = new Date().getTime();
    const startMonth = getDate(30).getTime();
    const endMonth = now;
    const startWeek = getDate(7).getTime();
    const endWeek = now;

    const filteredList = list?.length
        ? type === "all"
            ? list
            : type === "today"
            ? list.filter((item) => {
                  return (
                      format(new Date(item.dateActualisation), "MM-dd-yyyy") ===
                      format(new Date(), "MM-dd-yyyy")
                  );
              })
            : type === "month"
            ? list.filter((item) => {
                  return (
                      new Date(item.dateActualisation).getTime() >=
                          startMonth &&
                      new Date(item.dateActualisation).getTime() <= endMonth
                  );
              })
            : type === "week"
            ? list.filter((item) => {
                  return (
                      new Date(item.dateActualisation).getTime() >= startWeek &&
                      new Date(item.dateActualisation).getTime() <= endWeek
                  );
              })
            : list
        : [];

    return filteredList;
};

export const scrollToTop = () => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth", // Smooth scrolling
    });
};
