import { postPoleEmploi, deletePoleEmploi } from "utils/api";
import { url } from "./url";

export const signup = async (username, password) => {
    const response = await postPoleEmploi(url.authentication.signup, {
        username,
        password,
    });
    return response;
};

export const signout = async (token) => {
    const response = await deletePoleEmploi(
        url.authentication.signout,
        {},
        token
    );
    return response;
};

export const checkToken = async (token) => {
    const response = await postPoleEmploi(
        url.authentication.checkToken,
        {},
        "",
        {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        }
    );
    return response;
};
