import React from "react";
import { DialogTitle, IconButton, withStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(2.3),
        top: theme.spacing(2),
        color: "grey",
    },
    root: {
        [theme.breakpoints.only("xs")]: {
            padding: "1em",
        },
    },
});

const MuiDialogTitle = withStyles(styles)((props) => {
    const { title, classes, onClose } = props;
    return (
        <DialogTitle className={classes.root} disableTypography>
            <Typography
                variant="subtitle1"
                style={{ fontSize: 16, fontWeight: 600 }}
            >
                {title}
            </Typography>

            <IconButton
                className={classes.closeButton}
                size="small"
                onClick={onClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
            <hr />
        </DialogTitle>
    );
});

export default MuiDialogTitle;
