import axios from "axios";

const FILE_API = process.env.REACT_APP_API_FILE;
export const POLE_EMPLOI_API = process.env.REACT_APP_API_POLE_EMPLOI;

const files = axios.create({ baseURL: FILE_API });
const skills = axios.create({ baseURL: POLE_EMPLOI_API });
const pole_emploi = axios.create({
    baseURL: POLE_EMPLOI_API,
    withCredentials: true,
    crossDomain: true,
});
const ml_api = axios.create({ baseURL: FILE_API });

export { files, skills, pole_emploi, ml_api };
