import { getPoleEmploi } from "utils/api";
import { url } from "./url";

export const getSoftSkill = async (code_rome, callback = () => {}) => {
    try {
        const result = await getPoleEmploi(url.job.soft_skills + code_rome);
        console.log("Quality Professionelles", result);
        if (result?.status === 200) {
            callback();
            return result?.data;
        }
    } catch (e) {
        console.log(e);
    }
};

export const getJobSkills = async (code_rome, callback = () => {}) => {
    try {
        const result = await getPoleEmploi(url.job.job_skills + code_rome);

        console.log("SKILLS STATISTICS", result);
        if (result?.status === 200) {
            callback();
            return result?.data;
        }
    } catch (e) {
        console.log(e);
    }
};
