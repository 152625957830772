import React from "react";
import useStyles from "./makeStyles";
import clsx from "clsx";
import JobItemDescription from "components/Front-office/JobBoards/JobItemDescription";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { TableContainer } from "@material-ui/core";
import { Button, Space, Tooltip, Table } from "antd";
import { MdAdd, MdRemove } from "react-icons/md";
import { formatDate } from "utils/utils";
import { RiExternalLinkLine, RiDeleteBin7Line } from "react-icons/ri";

const SavedJobsList = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const searchParams = new URLSearchParams(location.search);
    const lang =
        searchParams.get("lang") ||
        window?.localStorage?.i18nextLng ||
        i18n.language ||
        "fr";
    const { filteredResults, onUnSaveClick } = props;

    const title = (label) => (
        <span className={clsx(classes.th)}>
            {t(`jobSites.${label}`).toUpperCase()}
        </span>
    );

    const columns = [
        {
            title: title("intitule"),
            width: "27%",
            dataIndex: "intitule",
            render: (item) => {
                return <span className="font-weight-bold">{item || ""}</span>;
            },
        },
        {
            title: title("dateActualisation"),
            dataIndex: "dateActualisation",
            render: (item) => {
                return (
                    <span>
                        {formatDate(new Date(item), "short", lang || "fr")}
                    </span>
                );
            },
            responsive: ["lg"],
        },
        {
            title: title("libelle"),
            dataIndex: "libelle",
            width: "14%",
            render: (_, item) => {
                return <span>{item.lieuTravail.libelle}</span>;
            },
        },
        {
            title: title("contractType"),
            dataIndex: "typeContrat",
            width: "12%",
        },
        {
            title: title("experienceLibelle"),
            dataIndex: "experienceLibelle",
        },
        {
            title: title("actions"),
            dataIndex: "origineOffre",
            width: "6%",
            render: (_, item) => {
                const { origineOffre } = item || {};
                const { partenaires, origine, urlOrigine } = origineOffre || {};
                const x = partenaires?.length ? partenaires[0] : null;
                const link = origine === "1" ? urlOrigine : x?.url || "";
                return (
                    <Space size={5} className="mt-2">
                        <Tooltip
                            title={`${t("jobSites.openJob")}: ${
                                link ? link.slice(0, 50) : ""
                            }...`}
                        >
                            <a
                                type="link"
                                href={link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button
                                    size="small"
                                    className={classes.borderRound}
                                >
                                    <RiExternalLinkLine
                                        style={{ marginTop: "-3px" }}
                                    />
                                </Button>
                            </a>
                        </Tooltip>{" "}
                        <Tooltip title={t("jobSites.removeJob")}>
                            <Button
                                size="small"
                                className={classes.borderRound}
                                onClick={() => onUnSaveClick(item)}
                            >
                                <RiDeleteBin7Line
                                    style={{
                                        marginTop: "-2px",
                                        fontSize: 13,
                                    }}
                                />
                            </Button>
                        </Tooltip>
                    </Space>
                );
            },
            responsive: ["md"],
        },
    ];

    return (
        <TableContainer className="mt-2">
            <Table
                rowKey="id"
                columns={columns}
                dataSource={filteredResults}
                pagination={false}
                expandable={{
                    fixed: "right",
                    expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                            <Tooltip title={t("cv.seeLess")}>
                                <MdRemove
                                    style={{
                                        marginLeft: "-4em",
                                    }}
                                    onClick={(e) => onExpand(record, e)}
                                    className={classes.pointer}
                                />
                            </Tooltip>
                        ) : (
                            <Tooltip title={t("cv.seeMore")}>
                                <MdAdd
                                    style={{
                                        marginLeft: "-4em",
                                    }}
                                    className={clsx(
                                        classes.pointer,
                                        classes.font12
                                    )}
                                    onClick={(e) => onExpand(record, e)}
                                />
                            </Tooltip>
                        ),
                    expandIconColumnIndex: 7,
                    expandedRowRender: (rec) => {
                        return <JobItemDescription job={rec} />;
                    },
                }}
            />
        </TableContainer>
    );
};

export default SavedJobsList;
