import React from "react";
import useStyles from "../makeStyles";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";

const SkillsNav = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <Grid container justifyContent="space-between" className="mb-2">
                <Grid item>
                    <Typography className={classes.header}>
                        <span className="font-weight-bold">
                            {props?.step === 0
                                ? t("cv.skillTitle")
                                : t("cv.skillValidationTitle")}
                        </span>
                    </Typography>
                    <Typography
                        className={classes.header}
                        variant="caption"
                        style={{
                            fontSize: "14px",
                            marginTop: "0",
                            fontWeight: "normal",
                        }}
                    >
                        {props?.step === 0
                            ? t("cv.skillSubtitle")
                            : t("cv.skillValidationSubTitle")}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default SkillsNav;
