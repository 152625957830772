import React, { useState, useMemo, useRef, useEffect } from "react";
import { Select, Spin, Typography } from "antd";
import { useLocation } from "react-router-dom";
import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";

const DebounceSelect = ({
    fetchOptions,
    setInputValue,
    debounceTimeout = 800,
    notFoundContent,
    type,
    search,
    fetching,
    setFetching,
    jobs,
    ...props
}) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [options, setOptions] = useState([]);
    const [defOptions, setDefOptions] = useState([]);
    const fetchRef = useRef(0);
    const [fetchingJobs, setFetchingJobs] = useState(false);
    const searchParams = new URLSearchParams(location.search);

    let lang =
        searchParams.get("lang") ||
        window?.localStorage?.i18nextLng ||
        i18n.language ||
        "fr";

    lang = lang?.includes("fr") ? "fr" : "en";
    useEffect(() => {
        fetchOptions("", lang).then((newOptions) => {
            setOptions(newOptions);
            setDefOptions(newOptions);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     console.log("openOptions", openOptions, search);
    //     if (openOptions && search) {
    //         setFetching(true);
    //         fetchOptions(search).then((newOptions) => {
    //             setOptions(newOptions);
    //             setFetching(false);
    //         });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [openOptions]);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;

            if (value) {
                const fetchId = fetchRef.current;
                setOptions([]);
                if (setFetching) setFetching(true);
                if (setInputValue) setInputValue(value);
                setFetchingJobs(true);
                fetchOptions(value, lang).then((newOptions) => {
                    if (fetchId !== fetchRef.current) {
                        // for fetch callback order
                        return;
                    }
                    setOptions(newOptions);
                    if (setFetching) setFetching(false);
                    setFetchingJobs(false);
                });
            } else {
                setOptions(defOptions);
                if (setInputValue) setInputValue(value);
            }
        };
        return debounce(loadOptions, debounceTimeout);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchOptions, debounceTimeout]);

    const filteredJobOptions = options?.length
        ? jobs?.length
            ? options.filter(
                  (itemX) =>
                      !jobs.some(
                          (itemY) =>
                              itemY?.name?.replace(/\s/g, "")?.toLowerCase() ===
                              itemX?.name?.replace(/\s/g, "")?.toLowerCase()
                      )
              )
            : options
        : !search && defOptions
        ? defOptions
        : [];

    return (
        <Select
            // searchValue={search || ""}
            labelInValue
            placement="bottomLeft"
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={
                fetching || fetchingJobs ? (
                    <Spin size="small" />
                ) : type === "newJob" && search ? (
                    <>
                        <Typography className="mt-2 p-0 font-weight-bold">
                            {t("cv.jobNotFound")}
                        </Typography>
                        <Typography className="mt-2 p-0 ">
                            {t("cv.requestAddJob")}
                        </Typography>
                    </>
                ) : null
            }
            {...props}
            options={type === "newJob" ? filteredJobOptions : options}
            // options={options}
        />
    );
};
export default DebounceSelect;
