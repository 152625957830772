import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        height: 22,
        width: 22,
    },
    font12: {
        fontSize: 12,
    },
    font14: {
        fontSize: "14px !important",
    },
    input: {
        fontSize: "13px",
    },
    dragger: {
        backgroundColor: "#f5f5fa !important",
        border: "1px dotted #3CB2DE !important",
        marginTop: "1em",
        padding: "3em 0",
        boxShadow: "-2px 10px 5px -10px rgba(0,0,0,0.75)",
    },
    file: {
        height: "100px",
        width: "auto",
    },
    title: {
        display: "flex",
        justifyContent: "center",
        fontWeight: 600,
        color: "#00536D",
    },
    position: {
        display: "flex",
        flexDirection: "column",
    },
    header: {
        marginBottom: "1em",
        fontWeight: 500,
    },
    desc: {
        marginTop: "3em",
        fontSize: "14px",
    },
    job: {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #e0e0e0",
        borderRadius: 3,
        boxShadow: "-3px 5px 5px -10px rgba(0,0,0,0.75)",
        // border: "1px solid #e0e0e0",
        background: "#fafafa",
        "&:hover": {
            // border: "1px solid #00536D",
            background: "#f5f5fa",
        },
        width: "100%",
    },
    primary: {
        fontSize: "16px",
        // fontWeight: 600,
        color: "#00536D",
    },
    secondary: { fontSize: "13px" },
    checkboxContainer: {
        marginTop: "-5px",
        // marginLeft: "0.5em",
        display: "flex",
        padding: 0,
        justifyContent: "space-between",
    },
    badge: {
        backgroundColor: "#198754",
        fontSize: "10px",
        marginTop: "-3px",
    },
    checkAll: {
        fontSize: "14px",
        marginBottom: "1em",
    },
    th: {
        fontWeight: 600,
        fontSize: "13px",
        color: "#fff !important",
    },
    response: {
        marginTop: "2em",
    },
    skills: {
        display: "flex",
        flexDirection: "column",
        marginTop: "3em",
    },
    draggerHeader: {
        fontSize: "18px",
        marginTop: "5em",
    },
    avatarContainer: {
        padding: "0",
        background: "#fafafa",
        cursor: "pointer",
    },
    skillBtn: {
        height: "30px",
        background: "#f5f5fa",
        fontSize: "13px",
        borderRadius: 3,
    },
    fileContainer: {
        marginTop: "3em",
    },
    actions: {
        padding: 0,
        marginTop: "3em",
        // display: "flex",
        // justifyContent: "flex-start",
    },
    cancel: {
        padding: "0 1em",
        // border: "1px solid #00536D",
        border: "1px solid #bdbdbd",
        borderRadius: 3,
        fontSize: "13px",
        color: "#00536D",
        "&:hover": { transform: "scale(1.05)" },
    },
    active: {
        background: "#00536D",
        color: "#fff",
        "&:hover": {
            transform: "scale(1.05)",
            background: "#00536D",
            color: "#fff",
        },
    },
    submit: {
        padding: "0 4em",
        "&:disabled": {
            background: "#00536D",
            color: "#fff",
            transform: "scale(1)",
        },
    },
    btn: {
        fontSize: "14px",
        borderRadius: 3,
        "&:active": { border: "none" },
        // [theme.breakpoints.only("xs")]: { width: "30%", padding: "0" },
    },
    disabled: {},
    searchText: {
        fontSize: "14px",
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    search: {
        width: 300,
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    searchSmUp: {
        marginBottom: "5px",
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    searchxs: {
        [theme.breakpoints.up("sm")]: {
            display: "none",
            marginBottom: "0.5em",
        },
        marginBottom: "1em",
    },
    filters: {
        display: "flex",
        justifyContent: "flex-end",
    },
    white: { background: "#fff" },
    filterSummary: {
        marginBottom: "3em",
    },
    noData: { marginTop: "6em" },
    dialogContent: {
        marginTop: "-1em",
        [theme.breakpoints.only("xs")]: {
            padding: "1em",
        },
    },
    dialogContentText: {
        textAlign: "justify",
        color: "#616161",
        fontSize: "14px",
        marginBottom: "1.5em",
    },
    actionBtn: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "3em",
        marginBottom: "2em",
    },
    thumb: {
        background: "#00ABD3",
        height: "12px",
        width: "12px",
        "&~&": {
            background: "#00ABD3",
        },
    },
    mark: {
        background: "black",
    },
    rail: {
        marginTop: "-4px",
        background: "linear-gradient(to right, red, orange, #1EC101, #073800);",
        padding: "3px",
    },
    track: {
        background: "orange",
    },
    valueLabel: {
        "&>*": {
            background: "black",
        },
    },
    descs: {
        background: "#fafafa",
        padding: "1em",
    },
    descBtn: {
        background: "#00536D",
        paddingLeft: "2em",
        paddingRight: "2em",
    },
    table: {
        padding: 0,
    },
    backdrop: {
        // zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        zIndex: 10000,
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    validateCancelBtn: {
        borderRadius: 5,
        paddingLeft: "1em",
        paddingRight: "1em",
    },
    validateBtn: {
        borderRadius: 5,
        paddingLeft: "3em",
        paddingRight: "3em",
    },
    saveBtn: {
        borderRadius: "4px",
        paddingLeft: "2em",
        paddingRight: "2em",
    },
    saveIcon: {
        margin: "-3px 5px 0 0",
    },
    mt_2em: {
        marginTop: "2em",
    },
    mt_3em: {
        marginTop: "3em",
    },
    mt_5em: {
        marginTop: "5em",
    },
    backdropText: {
        fontSize: "14px",
        marginTop: "6em",
    },
    seeMoreBtn: {
        color: "#1890FF",
    },
    rangePicker: {
        background: "#fafafa",
        marginTop: "1em",
        width: "100%",
    },
    saveCriteria: {
        marginTop: "-0.3em",
    },
    pointer: {
        cursor: "pointer",
    },
    borderRound: {
        borderRadius: "5px",
    },
    link: {
        color: "#1890FF",
        cursor: "pointer",
    },
    keywordTag: {
        cursor: "pointer",
        fontSize: 13,
        borderRadius: 25,
        paddingLeft: "0.8em",
        paddingRight: "0.8em",
        color: "#212121",
        marginTop: "5px",
    },
    jobSelect: {
        width: "500px",
    },
    newRefBtn: {
        padding: "5px",
        marginTop: "-1em",
    },
    addJobBtn: {
        background: "#f5f5fa",
        padding: "0 2em",
        borderRadius: "4px",
    },
    removeSkillIcon: {
        color: "#ED4034",
        fontSize: "16px",
        marginLeft: "8px",
        marginTop: "-2px",
    },
    removeSkillText: {
        color: "#ED4034",
        marginLeft: "-5px",
    },
    addSkillInput: {
        width: 500,
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    addSkillBtn: {
        background: "#f5f5fa",
        borderRadius: 5,
    },
    uploadCVBtn: {
        background: "#00536D",
        color: "#fff",
        padding: "0 4em",
        borderRadius: 4,
    },
}));

export default useStyles;
