import React from "react";
import clsx from "clsx";
import useStyles from "../makeStyles";
import { useTranslation } from "react-i18next";
import { Button, Table } from "antd";
import { Radio, TableContainer } from "@material-ui/core";
import { titleCase } from "utils/utils";

const SkillLearning = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { data, type, onViewDesc, selectedSkills, setSelectedSkills } = props;
    const { isValidated, removeJobSkill } = props;
    const pageSkill = 1;
    const pageJob = 1;

    const title = (label) => (
        <span className={clsx(classes.th)}>{t(`${label}`).toUpperCase()}</span>
    );

    const columns = [
        {
            title: title(
                type === "skill" ? "cv.tableSkillItem" : "cv.tableJobItem"
            ),
            render: (_, item, index) => (
                <span key={`${type}${index}`}>{titleCase(item?.name)}</span>
            ),
            key: `${type}Title`,
        },
        {
            title: title("cv.description"),
            width: type === "skill" ? "15%" : "20%",
            render: (_, item, index) => (
                <span
                    key={`${type}${index}`}
                    className={classes.link}
                    onClick={() => onViewDesc(item)}
                >
                    {t("cv.viewDescription")}
                </span>
            ),
            key: `${type}Description`,
        },
        {
            title: title(
                `cv.possession${type === "skill" ? "Skills" : "Jobs"}`
            ),
            width: type === "skill" ? "15%" : "20%",
            key: `${type}Possession`,
            render: (_, item, i) => {
                let page = type === "skill" ? pageSkill : pageJob;
                let index = (page - 1) * 10 + i;
                const handleChange = (event) => {
                    setSelectedSkills(
                        `selected${type === "skill" ? "Skills" : "Jobs"}`,
                        event.target.value,
                        index
                    );
                };
                if (isValidated) {
                    return (
                        <div>
                            <Button
                                size="small"
                                style={{ fontSize: 12, borderRadius: 4 }}
                                onClick={() => removeJobSkill(item)}
                            >
                                {t("cv.removeJob")}
                            </Button>
                        </div>
                    );
                }
                return (
                    <div key={`${type}${index}`}>
                        <Radio
                            size="small"
                            value="1"
                            checked={selectedSkills?.[index] === "1"}
                            onChange={handleChange}
                            name="radio-buttons"
                            inputProps={{ "aria-label": "Yes" }}
                            className="py-0"
                            color="primary"
                        />
                        <span className={clsx(classes.font12, "grey")}>
                            {t("cv.yes")}
                        </span>
                        <Radio
                            size="small"
                            value="-1"
                            checked={selectedSkills?.[index] === "-1"}
                            onChange={handleChange}
                            name="radio-buttons"
                            inputProps={{ "aria-label": "No" }}
                            className="py-0"
                        />
                        <span className={clsx(classes.font12, "grey")}>
                            {t("cv.no")}
                        </span>
                    </div>
                );
            },
        },
    ];

    return (
        <TableContainer className="mt-2">
            <Table
                locale={{ emptyText: isValidated ? " " : null }}
                rowKey={"name"}
                key={type}
                size="small"
                columns={columns}
                dataSource={data}
                pagination={false}
            />
        </TableContainer>
    );
};

export default SkillLearning;
