const contractsType = [
    { value: "CDI", label: "CDI - Contrat à durée indéterminée" },
    { value: "CDD", label: "CDD - Contrat à durée déterminée" },
    { value: "CDS", label: "CDS - CDD sénior" },
    { value: "CCE", label: "CCE - Profession commerciale" },
    { value: "MIS", label: "MIS - Mission intérimaire" },
    { value: "SAI", label: "SAI - Contrat travail saisonnier" },
    { value: "FRA", label: "FRA - Franchise" },
    { value: "LIB", label: "LIB - Profession libérale" },
    { value: "REP", label: "REP - Reprise d'entreprise" },
    {
        value: "TTI",
        label: "TTI - Contrat travail temporaire insertion",
    },
    { value: "DDI", label: "DDI - Contrat durée déterminée insertion" },
    { value: "DIN", label: "DIN - CDI Intérimaire" },
];

export default contractsType;
