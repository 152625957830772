import React from "react";
import useStyles from "../makeStyles";
import { titleCase } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { Typography, TableContainer } from "@material-ui/core";

const FilterSummary = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { filters } = props;
    const jobStats = filters?.length
        ? filters.filter((item) => item?.filtre !== "natureContrat")
        : [];

    const contracts = [
        { key: "CDI", label: "Contrat à durée indéterminée" },
        { key: "CDD", label: "Contrat à durée indéterminée" },
        { key: "CDS", label: "CDD sénior" },
        { key: "MIS", label: "Mission intérimaire" },
        { key: "SAI", label: "Contrat travail saisonnier" },
        { key: "CCE", label: "Profession commerciale" },
        { key: "FRA", label: "Franchise" },
        { key: "LIB", label: "Profession libérale" },
        { key: "REP", label: "Reprise d'entreprise" },
        { key: "TTI", label: "Contrat travail temporaire insertion" },
        { key: "DDI", label: "Contrat durée déterminée insertion" },
        { key: "DIN", label: "CDI Intérimaire" },
    ];

    const qualifications = [
        { key: "0", label: "Sans qualification" },
        { key: "1", label: "Manœuvre" },
        { key: "2", label: "Ouvrier spécialisé" },
        { key: "3", label: "Ouvrier qualifié (P1, P2)" },
        { key: "4", label: "Ouvrier qualifié (P3, P4, OHQ)" },
        { key: "5", label: "Employé non qualifié" },
        { key: "6", label: "Employé qualifié" },
        { key: "7", label: "Technicien" },
        { key: "8", label: "Agent de maîtrise" },
        { key: "9", label: "Cadre" },
        { key: "X", label: "Non précisé" },
    ];

    const experiences = [
        { key: "0", label: "Non renseignée" },
        { key: "1", label: "Moins de 1 an" },
        { key: "2", label: "De 1 à 3 ans" },
        { key: "3", label: "Plus de 3 ans" },
    ];

    return (
        <div className={classes.white}>
            {jobStats?.length
                ? jobStats.map((filt) => {
                      const { agregation: agreg, filtre } = filt || {};

                      let columns = [];
                      let result = [];

                      if (agreg?.length) {
                          columns = agreg.map((item) => {
                              const typeContracts = filtre.includes(
                                  "typeContrat"
                              )
                                  ? contracts.find(
                                        (c) => c.key === item.valeurPossible
                                    )
                                  : null;
                              const typeContractsLabel = typeContracts?.key
                                  ? typeContracts.label
                                  : null;
                              const qualification = filtre.includes(
                                  "qualification"
                              )
                                  ? qualifications.find(
                                        (qualif) =>
                                            qualif.key === item.valeurPossible
                                    )
                                  : null;
                              const qualifLabel = qualification?.key
                                  ? qualification.label
                                  : null;

                              const experience = filtre.includes("experience")
                                  ? experiences.find(
                                        (exp) => exp.key === item.valeurPossible
                                    )
                                  : null;
                              const expLabel = experience?.key
                                  ? experience.label
                                  : null;
                              return {
                                  title:
                                      filtre.includes("qualification") &&
                                      qualifLabel
                                          ? qualifLabel
                                          : filtre.includes("experience") &&
                                            expLabel
                                          ? expLabel
                                          : typeContractsLabel,
                                  dataIndex: item.valeurPossible,
                              };
                          });

                          result = [
                              agreg.reduce(
                                  (acc, cur) => ({
                                      ...acc,
                                      [cur.valeurPossible]: cur.nbResultats,
                                  }),
                                  {}
                              ),
                          ];
                      }
                      return (
                          <div className={classes.filterSummary}>
                              <Typography className="font-weight-bold px-2">
                                  {titleCase(t(`${filtre}`))}
                              </Typography>
                              <TableContainer className="mt-2 px-2">
                                  <Table
                                      dataSource={result}
                                      columns={columns}
                                      pagination={false}
                                      size="small"
                                  />
                              </TableContainer>
                          </div>
                      );
                  })
                : ""}
        </div>
    );
};

export default FilterSummary;
