import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    font14: {
        fontSize: 14,
    },
    input: {
        fontSize: 13,
    },
    title: {
        display: "flex",
        justifyContent: "center",
        fontWeight: 600,
        color: "#00536D",
    },
    position: {
        display: "flex",
        flexDirection: "column",
    },
    header: {
        marginBottom: "1em",
        fontWeight: "bold",
    },
    desc: {
        marginTop: "3em",
        fontSize: 14,
    },
    primary: {
        fontSize: 16,
        color: "#00536D",
    },
    secondary: { fontSize: 13 },
    badge: {
        backgroundColor: "#198754",
        fontSize: 10,
        marginTop: "-3px",
    },
    checkAll: {
        fontSize: 14,
        marginBottom: "1em",
    },
    th: {
        fontWeight: 600,
        fontSize: 13,
        color: "#fff !important",
    },
    response: {
        marginTop: "2em",
    },
    draggerHeader: {
        fontSize: 18,
        marginTop: "5em",
    },
    avatarContainer: {
        padding: "2px",
        background: "#fafafa",
    },
    skillBtn: {
        height: "30px",
        background: "#f5f5fa",
        fontSize: 13,
        borderRadius: 3,
    },
    actions: {
        padding: 0,
        marginTop: "3em",
    },
    cancel: {
        padding: "0 1em",
        border: "1px solid #00536D",
        borderRadius: 3,
        fontSize: 13,
        color: "#00536D",
        "&:hover": { transform: "scale(1.05)" },
    },
    active: {
        background: "#00536D",
        color: "#fff",
        "&:hover": {
            transform: "scale(1.05)",
            background: "#00536D",
            color: "#fff",
        },
    },
    submit: {
        padding: "0 4em",
        "&:disabled": {
            background: "#00536D",
            color: "#fff",
            transform: "scale(1)",
        },
    },
    btn: {
        fontSize: 14,
        borderRadius: 3,
        "&:active": { border: "none" },
    },
    disabled: {},
    searchText: {
        fontSize: 14,
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    search: {
        width: 300,
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    searchSmUp: {
        marginBottom: "5px",
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    searchxs: {
        [theme.breakpoints.up("sm")]: {
            display: "none",
            marginBottom: "0.5em",
        },
        marginBottom: "1em",
    },
    filters: {
        display: "flex",
        justifyContent: "flex-end",
    },
    white: { background: "#fff" },
    filterSummary: {
        marginBottom: "3em",
    },
    noData: { marginTop: "6em" },
    dialogContent: {
        marginTop: "-1em",
        [theme.breakpoints.only("xs")]: {
            padding: "1em",
        },
    },
    dialogContentText: {
        textAlign: "justify",
        color: "#616161",
        fontSize: 14,
        marginBottom: "1.5em",
    },
    actionBtn: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "3em",
        marginBottom: "2em",
    },
    thumb: {
        background: "#00ABD3",
        height: "12px",
        width: "12px",
        "&~&": {
            background: "#00ABD3",
        },
    },
    mark: {
        background: "black",
    },
    rail: {
        marginTop: "-4px",
        background: "linear-gradient(to right, red, orange, #1EC101, #073800);",
        padding: "3px",
    },
    track: {
        background: "orange",
    },
    valueLabel: {
        "&>*": {
            background: "black",
        },
    },
    descs: {
        background: "#fafafa",
        padding: "1em",
    },
    descBtn: {
        background: "#00536D",
        paddingLeft: "2em",
        paddingRight: "2em",
    },
    login: {
        gridAutoFlow: "row",
        gridRowGap: "10px",
    },
}));

export default useStyles;
