import React, { useState, useEffect } from "react";
import contractsType from "utils/contractsType";
import useStyles from "../makeStyles";
import tagRender from "components/common-components/TagRender";
import clsx from "clsx";
import JobOfferStat from "../JobBoards/JobOfferStat";
import { useTranslation } from "react-i18next";
import { Form, Select, Space, Tooltip } from "antd";
import { Typography } from "@material-ui/core";
import { getAll } from "utils/api";
import { Loader } from "../../common-components";
import { FaInfoCircle } from "react-icons/fa";
import { getSession } from "../../../utils/utils";

const { Option } = Select;

const Criteria = (props) => {
    const classes = useStyles();
    const [departements, setDepartements] = useState([]);
    const [regions, setRegions] = useState([]);
    const [loader, setLoader] = useState(false);
    const { t } = useTranslation();
    const [openStat, setOpenStat] = useState(false);
    const { criteria, handleCriteria, selectedRowKeys } = props;
    const { addressType, dept, region, typeContracts } = criteria || {};
    const selectedJob = getSession("selectedJob");
    const { appellation } = selectedJob || {};

    useEffect(() => {
        getDepartments();
        getRegions();

        if (!getSession("criteria")) props.handleCriteria("date", "all");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDepartments = async () => {
        setLoader(true);
        const res = await getAll("pe/referential", {
            q: "departements",
        });
        const { status, data } = res || {};
        const _depts =
            status === 200 && data?.length
                ? data.sort((a, b) => {
                      return a.libelle.localeCompare(b.libelle);
                  })
                : [];

        setLoader(false);
        setDepartements(_depts);
    };

    const getRegions = async () => {
        setLoader(true);
        const res = await getAll("pe/referential", {
            q: "regions",
        });
        const { status, data } = res || {};
        const _regions =
            status === 200 && data?.length
                ? data.sort((a, b) => {
                      return a.libelle.localeCompare(b.libelle);
                  })
                : [];

        setLoader(false);
        setRegions(_regions);
    };

    const onViewStat = () => setOpenStat(!openStat);

    const dateFilters = [
        { key: "all", label: t("filterDate.all") },
        { key: "today", label: t("filterDate.today") },
        { key: "week", label: t("filterDate.thisWeek") },
        { key: "month", label: t("filterDate.thisMonth") },
    ];

    const referentials = addressType
        ? addressType === "region"
            ? regions
            : departements
        : [];

    const refName = addressType
        ? addressType === "region"
            ? "region"
            : "dept"
        : "";

    return (
        <div className={classes.mt_2em}>
            {loader ? (
                <Loader />
            ) : (
                <>
                    <Space size={0}>
                        {selectedRowKeys?.length === 1 && appellation ? (
                            <Typography className="font-weight-bold">
                                {appellation || ""}
                            </Typography>
                        ) : (
                            <Space size={0}>
                                <Typography className="font-weight-bold">
                                    {t("jobSites.jobPositionsRequested")}
                                </Typography>
                                <Tooltip
                                    title={t("jobSites.seeJobOffersDetails")}
                                    placement="right"
                                >
                                    <FaInfoCircle
                                        onClick={onViewStat}
                                        className={classes.infoCircleIcon}
                                    />
                                </Tooltip>
                            </Space>
                        )}
                    </Space>
                    <Typography
                        className={clsx(classes.secondary, classes.font14)}
                    >
                        {t("criteria.criteriaDesc")}{" "}
                    </Typography>

                    <div className={classes.mt_3em}>
                        <Form
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 14 }}
                            labelAlign="left"
                        >
                            <Form.Item
                                label={t("criteria.criteriaRegionDeptLabel")}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder={t(
                                        "criteria.criteriaRegionDeptInputPlaceholder"
                                    )}
                                    className="w-100"
                                    onChange={(e) =>
                                        handleCriteria("addressType", e)
                                    }
                                    value={addressType || null}
                                >
                                    <Option value="dept">
                                        {t("criteria.department")}
                                    </Option>
                                    <Option value="region">
                                        {t("criteria.region")}
                                    </Option>
                                </Select>{" "}
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) => {
                                        return (
                                            option?.children?.join("") ?? ""
                                        )
                                            .toLowerCase()
                                            .includes(input.toLowerCase());
                                    }}
                                    className="mt-4 w-100"
                                    placeholder={t(
                                        "criteria.regionDeptOptionsPlaceholder"
                                    )}
                                    onChange={(e) => handleCriteria(refName, e)}
                                    value={
                                        addressType === "dept"
                                            ? dept || null
                                            : addressType === "region"
                                            ? region || null
                                            : null
                                    }
                                >
                                    {referentials?.length
                                        ? referentials.map((dep, idx) => (
                                              <Option
                                                  key={idx}
                                                  value={dep.code}
                                              >
                                                  {dep.libelle || ""}
                                                  {addressType === "dept"
                                                      ? ` - (${
                                                            dep?.code || ""
                                                        })`
                                                      : ""}
                                              </Option>
                                          ))
                                        : ""}
                                </Select>
                            </Form.Item>
                        </Form>

                        <Form
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 14 }}
                            labelAlign="left"
                        >
                            <Form.Item label={t("criteria.contractTypeLabel")}>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    showArrow
                                    className="w-100"
                                    placeholder={t(
                                        "criteria.contractTypePlaceholder"
                                    )}
                                    onChange={(e) =>
                                        handleCriteria("typeContracts", e)
                                    }
                                    options={contractsType}
                                    tagRender={tagRender}
                                    value={typeContracts || []}
                                />
                            </Form.Item>
                        </Form>
                        <Form
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 14 }}
                            labelAlign="left"
                        >
                            <Form.Item label={t("criteria.pubDateLabel")}>
                                <Select
                                    allowClear
                                    showSearch
                                    className="w-100"
                                    placeholder={t(
                                        "criteria.pubDatePlaceholder"
                                    )}
                                    onChange={(e) => handleCriteria("date", e)}
                                    value={criteria?.date || null}
                                >
                                    {dateFilters.map((date, idx) => (
                                        <Option key={idx} value={date.key}>
                                            {date.label}{" "}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Form>
                    </div>
                </>
            )}
            <JobOfferStat
                open={openStat}
                toggleModal={onViewStat}
                jobStats={selectedRowKeys}
                isCriteria={true}
            />
        </div>
    );
};

export default Criteria;
