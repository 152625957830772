import React from "react";
import serverErrorImg from "../../images/server_error.svg";
import { Container, Typography, makeStyles } from "@material-ui/core";
import { Space } from "antd";

const ServerError = () => {
    const classes = useStyles();
    return (
        <Container maxWidth="lg" className={classes.paper}>
            <img
                src={serverErrorImg}
                height="230px"
                width="auto"
                alt="server error"
            />
            <Space direction="vertical" align="center" className="mt-4">
                <Typography style={{ fontWeight: 500 }}>
                    INTERNAL SERVER ERROR
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                    We're sorry, the server encountered an internal error and
                    was unable to complete your request.
                </Typography>
            </Space>
        </Container>
    );
};

const useStyles = makeStyles(() => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
    },
}));

export default ServerError;
