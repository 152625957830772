import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Grid } from "@material-ui/core";
import { Tabs, Popover, Avatar } from "antd";
import { Loader } from "../../common-components";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { FaInfoCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

const Competence = ({ skill }) => {
    const { t } = useTranslation();
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            className="d-flex justify-content-start"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Avatar
                shape="square"
                size={5}
                style={{
                    backgroundColor: "#1E87C0",
                    color: "#fff",
                    fontSize: "12px",
                    marginRight: "8px",
                    marginTop: "8px",
                }}
            >
                {/* {skill?.riasecMajeur} */}
                {skill?.competences.key}
            </Avatar>
            <p>{`${skill?.enjeu.libelle}`}</p>
            {isHovered && (
                // skill?.riasecMajeur && (
                <Popover
                    title="Details"
                    // title={
                    //     <b>
                    //         {t(
                    //             `position.riasec.${skill?.riasecMajeur?.toUpperCase()}.title`
                    //         )}{" "}
                    //     </b>
                    // }
                    content={
                        <div
                            style={{
                                width: "400px",
                            }}
                        >
                            <p className="text-justify">
                                <ul>
                                    {skill.competences.map((c) => (
                                        <li>{c.libelle}</li>
                                    ))}
                                </ul>
                            </p>
                            {/* <p className="text-justify">
                                {t(
                                    `position.riasec.${skill?.riasecMajeur}.desc`
                                )}{" "}
                            </p> */}
                        </div>
                    }
                    placement="right"
                >
                    <FaInfoCircle
                        color="#1890FF"
                        style={{ margin: "5px 0 0 1em", cursor: "pointer" }}
                    />
                </Popover>
            )}
        </div>
    );
};

const JobPanel = (props) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("1");
    const [series, setSeries] = useState([{ name: "Series 1", data: [] }]);
    const [options, setOptions] = useState({
        chart: { type: "radar" },
        dataLabels: { enabled: true },
        plotOptions: { radar: { size: 120 } },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                },
            },
        },
        title: {
            text: t("position.wantedSkills"),
            align: "center",
        },
        xaxis: { categories: [] },
    });

    const { jobSkills, softSkills, loader } = props;

    const handleTabs = (tab) => setActiveTab(tab);

    useEffect(() => {
        buildChart(softSkills);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [softSkills]);

    const buildChart = (softSkills) => {
        let data = [];
        let xaxis = [];
        for (const key in softSkills) {
            xaxis.push(softSkills[key]?.summary);
            data.push(Math.round(softSkills[key]?.score * 100));
        }
        let _series = { ...series?.[0], data: data?.slice(0, 10) };
        let _options = {
            ...options,
            xaxis: { categories: xaxis.slice(0, 10) },
        };
        setSeries([_series]);
        setOptions(_options);
    };
    //console.log("Rendering Jobskills");
    //console.log({ jobSkills });
    return (
        <Tabs
            activeKey={activeTab}
            className={`px-${activeTab === "2" ? "0" : "2"} pt-2`}
            style={{ background: "#fafafa" }}
            onChange={handleTabs}
        >
            <TabPane tab={t("position.skillsStatistics")} key="1" type="card">
                <div
                    style={{
                        background: "#fafafa",
                    }}
                    className="p-2"
                >
                    {loader || !jobSkills?.length ? (
                        <Loader />
                    ) : (
                        <div>
                            {jobSkills?.map((skill, idx) => (
                                <Competence key={idx} skill={skill} />
                                // <Competence key={idx} skill={skill.status} />
                            ))}
                        </div>
                    )}
                </div>
            </TabPane>
            <TabPane tab={t("position.qualityProfessionelles")} key="2">
                {loader ? (
                    <Loader />
                ) : (
                    <div
                        style={{
                            background: "#fff",
                            marginTop: "-1em",
                        }}
                        className="p-2"
                    >
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                                <div>
                                    {softSkills &&
                                        Object?.keys(softSkills)
                                            .slice(0, 10)
                                            ?.map((key, idx) => {
                                                let item = softSkills[key];
                                                return (
                                                    <div
                                                        className="d-flex justify-content-start"
                                                        key={idx}
                                                    >
                                                        <Avatar
                                                            shape="square"
                                                            size={26}
                                                            style={{
                                                                backgroundColor:
                                                                    "#1E87C0",
                                                                color: "#fff",
                                                                fontSize:
                                                                    "12px",
                                                                marginRight:
                                                                    "8px",
                                                            }}
                                                        >
                                                            {`${Math.round(
                                                                item?.score *
                                                                    100
                                                            )}%`}
                                                        </Avatar>
                                                        <p>{`${item?.summary}`}</p>{" "}
                                                        <Popover
                                                            content={
                                                                <div
                                                                    style={{
                                                                        width: "300px",
                                                                    }}
                                                                >
                                                                    <p className="text-justify">
                                                                        {
                                                                            item?.details
                                                                        }{" "}
                                                                    </p>
                                                                </div>
                                                            }
                                                            placement="right"
                                                        >
                                                            <HiQuestionMarkCircle
                                                                color="#bdbdbd"
                                                                fontSize={14}
                                                                className="ml-2 mt-1"
                                                            />
                                                        </Popover>
                                                    </div>
                                                );
                                            })}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Chart
                                    style={{
                                        background: "#fff",
                                        padding: "1em",
                                    }}
                                    options={options}
                                    series={series}
                                    type="radar"
                                    // width="750"
                                    height="350"
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}
            </TabPane>
            {/* <TabPane tab="Langues" key="3">
                <div
                    style={{
                        background: "#fafafa",
                    }}
                    className="p-2"
                >
                    <div>
                        {languageStats.map(
                            (item, idx) => {
                                return (
                                    <div
                                        className="d-flex justify-content-start"
                                        key={idx}
                                    >
                                        <Avatar
                                            shape="square"
                                            size={
                                                26
                                            }
                                            style={{
                                                backgroundColor:
                                                    "#1E87C0",
                                                color: "#fff",
                                                fontSize: 12,
                                                marginRight:
                                                    "8px",
                                            }}
                                        >
                                            {`${item.volume}`}
                                        </Avatar>
                                        <p>{`${item.langues}`}</p>
                                    </div>
                                );
                            }
                        )}
                    </div>
                </div>
            </TabPane>
            <TabPane tab="Driving License" key="4">
                <div
                    style={{
                        background: "#fafafa",
                    }}
                    className="p-2"
                >
                    <div>
                        {license.map(
                            (item, idx) => {
                                return (
                                    <div
                                        className="d-flex justify-content-start"
                                        key={idx}
                                    >
                                        <Avatar
                                            shape="square"
                                            size={
                                                26
                                            }
                                            style={{
                                                backgroundColor:
                                                    "#1E87C0",
                                                color: "#fff",
                                                fontSize: 12,
                                                marginRight:
                                                    "8px",
                                            }}
                                        >
                                            {`${item.volume}`}
                                        </Avatar>
                                        <p>{`${item.permis}`}</p>
                                    </div>
                                );
                            }
                        )}
                    </div>
                </div>
            </TabPane> */}
        </Tabs>
    );
};

export default JobPanel;
