import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

const Introduction = () => {
    const { t } = useTranslation();
    return (
        <div className="mt-4">
            <Typography variant="h6" className="font-weight-bold">
                {t("intro.title")}
            </Typography>
            <Typography style={{ fontSize: "14px" }} className="mt-2">
                {t("intro.description")}{" "}
            </Typography>

            <Typography style={{ fontSize: "14px" }} className="mt-4">
                {t("intro.feature.title")}
            </Typography>

            <ul style={{ marginLeft: "-1.5em" }} className="mb-4">
                {[1, 3, 4, 5].map((item) => (
                    <li key={item}>
                        <Typography
                            style={{ fontSize: "14px" }}
                            className="mt-2"
                        >
                            {t(`intro.feature.${item}`)}
                        </Typography>
                    </li>
                ))}
            </ul>

            <Typography style={{ fontSize: "14px" }} className="mt-4">
                {t("intro.subtitle")}{" "}
            </Typography>
            <Typography style={{ fontSize: "14px" }} className="mt-2">
                {t("intro.subtitle2")}{" "}
            </Typography>
        </div>
    );
};

export default Introduction;
