import React from "react";
import useStyles from "../makeStyles";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Button, Space, Tooltip } from "antd";
import { BiSave } from "react-icons/bi";
import { RiExternalLinkLine } from "react-icons/ri";
import { TiDelete } from "react-icons/ti";

const JobActions = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const { job, jobOffers, onSaveClick } = props;
    // const {  onBtnClick, onMouseEnter, onMouseLeave, selectedJob, tooltipTitle, isTooltipOpen  } = props;

    const { t } = useTranslation();

    const searchParams = new URLSearchParams(location.search);
    const user_id = searchParams.get("userId");

    const { origineOffre } = job || {};
    const { partenaires, origine, urlOrigine } = origineOffre || {};
    const x = partenaires?.length ? partenaires[0] : null;
    const link = origine === "1" ? urlOrigine : x?.url || "";
    const isSaved = jobOffers?.length
        ? jobOffers.some((_job) => {
              const parsedJob = JSON.parse(_job);
              return parsedJob?.id === job?.id;
          })
        : false;

    return (
        <Space size={10}>
            <Tooltip
                title={`${t("jobSites.openJob")}: ${
                    link ? link.slice(0, 50) : ""
                }...`}
            >
                <a type="link" href={link} target="_blank" rel="noreferrer">
                    <Button size="small" className={classes.borderRound}>
                        <RiExternalLinkLine />
                    </Button>
                </a>
            </Tooltip>
            {/* <Tooltip
                title={tooltipTitle}
                open={isTooltipOpen && job?.id === selectedJob}
            >
                <Button
                    size="small"
                    className={classes.borderRound}
                    onMouseEnter={() => onMouseEnter(job)}
                    onMouseLeave={onMouseLeave}
                    onClick={() => onBtnClick(link)}
                >
                    <BiCopy />
                </Button>
            </Tooltip> */}
            <Tooltip
                title={t(
                    `jobSites.${isSaved ? "unsaveAdTooltip" : "saveAdTooltip"}`
                )}
            >
                <Button
                    size="small"
                    disabled={!user_id}
                    className={classes.borderRound}
                    onClick={() =>
                        isSaved
                            ? onSaveClick(job, "unsave")
                            : onSaveClick(job, "save")
                    }
                    style={
                        isSaved
                            ? {
                                  background: "#1890FF",
                                  color: "#fff",
                              }
                            : {}
                    }
                >
                    {isSaved ? (
                        <span style={{ fontSize: 13 }}>
                            <TiDelete
                                style={{
                                    marginTop: "-2px",
                                    fontSize: 14,
                                }}
                            />{" "}
                            {t("jobSites.unsaveAd")}
                        </span>
                    ) : (
                        <span>
                            <BiSave style={{ margin: "-2px 3px 0 0" }} />
                            {t("jobSites.saveAd")}
                        </span>
                    )}
                </Button>
            </Tooltip>
        </Space>
    );
};

export default JobActions;
