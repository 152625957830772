import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const getBackendPath = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const useAlternateLocale =
        urlParams.get("origin") === "1" || urlParams.get("origin") === 1;

    if (useAlternateLocale) {
        return "/standalone/{{lng}}/{{ns}}.json";
    } else {
        return "/locales/{{lng}}/{{ns}}.json";
    }
};
i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: getBackendPath(),
        },
        fallbackLng: "fr",
        debug: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
