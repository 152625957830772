import React from "react";
import useStyles from "../makeStyles";
import { DialogContent } from "@material-ui/core";
import { DialogTitle, MuiDialog } from "../../common-components";
import { useTranslation } from "react-i18next";
import { titleCase } from "utils/utils";
import { Button } from "antd";
import { FaCheck } from "react-icons/fa";

const SkillsDesc = ({ open, toggleModal, job, validateStep }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <MuiDialog open={open} maxWidth="md">
            <DialogTitle
                title={`${titleCase(job?.name) || ""}`}
                onClose={() => toggleModal(null)}
            />

            <DialogContent className={classes.dialogContent}>
                <div className="py-2 mb-4 mt-0">
                    <h6 className="font-weight-bold" style={{ fontSize: 14 }}>
                        {t(
                            validateStep === 0
                                ? "cv.skillDescriptionModalLabel"
                                : "cv.skillCategory"
                        )}{" "}
                        {validateStep === 1 ? (
                            <span className="font-weight-normal">
                                {`: ${job?.category || ""}`}{" "}
                            </span>
                        ) : (
                            ""
                        )}
                    </h6>

                    {validateStep === 0 && (
                        <div
                            style={{
                                background: "#fafafa",
                                padding: "1em 1em 0.5em 1em",
                                marginTop: "1em",
                            }}
                        >
                            <p>{`${
                                job?.description || job?.jobdescription || " "
                            }`}</p>
                        </div>
                    )}

                    {validateStep === 0 ? (
                        <>
                            {job?.altlabels && (
                                <>
                                    <h6
                                        className="font-weight-bold"
                                        style={{
                                            marginTop: "2em",
                                            fontSize: 15,
                                        }}
                                    >
                                        {validateStep === 0
                                            ? t("cv.altJobPositions")
                                            : t("cv.altSkillPositions")}
                                    </h6>

                                    <div
                                        style={{
                                            background: "#fafafa",
                                            padding: "1em 1em 0.5em 1em",
                                            marginTop: "1em",
                                        }}
                                    >
                                        <p>{`${job?.altlabels || " "}`}</p>
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <h6
                                className="font-weight-bold"
                                style={{ marginTop: "2em", fontSize: 14 }}
                            >
                                {t("cv.categorySkillsLabel")}:
                            </h6>
                            <div
                                style={{
                                    background: "#fafafa",
                                    padding: "1em 1em 0.5em 1em",
                                    marginTop: "1em",
                                }}
                            >
                                {job?.skills?.[0]?.length ? (
                                    job.skills?.[0].map((item, idx) => {
                                        return (
                                            <p className="mb-2" key={idx}>
                                                <FaCheck
                                                    color="green"
                                                    className="mr-2"
                                                />
                                                {item || ""}
                                            </p>
                                        );
                                    })
                                ) : (
                                    <p>{t("cv.noSkillFound")} </p>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <div className="d-flex justify-content-center pb-3">
                    <Button
                        style={{
                            background: "#00536D",
                            color: "#fff",
                            borderRadius: 4,
                        }}
                        onClick={() => toggleModal(null)}
                    >
                        Ok
                    </Button>
                </div>
            </DialogContent>
        </MuiDialog>
    );
};

export default SkillsDesc;
