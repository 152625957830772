import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function SkeletonLoader() {
    const theme = useTheme();
    const lg = useMediaQuery(theme.breakpoints.up("xl"));
    return (
        <div>
            <Skeleton
                variant="rect"
                width="100%"
                height={20}
                className="mt-4"
                style={{ borderRadius: 3 }}
            />
            <Skeleton
                variant="rect"
                width="100%"
                height={300}
                className="mt-4"
                style={{ borderRadius: 3 }}
            />

            {lg ? (
                <Skeleton
                    variant="rect"
                    width="100%"
                    height={200}
                    className="mt-4"
                    style={{ borderRadius: 3 }}
                />
            ) : (
                ""
            )}
        </div>
    );
}

export default SkeletonLoader;
