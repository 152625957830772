import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { AiOutlineDown } from "react-icons/ai";
import { Menu, Dropdown, Button } from "antd";
import CountryFlag from "react-country-flag";

export const changeLang = (payload) => {
    return (dispatch) => dispatch({ type: "CHANGE_LANGUAGE", payload });
};

const LanguageOptions = ({ component }) => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const lang = i18n.language || window?.localStorage?.i18nextLng || "fr";

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };

    const languages = [
        { code: "US", label: "EN" },
        { code: "FR", label: "FR" },
    ];

    return (
        <Dropdown
            overlay={() => (
                <Menu>
                    {languages.map((item, idx) => {
                        const code = item.code === "US" ? "en" : "fr";
                        return (
                            <Menu.Item
                                key={idx}
                                onClick={() => changeLanguage(code)}
                            >
                                <CountryFlag
                                    countryCode={item.code}
                                    svg
                                    className="mr-2"
                                />
                                {item.label}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            )}
            placement="bottomCenter"
            arrow
        >
            <Button size="large">
                <CountryFlag
                    countryCode={lang.startsWith("en") ? "US" : "FR"}
                    svg
                    className={component !== "appbar" ? classes.flag : ""}
                />
                <AiOutlineDown
                    fontSize="small"
                    className={classes.outlineDown}
                />
            </Button>
        </Dropdown>
    );
};

const useStyles = makeStyles((theme) => ({
    flag: { marginTop: "-7px" },
    outlineDown: {
        fontSize: 10,
        marginLeft: 5,
        color: "#b7c0cd",
        // color: "#e0e0e0",
    },
}));

export default LanguageOptions;
