export const url = {
    session: {
        session: "api/session/",
        jobs: "api/session/jobs",
        cv: "api/session/file",
        criteria: "api/session/criteria",
        skills: "api/session/skills",
        steps: "api/session/step",
        selectedJob: "api/session/selectedJob",
        suggest: "api/session/suggestions",
        choice: "api/session/choice",
        extsession: "api/session/extsessions",
    },
    job: {
        soft_skills: "api/job/soft-skills/",
        job_skills: "api/job-skills/",
        getJobOffer: "jo/get_joboffer/",
        addJobOffer: "jo/insert_joboffer/",
        deleteJobOffer: "jo/delete_joboffer/",
        addJobPosition: "jo/insert_jobposition/",
    },
    cv: {
        scan: "skill/scan/",
        skills: "skill/",
        jobs: "job/",
        trainSkill: "skill/train/skills",
        trainJob: "skill/train/job",
        searchJobFr: "search/search_jobs_full_fr/",
        searchJobEn: "search/search_jobs_full_en/",
        searchJobStatFr: "search/search_jobs_stat_fr/",
        searchJobStatEn: "search/search_jobs_stat_en/",
        insertCV: "search/insert_cv/",
        statusSearch: "search/status_search/",
        filteredJobsFr: "search/list_filtered_jobs_fr/",
        filteredJobsEn: "search/list_filtered_jobs_en/",
        addJobFr: "search/add_job_fr/",
        addJobEn: "search/add_job_en/",
        getJobItemFr: "search/get_job_id_fr/",
        getJobItemEn: "search/get_job_id_en/",
        searchSkillsFr: "search/search_skills_fr/",
        searchSkillsEn: "search/search_skills_en/",
        // searchSkills: "search/search_skills_jobs_ids_fr/",
        // searchSkills2: "search/search_skills_jobs_ids2_fr/",
    },
    authentication: {
        signup: "api/signup",
        signout: "/api/signout",
        checkToken: "/api/checkToken",
    },
};
