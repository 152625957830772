import "antd/dist/antd.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import Routes from "./components/Routes/Routes";
import { appStyles } from "./styles/style";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const THEME = createTheme({
    typography: {
        fontFamily: `"Quicksand", sans-serif`,
        fontSize: 14,
    },
});

function App() {
    const classes = appStyles();

    useEffect(() => {
        document.cookie = "origin = " + window.location.host;
    });

    return (
        <MuiThemeProvider theme={THEME}>
            <div className={classes.root}>
                <Routes />
            </div>
        </MuiThemeProvider>
    );
}

export default App;
