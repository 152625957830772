import React from "react";
import FrontOffice from "../Front-office/Home";
import MyJobs from "components/Front-office/MyJobs";
import BackOffice from "components/Back-office/BackOffice";
import SavedJobs from "components/SavedJobs";
import { Route, Switch } from "react-router-dom";
import { PageNotFound, ServerError } from "../common-components";

const Routes = () => {
    return (
        <Switch>
            <Route exact={true} path="/" component={FrontOffice} />
            <Route exact={true} path="/myjobs" component={MyJobs} />
            <Route exact={true} path="/saved-jobs" component={SavedJobs} />
            <Route exact={true} path="/back-office" component={BackOffice} />
            <Route exact path="/server-error/" component={ServerError} />
            <Route component={PageNotFound} />
        </Switch>
    );
};

export default Routes;
