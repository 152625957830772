import React from "react";
import { Container, CssBaseline } from "@material-ui/core";

const Layout = ({ children }) => {
    return (
        <Container component="main" className="mb-4">
            <CssBaseline />
            <div className="pt-4 mt-2">{children}</div>
        </Container>
    );
};

export default Layout;
