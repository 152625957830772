import React, { createRef, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import { DisappearedLoading } from "react-loadingg";
import { SnackbarProvider } from "notistack";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import "./utils/i18n";
import App from "./App";

const notistackRef = createRef();
const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
};

const loading = () => {
    return (
        <div className="spinner">
            <DisappearedLoading
                style={{ fontSize: 10 }}
                color="#eb5d27"
                size="10px"
            />
        </div>
    );
};

const { hostname } = window?.location || {};

const app = (
    <Suspense fallback={loading()}>
        <SnackbarProvider
            maxSnack={5}
            ref={notistackRef}
            action={(key) => (
                <Button
                    onClick={onClickDismiss(key)}
                    style={{ color: "white" }}
                >
                    OK
                </Button>
            )}
        >
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </SnackbarProvider>
    </Suspense>
);

ReactDOM.render(
    <>
        {process.env.NODE_ENV === "development" && hostname === "localhost" ? (
            <React.StrictMode>{app}</React.StrictMode>
        ) : (
            app
        )}
    </>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
