import React from "react";
import { DialogActions, Tooltip } from "@material-ui/core";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import useStyles from "../Front-office/makeStyles";

const MuiDialogActions = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { cancel, cancelLabel, disabled, disableCancel, processing } = props;
    const { submit, submitLabel, reset, fileUpload } = props;

    return (
        <DialogActions className={classes.actions}>
            {fileUpload && (
                <Tooltip title={t("reset")} arrow placement="top">
                    <Button
                        className={clsx(classes.cancel, classes.btn)}
                        onClick={reset}
                        disabled={disableCancel || processing}
                    >
                        {t("reset")}
                    </Button>
                </Tooltip>
            )}
            <Tooltip
                title={cancelLabel ? t("cancel") : t("previous")}
                arrow
                placement="top"
            >
                <Button
                    className={clsx(classes.cancel, classes.btn)}
                    onClick={cancel}
                    disabled={disableCancel || processing}
                >
                    {cancelLabel ? t("cancel") : t("previous")}
                </Button>
            </Tooltip>
            <Button
                className={clsx(classes.active, classes.btn, classes.submit)}
                onClick={submit}
                disabled={disabled ? disabled : processing}
                classes={{
                    root: classes.active,
                    disabled: classes.disabled,
                }}
                loading={processing}
            >
                {processing ? `${t("processing")}...` : submitLabel}
            </Button>
        </DialogActions>
    );
};

export default MuiDialogActions;
