import React from "react";
import clsx from "clsx";
import useStyles from "../makeStyles";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { Button, Space, Upload } from "antd";

const { Dragger } = Upload;

const CVForm = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { fileImg, processing, submit, fileList } = props;
    const { onFileRemove, handleDocuments } = props;

    const draggerProps = {
        showUploadList: true,
        multiple: false,
        onRemove: (file) => onFileRemove(file),
        beforeUpload: (_, fileList) => {
            handleDocuments(fileList);
            return false;
        },
        fileList,
    };

    return (
        <Grid
            container
            spacing={4}
            className="mt-2 mb-0"
            justifyContent="center"
        >
            <Grid item xs={12} sm={8}>
                <div className="mt-4">
                    <Dragger
                        {...draggerProps}
                        className={classes.dragger}
                        accept=".pdf"
                    >
                        <img
                            src={fileImg}
                            alt="empty list img"
                            className={classes.file}
                        />
                        <br />
                        <Typography
                            className={clsx(classes.draggerHeader, "mt-3")}
                        >
                            {t("cv.form.uploadfile")}
                        </Typography>
                        <Typography
                            className={clsx(classes.font14, "mt-2")}
                            style={{ fontSize: "14px" }}
                        >
                            {t("cv.form.uploadFileText")}
                        </Typography>
                    </Dragger>
                </div>
            </Grid>
            <Grid item xs={12} sm={8} className="d-flex justify-content-center">
                <Space className="px-2">
                    <Button disabled={true} className={clsx(classes.cancel)}>
                        {t("reset")}
                    </Button>
                    <Button
                        loading={processing}
                        disabled={processing}
                        onClick={submit}
                        className={classes.uploadCVBtn}
                    >
                        {t("next")}
                    </Button>
                </Space>
            </Grid>
        </Grid>
    );
};

export default CVForm;
