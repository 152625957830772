import React from "react";
import useStyles from "../makeStyles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Button, Space } from "antd";

const ValidateActions = ({ onBackClick, loadChoice, onValidateCLick }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Space
            className={clsx(
                classes.mt_5em,
                "d-flex justify-content-center mb-4"
            )}
        >
            <Button onClick={onBackClick} className={classes.validateCancelBtn}>
                {t("back")}
            </Button>
            <Button
                type="primary"
                className={clsx(classes.active, classes.validateBtn)}
                onClick={onValidateCLick}
                loading={loadChoice}
            >
                {t("validate")}
            </Button>
        </Space>
    );
};

export default ValidateActions;
