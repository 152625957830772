import { makeStyles } from "@material-ui/core/styles";

export const appStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        overflowY: "hidden",
        background: "#fff",
    },
}));
