import React, { useState } from "react";
import fileImg from "../../../images/files.svg";
import useStyles from "../makeStyles";
import SkillLearning from "./SkillLearning";
import CVForm from "./CVForm";
import SkillsNav from "./SkillsNav";
import SkillsLoader from "./SkillsLoader";
import ValidateSteps from "./ValidateSteps";
import AddSkill from "./AddSkill";
import AddJob from "./AddJob";
import Grid from "@material-ui/core/Grid";
import ValidateActions from "./ValidateActions";
import SkillsDesc from "./SkillDesc";
import { Button } from "antd";
import { getSession } from "utils/utils";
import { useTranslation } from "react-i18next";
import { create } from "service/cv-service";
import { useLocation } from "react-router-dom";

const Skills = (props) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const [skill, setSkill] = useState("");
    const [openDesc, setOpenDesc] = useState(false);
    const [selectedDesc, setSelectedDesc] = useState(null);
    const [job, setJob] = useState("");
    const [seeMoreJobs, setSeeMoreJobs] = useState(false);
    const [seeMoreSkills, setSeeMoreSkills] = useState(false);
    const [loadDesc, setLoadDesc] = useState(false);
    const { onFileRemove, handleDocuments, setSelected } = props;
    const { handleChoose, validateSkills, removeJobSkill } = props;
    const { reset, backToJobs, submit, addJobSkill, state } = props;
    const { files: fileList, fileUpload: success, processing } = state || {};
    const { file: fileData, loadingSuggestion, suggestedJobs } = state || {};
    const { suggestedSkills, selectedSkills } = state || {};
    const { selectedJobs, chosenSkills, chosenJobs, loadChoice } = state || {};
    const { validateStep, validateSkillAdd, hasChosen } = state || {};
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const lang =
        searchParams.get("lang") ||
        window?.localStorage?.i18nextLng ||
        i18n.language ||
        "fr";

    const _chosenJobs = chosenJobs?.length
        ? chosenJobs
        : getSession("chosenJobs")?.length
        ? getSession("chosenJobs")
        : [];

    const onInputChange = async (name, value) => {
        let _value = value;
        if (name !== "skill" && value?.id) {
            setLoadDesc(true);
            const res = await create(
                JSON.stringify({ jobid: value?.uuid }),
                `getJobItem${lang}`
            );

            const { data, status } = res || {};
            const _job = data?.results?.[0]?.[0]?.[0]?.joblist?.[0] || null;
            if (status === 200 && _job?.id) {
                _value.description = _job?.description || "";
                _value.altlabels = _job?.altlabels || "";
                _value.jobcode = _job?.jobcode || "";
                _value.RomeCode = _job?.RomeCode || "";
            }
            setLoadDesc(false);
        }
        const setData = name === "skill" ? setSkill : setJob;
        setData(_value);
    };

    const handleClick = (type, value) => {
        const setJobSkill = type === "Skill" ? setSkill : setJob;
        const chosen = type === "Skill" ? "chosenSkills" : "chosenJobs";
        addJobSkill(type, chosen, value);
        setJobSkill("");
    };

    const onViewDesc = (data) => {
        setOpenDesc(!openDesc);
        setSelectedDesc(data);
    };

    const onViewAll = () => setSeeMoreJobs((prevState) => !prevState);

    const onViewAllSkills = () => setSeeMoreSkills(!seeMoreSkills);

    const f = suggestedJobs?.length
        ? suggestedJobs.filter((item) => `${item?.code}`?.startsWith("2514"))
        : suggestedJobs;

    const ff = chosenJobs?.length
        ? chosenJobs.filter((item) => `${item?.code}`?.startsWith("2514"))
        : chosenJobs;

    const filteredJobs = !seeMoreSkills
        ? suggestedJobs?.length && f?.length
            ? f
            : suggestedJobs?.length > 10
            ? suggestedJobs.filter((_, idx) => idx < 10)
            : suggestedJobs
        : suggestedJobs;

    const filteredChosenJobs = !seeMoreSkills
        ? chosenJobs?.length && ff?.length
            ? ff
            : chosenJobs?.length > 10
            ? chosenJobs.filter((_, idx) => idx < 10)
            : chosenJobs
        : chosenJobs;
    const filteredSkills = !seeMoreJobs
        ? suggestedSkills?.length && suggestedSkills?.length > 15
            ? suggestedSkills.filter((_, idx) => idx < 15)
            : suggestedSkills
        : suggestedSkills;

    const skillLearning = (type, isValidated, removeJob) => {
        const _skill = type === "skill";
        const foo =
            type !== "skill" && isValidated ? filteredChosenJobs : filteredJobs;
        return (
            <SkillLearning
                type={type}
                data={_skill ? filteredSkills : foo}
                selectedSkills={_skill ? selectedSkills : selectedJobs}
                setSelectedSkills={setSelected}
                onViewDesc={onViewDesc}
                isValidated={isValidated}
                removeJobSkill={removeJob}
            />
        );
    };

    return !success ? (
        <CVForm
            fileImg={fileImg}
            processing={processing}
            submit={submit}
            fileList={fileList}
            onFileRemove={onFileRemove}
            handleDocuments={handleDocuments}
        />
    ) : (
        <div className={classes.fileContainer}>
            {((fileData?.data && !loadingSuggestion) ||
                getSession("isLoaded")) && <SkillsNav step={validateStep} />}

            {loadingSuggestion ? (
                <SkillsLoader />
            ) : (
                <>
                    <Grid container>
                        <Grid item sm={4}>
                            <ValidateSteps step={validateStep} />
                        </Grid>
                        <Grid item sm={12} className="mt-2">
                            {validateStep === 0 ? (
                                !hasChosen ? (
                                    <>
                                        {skillLearning("job")}
                                        {(filteredJobs?.length && f?.length) ||
                                        (suggestedJobs?.length &&
                                            suggestedJobs?.length > 10 &&
                                            f?.length === 0 &&
                                            filteredJobs?.length >= 10) ? (
                                            <div className="d-flex justify-content-center flex-column align-items-center mt-4">
                                                <p className="mt-4">
                                                    {!seeMoreSkills
                                                        ? t("cv.seeMoreJobDesc")
                                                        : t(
                                                              "cv.seeLessJobDesc"
                                                          )}
                                                </p>
                                                <Button
                                                    className={
                                                        classes.seeMoreBtn
                                                    }
                                                    type="text"
                                                    onClick={onViewAllSkills}
                                                >
                                                    {!seeMoreSkills
                                                        ? t("cv.seeMore")
                                                        : t("cv.seeLess")}
                                                </Button>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                ) : (
                                    <AddJob
                                        jobs={_chosenJobs}
                                        job={job}
                                        jobAddLoader={state?.jobAddLoader}
                                        addJob={handleClick}
                                        handleChange={onInputChange}
                                        removeJobSkill={removeJobSkill}
                                        skillLearning={skillLearning}
                                        onViewDesc={onViewDesc}
                                        setJob={setJob}
                                        loadDesc={loadDesc}
                                    />
                                )
                            ) : validateSkillAdd && validateStep === 1 ? (
                                <AddSkill
                                    skills={chosenSkills}
                                    skill={skill}
                                    addSkill={handleClick}
                                    handleChange={onInputChange}
                                    removeJobSkill={removeJobSkill}
                                />
                            ) : (
                                <>
                                    {skillLearning("skill")}
                                    {filteredSkills?.length >= 15 &&
                                    suggestedSkills?.length > 15 ? (
                                        <div className="d-flex justify-content-center flex-column align-items-center mt-4">
                                            <p className="mt-4">
                                                {!seeMoreJobs
                                                    ? t("cv.seeMoreSkillDesc")
                                                    : t("cv.seeLessSkillDesc")}
                                            </p>
                                            <Button
                                                className={classes.seeMoreBtn}
                                                type="text"
                                                onClick={onViewAll}
                                            >
                                                {!seeMoreJobs
                                                    ? t("cv.seeMore")
                                                    : t("cv.seeLess")}
                                            </Button>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>

                    {(validateStep === 0 && hasChosen) ||
                    (validateStep === 1 && validateSkillAdd) ? (
                        ""
                    ) : (
                        <ValidateActions
                            onBackClick={
                                validateStep === 0 ? reset : backToJobs
                            }
                            onValidateCLick={
                                validateStep === 0
                                    ? handleChoose
                                    : validateSkills
                            }
                            loadChoice={loadChoice}
                        />
                    )}
                </>
            )}

            <SkillsDesc
                open={openDesc}
                toggleModal={onViewDesc}
                job={selectedDesc}
                validateStep={validateStep}
            />
        </div>
    );
};

export default Skills;
