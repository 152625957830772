import React from "react";
import { Dialog, Slide, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    dialog: {
        position: "absolute",
        top: 25,
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const MuiDialog = ({ children, open, maxWidth }) => {
    const classes = useStyles();
    return (
        <Dialog
            classes={{ paper: classes.dialog }}
            open={open || false}
            maxWidth={maxWidth}
            fullWidth
            TransitionComponent={Transition}
        >
            {children}
        </Dialog>
    );
};

export default MuiDialog;
