import React from "react";
import useStyles from "../makeStyles";
import { useTranslation } from "react-i18next";
import { DialogContent } from "@material-ui/core";
import { Button, Space } from "antd";
import { FaAngleDoubleRight, FaInfoCircle } from "react-icons/fa";
import { DialogTitle, MuiDialog } from "../../common-components";
import { RiExternalLinkLine, RiCheckboxCircleLine } from "react-icons/ri";
import { BiCopy, BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { isFr } from "utils/utils";

const JobBoardHelp = ({ open, toggleModal }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    let lang = i18n.language || window?.localStorage?.i18nextLng;
    lang = isFr ? "fr" : "en";

    return (
        <MuiDialog open={open} maxWidth="md">
            <DialogTitle
                title={t("jobSites.help.modalTitle")}
                onClose={toggleModal}
            />

            <DialogContent className={classes.dialogContent}>
                <div
                    style={{
                        background: "#fafafa",
                        padding: "0.2em 1em",
                    }}
                >
                    <Space className="d-flex align-content-center" size={8}>
                        <FaAngleDoubleRight color="#43A047" className="mr-2" />
                        <p style={{ fontSize: 14 }} className="mt-3">
                            <FaInfoCircle
                                style={{
                                    fontSize: 16,
                                    color: "#1890FF",
                                    marginTop: "-3px",
                                    marginRight: "5px",
                                }}
                            />
                            {t("jobSites.help.offers")}
                        </p>
                    </Space>

                    <Space
                        className="d-flex align-content-center"
                        size={8}
                        style={{ marginTop: "-1em" }}
                    >
                        <FaAngleDoubleRight color="#43A047" className="mr-2" />
                        <p style={{ fontSize: 14 }} className="mt-3">
                            {t("jobSites.help.filter")}
                        </p>
                    </Space>
                    <Space
                        className="d-flex align-content-center"
                        size={8}
                        style={{ marginTop: "-1em" }}
                    >
                        <FaAngleDoubleRight color="#43A047" className="mr-2" />
                        <p style={{ fontSize: 14 }} className="mt-3">
                            {lang === "fr"
                                ? `Si la liste des offres d'emploi proposées est
                            limitée ou trop importante, vous avez la possibilité
                            de retourner à l'étape`
                                : "If the list of job offers proposed is limited or too large, you can return to the"}{" "}
                            <span
                                style={{
                                    background: "#fff",
                                    padding: "0.2em",
                                    fontSize: 16,
                                    borderRadius: 4,
                                }}
                            >
                                <RiCheckboxCircleLine
                                    style={{
                                        fontSize: 23,
                                        color: "#1890FF",
                                        marginTop: "-3px",
                                    }}
                                />{" "}
                                Postes
                            </span>{" "}
                            {lang === "fr"
                                ? `afin de modifier votre recherche en retirant ou en
                            ajoutant des métiers. Vous pouvez également
                            retourner à l'étape`
                                : "Professions step to modify your search by removing or adding professions. You can also return to the"}{" "}
                            <span
                                style={{
                                    background: "#fff",
                                    padding: "0.2em",
                                    fontSize: 16,
                                    borderRadius: 4,
                                }}
                            >
                                <RiCheckboxCircleLine
                                    style={{
                                        fontSize: 23,
                                        color: "#1890FF",
                                        marginTop: "-3px",
                                    }}
                                />{" "}
                                Critères
                            </span>{" "}
                            {lang === "fr"
                                ? `pour élargir ou restreindre le périmètre géographique.`
                                : "Criteria step to expand or narrow the geographic scope."}
                        </p>
                    </Space>

                    <Space
                        className="d-flex align-content-center"
                        size={8}
                        style={{ marginTop: "-1em" }}
                    >
                        <FaAngleDoubleRight color="#43A047" className="mr-2" />
                        <p style={{ fontSize: 14 }} className="mt-3">
                            <RiExternalLinkLine
                                style={{
                                    fontSize: 18,
                                    marginTop: "-3px",
                                    marginRight: "5px",
                                }}
                            />

                            {t("jobSites.help.openJobOffer")}
                        </p>
                    </Space>

                    <Space
                        className="d-flex align-content-center"
                        size={8}
                        style={{ marginTop: "-1em" }}
                    >
                        <FaAngleDoubleRight color="#43A047" className="mr-2" />
                        <p style={{ fontSize: 14 }} className="mt-3">
                            <BiCopy
                                style={{
                                    fontSize: 18,
                                    marginTop: "-3px",
                                    marginRight: "5px",
                                }}
                            />

                            {t("jobSites.help.copyJobOffer")}
                        </p>
                    </Space>

                    <Space
                        className="d-flex align-content-center"
                        size={8}
                        style={{ marginTop: "-1em" }}
                    >
                        <FaAngleDoubleRight color="#43A047" className="mr-2" />
                        <p style={{ fontSize: 14 }} className="mt-3">
                            <BiSave
                                style={{
                                    fontSize: 18,
                                    marginTop: "-3px",
                                    marginRight: "5px",
                                }}
                            />

                            {t("jobSites.help.saveJobOffer")}
                        </p>
                    </Space>

                    <Space
                        className="d-flex align-content-center"
                        size={8}
                        style={{ marginTop: "-1em" }}
                    >
                        <FaAngleDoubleRight color="#43A047" className="mr-2" />
                        <p style={{ fontSize: 14 }} className="mt-3">
                            <MdAdd
                                style={{
                                    fontSize: 18,
                                    marginTop: "-3px",
                                    marginRight: "5px",
                                }}
                            />

                            {t("jobSites.help.jobOverview")}
                        </p>
                    </Space>
                </div>

                <div className="d-flex justify-content-center my-4">
                    <Button
                        type="primary"
                        onClick={toggleModal}
                        className={classes.descBtn}
                    >
                        {t("ok")}
                    </Button>
                </div>
            </DialogContent>
        </MuiDialog>
    );
};

export default JobBoardHelp;
