import React, { useState, useEffect } from "react";
import clsx from "clsx";
import useStyles from "../makeStyles";
import JobItemDescription from "./JobItemDescription";
import JobActions from "./JobActions";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Table, Tooltip } from "antd";
import { TableContainer } from "@material-ui/core";
import { formatDate, snackbarAlert } from "utils/utils";
import { MdAdd, MdRemove } from "react-icons/md";
import { create } from "service/cv-service";
import { ConfirmSave } from "components/common-components";

const JobBoardsList = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [isTooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipTitle, setTooltipTitle] = useState("");
    const [selectedJob, setSelectedJob] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [confirmTitle, setConfirmTitle] = useState("");
    const [confirmDesc, setConfirmDesc] = useState("");
    const [jobOffers, setJobOffers] = useState(null);
    const [confirmAction, setConfirmAction] = useState("");

    const { t, i18n } = useTranslation();
    const lang = i18n.language || window?.localStorage?.i18nextLng || "fr";
    const { getFilters, jobs, defJobsLength } = props;

    const searchParams = new URLSearchParams(location.search);
    const user_id = searchParams.get("userId");

    const snackAlert = (data, type) =>
        snackbarAlert(enqueueSnackbar, data, type);

    useEffect(() => {
        getJobOffers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getJobOffers = async () => {
        const res = await create({ user_id }, `getJobOffer`);

        const jobOffers = res?.data?.results?.[0]?.[0]?.job_offers?.length
            ? JSON.parse(res?.data?.results?.[0]?.[0]?.job_offers)
            : null;
        setJobOffers(jobOffers);
    };

    const onMouseEnter = (job) => {
        setTooltipOpen(true);
        setTooltipTitle(t("jobSites.copyJob"));
        setSelectedJob(job);
    };

    const onMouseLeave = () => {
        setTooltipOpen(false);
        setSelectedJob(null);
    };

    const onBtnClick = (jobLink) => {
        navigator.clipboard.writeText(jobLink);
        setTooltipOpen(true);
        setTooltipTitle(t("jobSites.copied"));
    };

    const onSaveClick = (job, action) => {
        setConfirmOpen(true);
        setSelectedJob(job);
        setConfirmTitle(job?.intitule || "");
        setConfirmDesc(
            t(`jobSites.confirm${action === "save" ? "Save" : "UnSave"}JobDesc`)
        );
        setConfirmAction(action);
    };

    const onSaveCancel = () => {
        setSelectedJob(null);
        setConfirmOpen(false);
        setConfirmTitle("");
        setConfirmDesc("");
    };

    const saveJob = async () => {
        setConfirmLoading(true);

        try {
            const { id: joboffer_id } = selectedJob || {};
            const job_content = JSON.stringify(selectedJob);

            const res = await create(
                { user_id, joboffer_id, job_content },
                "addJobOffer"
            );
            const { status, data } = res || {};
            console.log(res);
            if (
                status === 200 &&
                data?.message === "Record inserted successfully."
            ) {
                setSelectedJob(null);
                setConfirmLoading(false);
                setConfirmOpen(false);
                snackAlert([t("jobSites.saveJobSuccess")], "success");
                getJobOffers();
            }
        } catch (e) {
            console.log(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    const removeSavedJob = async () => {
        setConfirmLoading(true);

        try {
            const { id: joboffer_id } = selectedJob || {};

            const payload = { user_id, joboffer_id };
            const res = await create(payload, "deleteJobOffer");
            const { status, data } = res || {};
            console.log(res);
            if (
                status === 200 &&
                data?.message === "Record inserted successfully."
            ) {
                setSelectedJob(null);
                setConfirmLoading(false);
                setConfirmOpen(false);
                snackAlert([t("jobSites.unSaveJobSuccess")], "success");
                getJobOffers();
            }
        } catch (e) {
            console.log(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    const title = (label) => (
        <span className={clsx(classes.th)}>
            {t(`jobSites.${label}`).toUpperCase()}
        </span>
    );

    const columns = [
        {
            title: title("intitule"),
            dataIndex: "intitule",
            width: defJobsLength ? "25%" : "",
            render: (item) => (
                <span className="font-weight-bold">{item || ""}</span>
            ),
        },
        {
            title: title("dateActualisation"),
            dataIndex: "dateActualisation",
            render: (item) => (
                <span>{formatDate(new Date(item), "short", lang || "fr")}</span>
            ),
            width: defJobsLength ? "12%" : "",
            responsive: ["lg"],
        },
        {
            title: title("libelle"),
            dataIndex: "libelle",
            width: "14%",
            render: (_, item) => <span>{item.lieuTravail.libelle}</span>,
        },
        {
            title: title("contractType"),
            dataIndex: "typeContrat",
            width: "12%",
            filters: getFilters("typeContrat"),
            onFilter: (value, record) =>
                record.typeContrat.indexOf(value) === 0,
        },
        {
            title: title("experienceLibelle"),
            dataIndex: "experienceLibelle",
        },
        {
            title: title("actions"),
            dataIndex: "origineOffre",
            width: "9%",
            responsive: ["md"],
            render: (_, item) => (
                <JobActions
                    job={item}
                    jobOffers={jobOffers}
                    selectedJob={selectedJob}
                    tooltipTitle={tooltipTitle}
                    isTooltipOpen={isTooltipOpen}
                    onSaveClick={onSaveClick}
                    onBtnClick={onBtnClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                />
            ),
        },
    ];

    return (
        <>
            <TableContainer className="mt-2">
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={jobs}
                    pagination={false}
                    expandable={{
                        fixed: "right",
                        expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                                <Tooltip title={t("cv.seeLess")}>
                                    <MdRemove
                                        onClick={(e) => onExpand(record, e)}
                                        style={{ marginLeft: "-3em" }}
                                        className={classes.pointer}
                                    />
                                </Tooltip>
                            ) : (
                                <Tooltip title={t("cv.seeMore")}>
                                    <MdAdd
                                        className={clsx(
                                            classes.pointer,
                                            classes.font12
                                        )}
                                        style={{ marginLeft: "-3em" }}
                                        onClick={(e) => onExpand(record, e)}
                                    />
                                </Tooltip>
                            ),
                        expandIconColumnIndex: 7,
                        expandedRowRender: (rec) => {
                            return <JobItemDescription job={rec} />;
                        },
                    }}
                />
            </TableContainer>
            <ConfirmSave
                open={confirmOpen}
                submit={confirmAction === "save" ? saveJob : removeSavedJob}
                toggleModal={onSaveCancel}
                description={confirmDesc}
                title={confirmTitle}
                processing={confirmLoading}
            />
        </>
    );
};

export default JobBoardsList;
