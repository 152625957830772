import React, { useEffect, useState } from "react";
import SkeletonLoader from "components/common-components/SkeletonLoader";
import notFound from "../../images/no_data.png";
import SavedJobsList from "./SavedJobsList";
import Filters from "./Filters";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Container, Typography } from "@material-ui/core";
import { Badge, Space } from "antd";
import { getSession, snackbarAlert } from "utils/utils";
import { filterByContract, filterByDate, searchFilter } from "utils/utils";
import { create } from "service/cv-service";
import { useSnackbar } from "notistack";
import { ConfirmSave } from "components/common-components";

const SavedJobs = ({ embed }) => {
    const location = useLocation();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [contractFilters, setContractFilters] = useState(null);
    const [selectedJob, setSelectedJob] = useState(null);
    const [jobOffers, setJobOffers] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filterItems, setFilterItems] = useState({
        openContract: false,
        search: "",
        dateFilter: "all",
        checkedListCopy: null,
    });

    const searchParams = new URLSearchParams(location.search);
    const user_id = searchParams.get("userId");
    const menuTab = getSession("menuTab");

    useEffect(() => {
        getJobOffers();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuTab]);

    const getJobOffers = async () => {
        const res = await create({ user_id }, "getJobOffer");
        const data = res?.data?.results?.[0]?.[0]?.job_offers?.length
            ? JSON.parse(res?.data?.results?.[0]?.[0]?.job_offers)
            : null;

        const jobOffers = data?.length
            ? data.map((item) => JSON.parse(item))
            : null;
        setJobOffers(jobOffers);
        setLoading(false);
    };

    const snackAlert = (data, type) =>
        snackbarAlert(enqueueSnackbar, data, type);

    const onUnSaveClick = (job) => {
        setSelectedJob(job);
        setConfirmOpen(true);
    };

    const onSaveCancel = () => {
        setSelectedJob(null);
        setConfirmOpen(false);
    };

    const removeSavedJob = async () => {
        setConfirmLoading(true);

        try {
            const { id: joboffer_id } = selectedJob || {};

            const payload = { user_id, joboffer_id };
            const res = await create(payload, "deleteJobOffer");
            const { status, data } = res || {};
            if (
                status === 200 &&
                data?.message === "Record inserted successfully."
            ) {
                setSelectedJob(null);
                setConfirmLoading(false);
                setConfirmOpen(false);
                snackAlert([t("jobSites.unSaveJobSuccess")], "success");
                getJobOffers();
            }
        } catch (e) {
            console.log(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    const handleInput = (name, value) => {
        setFilterItems((prevState) => ({ ...prevState, [name]: value }));
        if (name === "checkedListCopy") {
            setFilterItems((prevState) => ({
                ...prevState,
                checkedList: value,
            }));
        }
    };

    const filterList = (action) => {
        const { checkedListCopy } = filterItems || {};
        const _contractFilters =
            action === "reset"
                ? []
                : action === "cancel"
                ? contractFilters
                : checkedListCopy?.length
                ? checkedListCopy
                : [];

        setContractFilters(_contractFilters);
        handleInput("openContract", false);
        if (action === "reset") {
            setFilterItems((prevState) => ({
                ...prevState,
                checkedList: [],
                checkedListCopy: [],
            }));
        }
    };

    const { dateFilter } = filterItems || {};

    let filteredJobs = jobOffers?.length ? jobOffers : [];

    filteredJobs = filterByContract(filteredJobs, contractFilters);

    const dupJobs = filteredJobs?.length ? [...filteredJobs] : [];

    // filter by search
    filteredJobs = searchFilter(filteredJobs, filterItems?.search);

    // date filters
    filteredJobs = filterByDate(filteredJobs, dateFilter);

    let _results = [...filteredJobs];
    _results = _results?.length
        ? _results.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
        : [];

    const content = (
        <div>
            <Space>
                <Typography
                    variant="h6"
                    className="font-weight-bold"
                    align="center"
                >
                    {t("jobSites.yourSavedJobs")}
                </Typography>
                <Badge
                    count={`${_results?.length || 0}`}
                    overflowCount={10000000}
                    className="ml-1"
                    style={{
                        backgroundColor: "#198754",
                    }}
                />
            </Space>

            {loading ? (
                <SkeletonLoader />
            ) : (
                <>
                    {jobOffers?.length ? (
                        <>
                            <Filters
                                filteredJobs={filteredJobs}
                                jobOffers={jobOffers}
                                filterList={filterList}
                                filterItems={filterItems}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                dupJobs={dupJobs}
                                handleInput={handleInput}
                            />
                            <SavedJobsList
                                jobOffers={jobOffers}
                                filteredResults={_results}
                                filterItems={filterItems}
                                currentPage={currentPage}
                                contractFilters={contractFilters}
                                onUnSaveClick={onUnSaveClick}
                            />
                        </>
                    ) : (
                        <Container
                            maxWidth="sm"
                            className="d-flex justify-content-center flex-column align-items-center mt-4"
                        >
                            {/* <Empty /> */}
                            <img
                                src={notFound}
                                width={110}
                                alt="no data found"
                                className="mt-4"
                            />
                            <Typography variant="subtitle2" className="mt-4">
                                {t("savedJob.noSaveJob")}
                            </Typography>
                        </Container>
                    )}
                </>
            )}

            <ConfirmSave
                open={confirmOpen}
                submit={removeSavedJob}
                toggleModal={onSaveCancel}
                description={t("jobSites.confirmUnSaveJobDesc")}
                title={selectedJob?.intitule || ""}
                processing={confirmLoading}
            />
        </div>
    );

    if (!embed) {
        return (
            <Container
                component="main"
                className="mb-4"
                style={{ paddingTop: "3em" }}
            >
                {content}
            </Container>
        );
    }
    return (
        <div component="main" className="mb-4" style={{ paddingTop: "2em" }}>
            {content}
        </div>
    );
};

export default SavedJobs;
