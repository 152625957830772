import React from "react";
import { Tag } from "antd";
import { IoMdClose } from "react-icons/io";

const tagRender = (props) => {
    const { value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <Tag
            color="#1E87C0"
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3 }}
            closeIcon={<IoMdClose color="#fff" />}
        >
            {value}
        </Tag>
    );
};
export default tagRender;
